import { useEffect, useRef, useState } from "react";
import "./SelectCreateDropdownList.css";
import { Icon } from "../Icon/Icon";
import { Text } from "../Text/Text";
import Skeleton from "react-loading-skeleton";
import { Chip } from "../Chips/Chips";

export const SelectCreateDropdownList = ({
    label,
    error,
    options,
    readOnly,
    onChange,
    onCreateOption,
    loading,
    value,
    className,
    floatlabel,
    iconLeft,
    type,
    rtl,
}) => {
    const [selected, setSelected] = useState(type == "multiselect" ? [] : null);

    const [showDropdown, setShowDropDown] = useState(false);

    const [searchEnabled, setSearchEnabled] = useState(false);

    const [search, setSearch] = useState("");

    const searchRef = useRef(null);

    const ref = useRef(null);

    const selectRef = useRef(null);
    
    const [dropdownHeight, setDropdownHeight] = useState("300px");

    useEffect(() => {
    const handleResize = () => {
      if (selectRef.current) {
        const triggerRect = selectRef.current.getBoundingClientRect();
        
        const availableSpace = window.innerHeight - triggerRect.bottom - 10; // 10px padding
        setDropdownHeight(`${availableSpace}px`);
      }
    };

    if (showDropdown) {
      handleResize();
      window.addEventListener("resize", handleResize);
    }

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [showDropdown]);

    useEffect(() => {
        if (value) {
            setSelected(value);
        }
    }, [value, options]);

    useEffect(() => {
        if (!loading) {
            options.forEach((item) => {
                if (item.value === selected) {
                    setSearch("");
                    setSearchEnabled(false);
                    setShowDropDown(false);
                }
            });
        }

        //
    }, [loading]);

    useEffect(() => {
        if (searchEnabled && searchRef && searchRef.current) {
            searchRef.current.focus();
        }
    }, [searchEnabled]);

    useEffect(() => {
        /**
         * Alert if clicked on outside of element
         */
        function handleClickOutside(event) {
            if (
                ref.current &&
                !ref.current.contains(event.target)
            ) {
                setShowDropDown(false);
                setSearchEnabled(false);
                setSearch("");
            }
        }
        // Bind the event listener
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            // Unbind the event listener on clean up
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [ref]);

    const renderSelectedValues = () => {
        const optionsRender = [];

        selected.forEach((value) => {
            let label = "";

            options.forEach((item) => {
                if (item.value === value) {
                    label = item.label;
                }
            });
            if (label) {
                optionsRender.push(
                    <Chip
                        key={"Chip" + value}
                        type={"icon-right"}
                        title={label}
                        value={value}
                        onRemove={(value) => {
                            const locSelected = [...selected];
                            const index = locSelected.indexOf(value);
                            if (index > -1) {
                                locSelected.splice(index, 1);
                            }
                            setSelected(locSelected);
                            if (onChange) onChange(locSelected);
                        }}
                    />
                );
            }
        });

        return <>{optionsRender}</>;
    };
    const renderSelectBox = () => {
        return (
            <div
                ref={selectRef}
                className={`full-width ${rtl == true ? "arabic_wrapper" : ""} `}
            >
                <div
                    className={`w-100  ${
                        type == "multiselect"
                            ? ""
                            : selected
                            ? "project-selected"
                            : ""
                    } ${searchEnabled ? "input-active" : ""}`}
                    onClick={(e) => {
                        e.preventDefault();

                        if (search && search.length > 0) {
                            setShowDropDown(true);
                        } else {
                            if (!searchEnabled) {
                                setShowDropDown(!showDropdown);
                            }
                        }
                        if (!searchEnabled) {
                            setSearchEnabled(true);
                            setShowDropDown(true);
                        }
                    }}
                >
                    <div className={`d-flex align-items-center select-member-dropdown ${iconLeft?"item-selected-icon":""}`}>
                        {loading ? (
                            <>
                                <Skeleton
                                    width={24}
                                    height={24}
                                    style={{
                                        borderRadius: "110px",
                                        marginTop: "9px",
                                    }}
                                />
                            </>
                        ) : (
                            <>
                                {iconLeft ? (
                                    <Icon
                                        value={iconLeft}
                                        size={"medium"}
                                        hover={"true"}
                                        colorClass={
                                            showDropdown || value
                                                ? "gray-900-svg"
                                                : "gray-600-svg"
                                        }
                                    />
                                ) : (
                                    <></>
                                )}
                            </>
                        )}

                        {searchEnabled ? (
                            <input
                                ref={searchRef}
                                type={"text"}
                                value={search}
                                onChange={(e) => {
                                    setSearch(e.target.value);
                                }}
                                /*onClick={(e) => {
                                    setShowDropDown(true);
                                    e.preventDefault();
                                    e.stopPropagation();
                                }}*/
                                onBlur={(e) => {
                                    if (!e.target.value) {
                                        setSearchEnabled(false);
                                    }
                                }}
                                onKeyDown={(event) => {
                                    if (event.key === "Enter") {
                                        let filteredOptions = search
                                            ? options.filter(function (option) {
                                                  var rgxp = new RegExp(
                                                      `\\b${search
                                                          .trim()
                                                          .toLowerCase()}\\b`,
                                                      "i"
                                                  );
                                                  //var rgxp = new RegExp(search.trim().toLowerCase(), "g");
                                                  var searchTitle = option.label
                                                      .trim()
                                                      .toLowerCase();
                                                  if (
                                                      searchTitle.match(rgxp) &&
                                                      searchTitle.match(rgxp)
                                                          .length > 0
                                                  ) {
                                                      return option;
                                                  }
                                              })
                                            : options;

                                        if (filteredOptions.length == 0) {
                                            event.preventDefault();

                                            onCreateOption(search);
                                        }
                                    }
                                }}
                            />
                        ) : type != "multiselect" &&
                          selected &&
                          selected != null ? (
                            <Text
                                type={"body-text-2"}
                                fontWeight={"normal-font"}
                            >
                                {options.map((item) => {
                                    if (item.value == selected) {
                                        return (
                                            <div
                                                key={
                                                    "selected-option" +
                                                    item.value
                                                }
                                                className="selected-project-item"
                                            >
                                                <span className="selected-project-name">
                                                    {item.label}{" "}
                                                </span>
                                                {item.sublabel && (
                                                    <span className="selected-project-member">
                                                        / {item.sublabel}
                                                    </span>
                                                )}
                                            </div>
                                        );
                                    }
                                })}
                            </Text>
                        ) : (
                            <Text
                                type={"body-text-2"}
                                fontWeight={"normal-font"}
                                cssClasses={iconLeft ? "pl-30" : ""}
                            >
                                {label}
                            </Text>
                        )}
                        {
                            <Text type={"caption"} cssClasses={"project-label"}>
                                {floatlabel
                                    ? floatlabel
                                    : "Project folder name"}
                            </Text>
                        }
                    </div>
                    <div
                        className={`multiselectlist-dropdown-dropdown-icon-container`}
                        onClick={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                            if (search && search.length > 0) {
                                setShowDropDown(false);
                                setSearch("");
                                setSearchEnabled(false);
                            } else {
                                setShowDropDown(!showDropdown);
                            }
                        }}
                    >
                        <Icon
                            colorClass={"gray-900-svg"}
                            value={showDropdown ? "Chevron up" : "Chevron"}
                            hover={true}
                        />
                    </div>
                </div>
            </div>
        );
    };
    const renderDropDown = () => {
        if (showDropdown) {
            let filteredOptions = search
                ? options.filter(function (option) {
                      var rgxp = new RegExp(
                          `\\b${search.trim().toLowerCase()}`,
                          "i"
                      );
                      var searchTitle =
                          option &&
                          option.label &&
                          option.label.trim().toLowerCase();
                      if (
                          searchTitle &&
                          searchTitle.match(rgxp) &&
                          searchTitle.match(rgxp).length > 0
                      ) {
                          return option;
                      }
                  })
                : options;

            return (
                <div
                    className={`multiselectlist-dropdown-options ${
                        rtl == true ? "arabic_wrapper" : ""
                    }`}
                    style={{
                        position: "absolute",
                        background: "#fff",
                        width: "200px",
                        maxHeight: dropdownHeight
                    }}
                >
                    <>
                        {search.length > 0 && filteredOptions.length == 0 && (
                            <div
                                key={"create-select-option"}
                                className={`multiselectlist-dropdown-option cursor-pointer active-option justify-content-start`}
                                onClick={(e) => {
                                    e.preventDefault();
                                    if (search.length > 0) {
                                        onCreateOption(search);
                                    }
                                }}
                            >
                                Create{" "}
                                {search.length > 0 && (
                                    <span className="semi-bold-font ml-2">
                                        {search}
                                    </span>
                                )}
                            </div>
                        )}
                    </>
                    {filteredOptions.map((option, index) => {
                        return (
                            <div
                                key={"select-option" + index}
                                className={`multiselectlist-dropdown-option align-items-center ${
                                    type == "multiselect"
                                        ? selected.includes(option.value)
                                            ? "disabled-option"
                                            : "active-option"
                                        : selected == option.value
                                        ? "disabled-option"
                                        : "active-option"
                                } `}
                                onClick={(e) => {
                                    if (type == "multiselect") {
                                        if (!selected.includes(option.value)) {
                                            var selectedLoc = Object.assign(
                                                [],
                                                selected
                                            );

                                            selectedLoc.push(option.value);

                                            setSelected(selectedLoc);

                                            setSearchEnabled(false);

                                            setSearch("");

                                            setShowDropDown(false);

                                            if (onChange) {
                                                onChange(selectedLoc);
                                            }
                                        }
                                    } else {
                                        if (selected !== option.value) {
                                            setSelected(option.value);

                                            setSearchEnabled(false);

                                            setSearch("");

                                            setShowDropDown(false);

                                            if (onChange) {
                                                onChange(option.value);
                                            }
                                        }
                                    }
                                }}
                            >
                                {" "}
                                <span className="member-label d-flex align-items-center">
                                    {option.label}
                                </span>
                                {option.sublabel && (
                                    <span className="selected-project-member ml-1">
                                        / {option.sublabel}
                                    </span>
                                )}
                                {type == "multiselect" && (
                                    <span className="added-member align-items-center">
                                        <Icon
                                            colorClass={"success-green-200-svg"}
                                            value={"check mark"}
                                            size={"small"}
                                        />
                                        <Text
                                            type={"caption"}
                                            fontWeight={"medium-font"}
                                            cssClasses={"green-color"}
                                        >
                                            Added
                                        </Text>
                                    </span>
                                )}
                            </div>
                        );
                    })}
                </div>
            );
        } else {
            return <></>;
        }
    };
    return (
        <div
            className={`multiselectlist-dropdown-box select-create-dropdown position-relative ${
                error ? "field-error" : ""
            } ${className ? className : ""} ${
                readOnly ? "multiselectlist-dropdown-readonly" : ""
            }`}
        >
            <div
                ref={ref}
                className="multiselectlist-dropdown-box-container p-0"
            >
                {renderSelectBox()}
                {renderDropDown()}
            </div>

            {type === "multiselect" && (
                <div className="members-multiselect-dropdown-inner">
                    <div className="multiselectlist-dropdown-selected-values-container">
                        {renderSelectedValues()}
                    </div>
                </div>
            )}
            {error != null && <span className="caption">{error}</span>}
        </div>
    );
};
