import React, { useState, useEffect, useRef } from "react";
import { Link, Navigate } from "react-router-dom";
import { LayoutResearcher } from "../themes/userq/Layouts/layout_researcher";
import { ReactSession } from "../../lib/secure_reactsession";
import { useSearchParams, useNavigate } from "react-router-dom";
import { InformationTilesBox } from "../themes/userq/InformationTilesBox/information_tiles_box";
import { Text } from "../themes/userq/Text/Text";
import { Input } from "../themes/userq/Input/Input";
import { DropdownComponent } from "../themes/userq/Dropdown/Dropdown";
import { CheckBox } from "../themes/userq/CheckBox/CheckBox";
import { Button } from "../themes/userq/Button/Button";
import { Icon } from "../themes/userq/Icon/Icon";
import { PhoneNumberInput } from "../themes/userq/PhoneNumberInput/PhoneNumberInput";
import {
    getProfile,
    getTesterProfile,
    updateStep1,
    updateStep2,
    updateStep3,
    updateStep4,
    updateStep5,
    updateWelcomeMessage,
} from "../../services/user.js";
import { isValidPhoneNumber } from "react-phone-number-input";
import { LoadingIcon } from "../themes/userq/Loader/loadingIcon";
import { ToastMessage } from "../themes/userq/ToastMessage/ToastMessage";
import { toast } from "react-hot-toast";
import { settings } from "../../data/settings";
import { PageProgressBar } from "../themes/userq/ProgressBar/page_progress_bar";
import { RadioButton } from "../themes/userq/RadioButton/RadioButton";
import { CountryDropdownSingle } from "../themes/userq/CountryDropdown/CountryDropdownSingle";
import { InputCalendar } from "../themes/userq/InputCalendar/InputCalendar";
import { LayoutTester } from "../themes/userq/Layouts/layout_tester";
import { emirates } from "../../data/emirates.js";
import { saregions } from "../../data/saregions.js";

import moment from "moment";
import { getAge, removeItemFromArray } from "../../lib/helpers";
import { IncDecCounter } from "../themes/userq/IncDecCounter/IncDecCounter";
import { TesterSlider } from "./tester_slider";
import { SignUpInformationalCard } from "../themes/userq/SignUpInformationalCard/sign_up_informational_card";
import { getLanguagesService } from "../../services/language.js";
import { SearchDropdownMultiple } from "../themes/userq/CountryDropdown/SearchDropdownMultiple.js";
import { ProfileCompletionSteps } from "../themes/userq/ProfileCompletionSteps/ProfileCompletionSteps.js";

export default function Step1({ existingTester }) {
    ReactSession.setStoreType("localStorage");

    const navigate = useNavigate();

    const [isLoading, setLoading] = useState(false);

    const [formLoading, setFormLoading] = useState(false);

    const [formValues, setFormValues] = useState({
        employment: "",
        industry: "",
        industry_other: "",
        department: "",
        department_other: "",
        career_stage: "",
        company_size: "",
        working_environment: "",
    });

    const [formErrors, setFormErrors] = useState({
        employment: null,
        industry: null,
        department: null,
        career_stage: null,
        company_size: null,
        working_environment: null,
    });

    const [lovCategories, setLovCategories] = useState([]);

    const employmentOptions = [
        { label: "Student", value: "Student" },
        {
            label: "Freelancer / Self employed",
            value: "Freelancer / Self employed",
        },
        { label: "Unemployed", value: "Unemployed" },
        { label: "Private employee", value: "Private employee" },
        { label: "Public employee", value: "Public employee" },
        { label: "Homemaker", value: "Homemaker" },
        { label: "Retired", value: "Retired" },
        { label: "Business Owner / CEO", value: "Business Owner / CEO" },
    ];
    const industryOptions = [
        {
            label: "Accountancy, banking and finance",
            value: "Accountancy, banking and finance",
        },
        {
            label: "Armed force, defence & military",
            value: "Armed force, defence & military",
        },
        {
            label: "Aviation",
            value: "Aviation",
        },
        {
            label: "Business, consulting and management",
            value: "Business, consulting and management",
        },
        {
            label: "Charity and voluntary work",
            value: "Charity and voluntary work",
        },
        {
            label: "Creative arts and design",
            value: "Creative arts and design",
        },
        { label: "Digital", value: "Digital" },
        { label: "Energy and utilities", value: "Energy and utilities" },
        {
            label: "Engineering and manufacturing",
            value: "Engineering and manufacturing",
        },
        {
            label: "Environment and agriculture",
            value: "Environment and agriculture",
        },
        {
            label: "Food and beverage",
            value: "Food and beverage",
        },
        { label: "Healthcare", value: "Healthcare" },
        {
            label: "Hospitality and events management",
            value: "Hospitality and events management",
        },
        { label: "Information technology", value: "Information technology" },
        { label: "Law", value: "Law" },
        {
            label: "Law enforcement and security",
            value: "Law enforcement and security",
        },
        {
            label: "Leisure, sport and tourism",
            value: "Leisure, sport and tourism",
        },
        {
            label: "Marketing, advertising and PR",
            value: "Marketing, advertising and PR",
        },
        {
            label: "Media, Internet & telecommunications",
            value: "Media, Internet & telecommunications",
        },
        {
            label: "Property and construction",
            value: "Property and construction",
        },
        { label: "Nonprofit", value: "Nonprofit" },
        {
            label: "Public services and administration",
            value: "Public services and administration",
        },
        { label: "Real Estate", value: "Real Estate" },
        { label: "Recruitment and HR", value: "Recruitment and HR" },
        { label: "Retail", value: "Retail" },
        { label: "Sales", value: "Sales" },
        {
            label: "Science and pharmaceuticals",
            value: "Science and pharmaceuticals",
        },
        {
            label: "Security",
            value: "Security",
        },
        { label: "Social care", value: "Social care" },
        {
            label: "Teacher training and education",
            value: "Teacher training and education",
        },
        {
            label: "Transport and logistics",
            value: "Transport and logistics",
        },

        { label: "Other (please specify)", value: "Other (please specify)" },
    ];
    const departmentOptions = [
        { label: "Finance", value: "Finance" },
        { label: "HR", value: "HR" },
        { label: "Admin", value: "Admin" },
        { label: "Design & research", value: "Design & research" },
        { label: "IT & tech", value: "IT & tech" },
        { label: "Branding & marketing", value: "Branding & marketing" },
        { label: "Sales", value: "Sales" },
        { label: "Purchase", value: "Purchase" },
        { label: "Legal", value: "Legal" },
        { label: "Other (please specify)", value: "Other (please specify)" },
    ];
    const industryRef = useRef(null);

    const departmentRef = useRef(null);

    const [employmentFieldsReadOnly, setEmploymentFieldsReadOnly] =
        useState(false);

    const [steps, setSteps] = useState([
        {
            label: "Personal info",
            percentage: 100,
            link: "/tester/profile/complete/step1",
        },
        {
            label: "Contact details",
            percentage: 100,
            link: "/tester/profile/complete/step2",
        },
        {
            label: "Personal background",
            percentage: 100,
            link: "/tester/profile/complete/step3",
        },
        {
            label: "Household",
            percentage: 100,
            link: "/tester/profile/complete/step4",
        },
        {
            label: "Employment & career",
            percentage: 100,
            link: "/tester/profile/complete/step5",
        },
        {
            label: "Technology usage and preferences",
            percentage: 100,
            link: "/tester/profile/complete/step6",
        },
        {
            label: "Lifestyle",
            percentage: 100,
            link: "/tester/profile/complete/step7",
        },
        {
            label: "Financial & services information",
            percentage: 100,
            link: "/tester/profile/complete/step8",
        },
    ]);

    useEffect(() => {
        window.animate();

        document.title = "Step 5 | " + process.env.REACT_APP_NAME;

        getProfileApi();
    }, []);
    useEffect(() => {
        if (
            industryRef &&
            industryRef.current &&
            formValues.industry == "Other (please specify)"
        ) {
            industryRef.current.focus();
        }
    }, [formValues.industry, industryRef]);

    useEffect(() => {
        if (
            departmentRef &&
            departmentRef.current &&
            formValues.department == "Other (please specify)"
        ) {
            departmentRef.current.focus();
        }
    }, [formValues.department, departmentRef]);

    const getProfileApi = () => {
        const user = ReactSession.get("user");

        const token = ReactSession.get("token");

        setLoading(true);

        var formData = {};
        if (existingTester) {
            formData.existing_tester = 1;
        }
        formData.step = 5;

        getTesterProfile(formData, token).then((response) => {
            setLoading(false);

            if (response.success) {
                if (existingTester) {
                    if (response.data.user.completed_step != 8) {
                        navigate("/tester/profile/step1");
                    }
                } else {
                    if (response.data.user.completed_step < 4) {
                        navigate("/tester/profile/step4");
                    }
                }
                if (response.data.lov_categories) {
                    setLovCategories(response.data.lov_categories);
                }

                var career_stage = "";

                var company_size = "";

                var working_environment = "";

                response.data.lov_categories.forEach(function (category) {
                    category.active_lov.forEach(function (item) {
                        response.data.tester_lov.forEach(function (lov) {
                            if (
                                category.name == "Career stage" &&
                                item.id == lov.lov_id
                            ) {
                                career_stage = item.id;
                            }
                            if (
                                category.name == "Company size" &&
                                item.id == lov.lov_id
                            ) {
                                company_size = item.id;
                            }
                            if (
                                category.name == "Working environment" &&
                                item.id == lov.lov_id
                            ) {
                                working_environment = item.id;
                            }
                        });
                    });
                });
                setFormValues({
                    ...formValues,
                    employment: response.data.user.employment,
                    industry: [
                        "Student",
                        "Unemployed",
                        "Homemaker",
                        "Retired",
                    ].includes(response.data.user.employment)
                        ? ""
                        : response.data.user.industry,
                    industry_other: [
                        "Student",
                        "Unemployed",
                        "Homemaker",
                        "Retired",
                    ].includes(response.data.user.employment)
                        ? ""
                        : response.data.user.industry_other
                        ? response.data.user.industry_other
                        : "",
                    department: [
                        "Student",
                        "Unemployed",
                        "Homemaker",
                        "Retired",
                    ].includes(response.data.user.employment)
                        ? ""
                        : (response.data.user.department!="Other"?response.data.user.department:""),
                    department_other: [
                        "Student",
                        "Unemployed",
                        "Homemaker",
                        "Retired",
                    ].includes(response.data.user.employment)
                        ? ""
                        : response.data.user.department_other
                        ? response.data.user.department_other
                        : "",
                    career_stage: career_stage,
                    company_size: company_size,
                    working_environment: working_environment,
                });
                if (
                    ["Student", "Unemployed", "Homemaker", "Retired"].includes(
                        response.data.user.employment
                    )
                ) {
                    setEmploymentFieldsReadOnly(true);
                } else {
                    setEmploymentFieldsReadOnly(false);
                }
                setSteps(response.data.steps);
            }
        });
    };

    const showError = (error) => {
        toast(
            <ToastMessage
                type={"error"}
                message={error}
                closable={true}
                onClose={() => {
                    toast.dismiss();
                }}
            />,
            {
                className: "errortoast",
                position: "bottom-center",
                duration: settings.toast_duration,
            }
        );
    };

    const showSuccess = (success) => {
        toast(
            <ToastMessage
                type={"success"}
                message={success}
                closable={true}
                onClose={() => {
                    toast.dismiss();
                }}
            />,
            {
                className: "successtoast",
                position: "bottom-center",
                duration: settings.toast_duration,
            }
        );
    };

    const completeProfile = (navigateStep) => {
        setFormLoading(true);

        const token = ReactSession.get("token");

        var formData = { ...formValues };

        if (existingTester) {
            formData.existingTester = 1;
        }

        updateStep5(formData, token).then((response) => {
            setFormLoading(false);

            if (response.success) {
                if (existingTester) {
                    if (navigateStep) {
                        navigate(navigateStep);
                    } else {
                        navigate("/tester/profile/complete/step6");
                    }
                } else {
                    let user = ReactSession.get("user");

                    user.completed_step = response.user.completed_step;

                    ReactSession.set("user", user);

                    navigate("/tester/profile/step6");
                }
            } else {
                showError(response.message);
            }
        });
    };
    const resetFormErrors = () => {
        let formErrorsLocal = Object.assign({}, formErrors);

        for (var key in formErrors) {
            formErrorsLocal[key] = null;
        }
        setFormErrors(formErrorsLocal);

        return formErrorsLocal;
    };
    const validateFullForm = (focus, key, value, inline) => {
        var error = false;

        var re = /^(?=.*\d)(?=.*[@$!%*#?&])(?=.*[a-zA-Z]).{6,}$/;

        var form_errors = Object.assign([], formErrors);

        if (key) {
            form_errors[key] = null;
        }

        if (focus) {
            form_errors = resetFormErrors();
        }

        var firsterrorid = null;

        if (!key || key == "employment") {
            var field_value = key ? value : formValues.employment;

            if (!field_value) {
                form_errors = {
                    ...form_errors,
                    employment: "Required field",
                    error_class: "input_error",
                };
                error = true;
                firsterrorid =
                    firsterrorid == null ? "employment" : firsterrorid;
            }
            if (
                ["Student", "Unemployed", "Homemaker", "Retired"].includes(
                    field_value
                )
            ) {
                form_errors = {
                    ...form_errors,
                    industry: null,
                    industry_other: null,
                    department: null,
                    department_other: null,
                    career_stage: null,
                    company_size: null,
                    working_environment: null,
                };
            }
        }
        if (!key || key == "industry") {
            var field_value = key ? value : formValues.industry;

            if (
                !field_value &&
                !["Student", "Unemployed", "Homemaker", "Retired"].includes(
                    formValues.employment
                )
            ) {
                form_errors = {
                    ...form_errors,
                    industry: "Required field",
                    error_class: "input_error",
                };
                error = true;
                firsterrorid = firsterrorid == null ? "industry" : firsterrorid;
            }
        }
        if (!key || key == "industry_other") {
            var field_value = key && inline ? value : formValues.industry_other;

            if (
                formValues.industry == "Other (please specify)" &&
                !field_value &&
                !["Student", "Unemployed", "Homemaker", "Retired"].includes(
                    formValues.employment
                )
            ) {
                form_errors = {
                    ...form_errors,
                    industry_other: "Required field",
                    error_class: "input_error",
                };
                error = true;
                firsterrorid =
                    firsterrorid == null ? "industry_other" : firsterrorid;
            }
        }
        if (!key || key == "department") {
            var field_value = key ? value : formValues.department;

            if (
                !field_value &&
                !["Student", "Unemployed", "Homemaker", "Retired"].includes(
                    formValues.employment
                )
            ) {
                form_errors = {
                    ...form_errors,
                    department: "Required field",
                    error_class: "input_error",
                };
                error = true;
                firsterrorid =
                    firsterrorid == null ? "department" : firsterrorid;
            }
        }
        if (!key || key == "department_other") {
            var field_value =
                key && inline ? value : formValues.department_other;

            if (
                formValues.department == "Other (please specify)" &&
                !field_value &&
                !["Student", "Unemployed", "Homemaker", "Retired"].includes(
                    formValues.employment
                )
            ) {
                form_errors = {
                    ...form_errors,
                    department_other: "Required field",
                    error_class: "input_error",
                };
                error = true;
                firsterrorid =
                    firsterrorid == null ? "department_other" : firsterrorid;
            }
        }
        if (!key || key == "career_stage") {
            var field_value = key && inline ? value : formValues.career_stage;

            if (
                !field_value &&
                !["Student", "Unemployed", "Homemaker", "Retired"].includes(
                    formValues.employment
                )
            ) {
                form_errors = {
                    ...form_errors,
                    career_stage: "Required field",
                    error_class: "input_error",
                };
                error = true;
                firsterrorid =
                    firsterrorid == null ? "career_stage" : firsterrorid;
            }
        }
        if (!key || key == "company_size") {
            var field_value = key && inline ? value : formValues.company_size;

            if (
                !field_value &&
                !["Student", "Unemployed", "Homemaker", "Retired"].includes(
                    formValues.employment
                )
            ) {
                form_errors = {
                    ...form_errors,
                    company_size: "Required field",
                    error_class: "input_error",
                };
                error = true;
                firsterrorid =
                    firsterrorid == null ? "company_size" : firsterrorid;
            }
        }
        if (!key || key == "working_environment") {
            var field_value =
                key && inline ? value : formValues.working_environment;

            if (
                !field_value &&
                !["Student", "Unemployed", "Homemaker", "Retired"].includes(
                    formValues.employment
                )
            ) {
                form_errors = {
                    ...form_errors,
                    working_environment: "Required field",
                    error_class: "input_error",
                };
                error = true;
                firsterrorid =
                    firsterrorid == null ? "working_environment" : firsterrorid;
            }
        }

        if ((inline && formErrors[key]) || !inline) {
            setFormErrors(form_errors);
        }

        if (focus) {
            if (firsterrorid != null) {
                document.getElementById(firsterrorid).scrollIntoView({
                    behavior: "smooth",
                    block: "center",
                });
            }
        }

        return error;
    };
    const checkBtnState = () => {
        var error = false;

        if (!formValues.employment) {
            error = true;
        }
        if (
            !["Student", "Unemployed", "Homemaker", "Retired"].includes(
                formValues.employment
            )
        ) {
            if (!formValues.industry) {
                error = true;
            }
            if (
                formValues.industry == "Other (please specify)" &&
                !formValues.industry_other
            ) {
                error = true;
            }

            if (!formValues.department) {
                error = true;
            }
            if (
                formValues.department == "Other (please specify)" &&
                !formValues.department_other
            ) {
                error = true;
            }
            if (!formValues.career_stage) {
                //console.log(6)
                error = true;
            }
            if (!formValues.company_size) {
                //console.log(7)
                error = true;
            }
            if (!formValues.working_environment) {
                //console.log(8)
                error = true;
            }
        }

        return error;
    };
    const onSubmit = async (event, navigateStep) => {
        if (event) {
            event.preventDefault();
        }
        if (!isLoading) {
            var error = validateFullForm(true, null);

            if (!error) {
                completeProfile(navigateStep);
            }
        }

        return false;
    };
    const set = (name) => {
        return ({ target: { value } }) => {
            const re = /^[A-Za-z]+$/;
            if (name == "first_name" || name == "last_name") {
                if (value === "" || re.test(value)) {
                    setFormValues((oldValues) => ({
                        ...oldValues,
                        [name]: value,
                    }));
                }
            } else {
                setFormValues((oldValues) => ({ ...oldValues, [name]: value }));
            }
            ///
            setFormErrors((oldValues) => ({ ...oldValues, [name]: null }));

            validateFullForm(false, name, value ? value : "", true);
        };
    };
    const clearFormValue = (name) => {
        setFormValues((oldValues) => ({ ...oldValues, [name]: "" }));
    };

    const toggleCheck = (name, value) => {
        setFormValues((oldValues) => ({ ...oldValues, [name]: value }));
    };

    const setFormValue = (name, value) => {
        setFormValues((oldValues) => ({ ...oldValues, [name]: value }));
    };
    return (
        <LayoutTester
            skipProfileComplete={true}
            profileHeader={existingTester ? true : true}
            wrapClass={"mb-0"}
        >
            {steps.length > 0 && existingTester && (
                <div className="col-12 border-bottom-gray">
                    <div className="container">
                        <ProfileCompletionSteps
                            isLoading={isLoading || formLoading}
                            current_step={5}
                            steps={steps}
                            onClick={(navigateStep) => {
                                onSubmit(null, navigateStep);
                            }}
                        />
                    </div>
                </div>
            )}
            <div className="container">
                {isLoading && (
                    <div className="accountloading-box">
                        {" "}
                        <LoadingIcon />
                    </div>
                )}
                {!isLoading && (
                    <form
                        method="POST"
                        onSubmit={(e) => {
                            e.preventDefault();
                            onSubmit(null, null);
                        }}
                    >
                        <div className="register-page-min-hegiht">
                            <div className="row tester_sign_up_step_row justify-content-center">
                                <div className="col-md-6 graytext researcher-profilebox-right-outerbox progress-max">
                                    {!existingTester && (
                                        <PageProgressBar
                                            current_step={5}
                                            total_steps={8}
                                        />
                                    )}
                                    <div className="mt-24">
                                        <Text
                                            type={"subtitle-2"}
                                            fontWeight={"semi-bold-font"}
                                        >
                                            Your professional path
                                        </Text>
                                        <Text
                                            type={"body-text-2"}
                                            fontWeight={"medium-font"}
                                            cssClasses={"mt-12"}
                                        >
                                            We want to know more about your
                                            career and goals
                                        </Text>
                                    </div>

                                    <div className="login-form-inner-data researcher-profilebox tester-profilebox mt-32">
                                        <div
                                            className="formbox"
                                            id="employment"
                                        >
                                            <div className="profile-form-group">
                                                <DropdownComponent
                                                    options={employmentOptions}
                                                    value={
                                                        formValues.employment
                                                    }
                                                    onChange={(item) => {
                                                        if (item) {
                                                            if (
                                                                [
                                                                    "Student",
                                                                    "Unemployed",
                                                                    "Homemaker",
                                                                    "Retired",
                                                                ].includes(
                                                                    item.value
                                                                )
                                                            ) {
                                                                setEmploymentFieldsReadOnly(
                                                                    true
                                                                );
                                                                setFormValues({
                                                                    ...formValues,
                                                                    employment:
                                                                        item.value,
                                                                    department:
                                                                        "",
                                                                    industry:
                                                                        "",
                                                                    industry_other:
                                                                        "",
                                                                    department_other:
                                                                        "",
                                                                    career_stage:
                                                                        "",
                                                                    company_size:
                                                                        "",
                                                                    working_environment:
                                                                        "",
                                                                });
                                                                setFormErrors({
                                                                    ...formErrors,
                                                                    employment:null,
                                                                    department:null,
                                                                    industry:null,
                                                                    industry_other:null,
                                                                    department_other:null,
                                                                    career_stage:null,
                                                                    company_size:null,
                                                                    working_environment:null,
                                                                });
                                                            } else {
                                                                setEmploymentFieldsReadOnly(
                                                                    false
                                                                );
                                                                setFormValues({
                                                                    ...formValues,
                                                                    employment:
                                                                        item.value,
                                                                });
                                                            }
                                                        } else {
                                                            setEmploymentFieldsReadOnly(
                                                                false
                                                            );
                                                            setFormValues({
                                                                ...formValues,
                                                                employment: "",
                                                            });
                                                        }
                                                        validateFullForm(
                                                            false,
                                                            "employment",
                                                            item
                                                                ? item.value
                                                                : "",
                                                            true
                                                        );
                                                    }}
                                                    isClearable={true}
                                                    label="Your employment status"
                                                    error={
                                                        formErrors.employment
                                                    }
                                                    error_class={
                                                        formErrors.error_class
                                                    }
                                                />
                                            </div>
                                        </div>
                                        {formValues.industry !=
                                            "Other (please specify)" && (
                                            <div
                                                className="formbox"
                                                id="industry"
                                            >
                                                <div className="profile-form-group">
                                                    <DropdownComponent
                                                        options={
                                                            industryOptions
                                                        }
                                                        value={
                                                            formValues.industry
                                                        }
                                                        onChange={(item) => {
                                                            if (item) {
                                                                setFormValues({
                                                                    ...formValues,
                                                                    industry:
                                                                        item.value,
                                                                });
                                                            } else {
                                                                setFormValues({
                                                                    ...formValues,
                                                                    industry:
                                                                        "",
                                                                });
                                                            }
                                                            validateFullForm(
                                                                false,
                                                                "industry",
                                                                item
                                                                    ? item.value
                                                                    : "",
                                                                true
                                                            );
                                                        }}
                                                        isClearable={true}
                                                        label="What industry do you work in?"
                                                        error={
                                                            formErrors.industry
                                                        }
                                                        error_class={
                                                            formErrors.error_class
                                                        }
                                                        readOnly={
                                                            employmentFieldsReadOnly
                                                        }
                                                    />
                                                </div>
                                            </div>
                                        )}
                                        {formValues.industry ==
                                            "Other (please specify)" && (
                                            <div className="formbox">
                                                <Input
                                                    ref={industryRef}
                                                    type="text"
                                                    id="industry_other"
                                                    name="industry_other"
                                                    value={
                                                        formValues.industry_other
                                                    }
                                                    onChange={(e) => {
                                                        setFormValues({
                                                            ...formValues,
                                                            industry_other:
                                                                e.target.value,
                                                        });
                                                        validateFullForm(
                                                            false,
                                                            "industry_other",
                                                            e.target.value,
                                                            true
                                                        );
                                                    }}
                                                    label={"Industry (Other)"}
                                                    onClear={() => {
                                                        setFormValues({
                                                            ...formValues,
                                                            industry_other: "",
                                                            industry: "",
                                                        });
                                                    }}
                                                    required={true}
                                                    error={
                                                        formErrors.industry_other
                                                    }
                                                    error_class={
                                                        formErrors.error_class
                                                    }
                                                    onBlur={(e) => {
                                                        validateFullForm(
                                                            false,
                                                            "industry_other"
                                                        );

                                                        if (!e.target.value) {
                                                            setFormValues(
                                                                (
                                                                    oldValues
                                                                ) => ({
                                                                    ...oldValues,
                                                                    industry_other:
                                                                        "",
                                                                    industry:
                                                                        "",
                                                                })
                                                            );
                                                        }
                                                    }}
                                                />
                                            </div>
                                        )}

                                        {formValues.department !=
                                            "Other (please specify)" && (
                                            <div
                                                className="formbox"
                                                id="department"
                                            >
                                                <div className="profile-form-group">
                                                    <DropdownComponent
                                                        options={
                                                            departmentOptions
                                                        }
                                                        value={
                                                            formValues.department
                                                        }
                                                        onChange={(item) => {
                                                            if (item) {
                                                                setFormValues({
                                                                    ...formValues,
                                                                    department:
                                                                        item.value,
                                                                });
                                                            } else {
                                                                setFormValues({
                                                                    ...formValues,
                                                                    department:
                                                                        "",
                                                                });
                                                            }
                                                            validateFullForm(
                                                                false,
                                                                "department",
                                                                item
                                                                    ? item.value
                                                                    : "",
                                                                true
                                                            );
                                                        }}
                                                        isClearable={true}
                                                        label="What department do you work in?"
                                                        error={
                                                            formErrors.department
                                                        }
                                                        error_class={
                                                            formErrors.error_class
                                                        }
                                                        readOnly={
                                                            employmentFieldsReadOnly
                                                        }
                                                    />
                                                </div>
                                            </div>
                                        )}
                                        {formValues.department ==
                                            "Other (please specify)" && (
                                            <div className="formbox">
                                                <Input
                                                    ref={departmentRef}
                                                    type="text"
                                                    id="department_other"
                                                    name="department_other"
                                                    value={
                                                        formValues.department_other
                                                    }
                                                    onChange={(e) => {
                                                        setFormValues({
                                                            ...formValues,
                                                            department_other:
                                                                e.target.value,
                                                        });
                                                        validateFullForm(
                                                            false,
                                                            "department_other",
                                                            e.target.value,
                                                            true
                                                        );
                                                    }}
                                                    label={"Department (Other)"}
                                                    onClear={() => {
                                                        setFormValues(
                                                            (oldValues) => ({
                                                                ...oldValues,
                                                                department_other:
                                                                    "",
                                                                department: "",
                                                            })
                                                        );
                                                    }}
                                                    required={true}
                                                    error={
                                                        formErrors.department_other
                                                    }
                                                    error_class={
                                                        formErrors.error_class
                                                    }
                                                    onBlur={(e) => {
                                                        validateFullForm(
                                                            false,
                                                            "department_other"
                                                        );

                                                        if (!e.target.value) {
                                                            setFormValues(
                                                                (
                                                                    oldValues
                                                                ) => ({
                                                                    ...oldValues,
                                                                    department_other:
                                                                        "",
                                                                    department:
                                                                        "",
                                                                })
                                                            );
                                                        }
                                                    }}
                                                />
                                            </div>
                                        )}
                                        <div
                                            className="formbox"
                                            id="career_stage"
                                        >
                                            <div className="profile-form-group">
                                                <DropdownComponent
                                                    value={
                                                        formValues.career_stage
                                                    }
                                                    options={lovCategories
                                                        .filter(
                                                            (category) =>
                                                                category.name ==
                                                                "Career stage"
                                                        )
                                                        .flatMap(
                                                            (category) =>
                                                                category.active_lov
                                                        )
                                                        .map((active_lov) => {
                                                            return {
                                                                value: active_lov.id,
                                                                label: active_lov.lov_value,
                                                            };
                                                        })}
                                                    onChange={(item) => {
                                                        if (item) {
                                                            setFormValues({
                                                                ...formValues,
                                                                career_stage:
                                                                    item.value,
                                                            });
                                                        } else {
                                                            setFormValues({
                                                                ...formValues,
                                                                career_stage:
                                                                    "",
                                                            });
                                                        }
                                                        setFormErrors({
                                                            ...formErrors,
                                                            career_stage: null,
                                                        });
                                                    }}
                                                    isClearable={true}
                                                    label="Career stage"
                                                    error={
                                                        formErrors.career_stage
                                                    }
                                                    error_class={
                                                        formErrors.error_class
                                                    }
                                                    readOnly={
                                                        employmentFieldsReadOnly
                                                    }
                                                />
                                            </div>
                                        </div>
                                        <div
                                            className="formbox"
                                            id="company_size"
                                        >
                                            <div className="profile-form-group">
                                                <DropdownComponent
                                                    value={
                                                        formValues.company_size
                                                    }
                                                    options={lovCategories
                                                        .filter(
                                                            (category) =>
                                                                category.name ==
                                                                "Company size"
                                                        )
                                                        .flatMap(
                                                            (category) =>
                                                                category.active_lov
                                                        )
                                                        .map((active_lov) => {
                                                            return {
                                                                value: active_lov.id,
                                                                label: active_lov.lov_value,
                                                            };
                                                        })}
                                                    onChange={(item) => {
                                                        if (item) {
                                                            setFormValues({
                                                                ...formValues,
                                                                company_size:
                                                                    item.value,
                                                            });
                                                        } else {
                                                            setFormValues({
                                                                ...formValues,
                                                                company_size:
                                                                    "",
                                                            });
                                                        }
                                                        setFormErrors({
                                                            ...formErrors,
                                                            company_size: null,
                                                        });
                                                    }}
                                                    isClearable={true}
                                                    label="Company size"
                                                    error={
                                                        formErrors.company_size
                                                    }
                                                    error_class={
                                                        formErrors.error_class
                                                    }
                                                    readOnly={
                                                        employmentFieldsReadOnly
                                                    }
                                                />
                                            </div>
                                        </div>
                                        <div
                                            className="formbox"
                                            id="working_environment"
                                        >
                                            <div className="profile-form-group">
                                                <DropdownComponent
                                                    value={
                                                        formValues.working_environment
                                                    }
                                                    options={lovCategories
                                                        .filter(
                                                            (category) =>
                                                                category.name ==
                                                                "Working environment"
                                                        )
                                                        .flatMap(
                                                            (category) =>
                                                                category.active_lov
                                                        )
                                                        .map((active_lov) => {
                                                            return {
                                                                value: active_lov.id,
                                                                label: active_lov.lov_value,
                                                            };
                                                        })}
                                                    onChange={(item) => {
                                                        if (item) {
                                                            setFormValues({
                                                                ...formValues,
                                                                working_environment:
                                                                    item.value,
                                                            });
                                                        } else {
                                                            setFormValues({
                                                                ...formValues,
                                                                working_environment:
                                                                    "",
                                                            });
                                                        }
                                                        setFormErrors({
                                                            ...formErrors,
                                                            working_environment:
                                                                null,
                                                        });
                                                    }}
                                                    isClearable={true}
                                                    label="Working environment"
                                                    error={
                                                        formErrors.working_environment
                                                    }
                                                    error_class={
                                                        formErrors.error_class
                                                    }
                                                    readOnly={
                                                        employmentFieldsReadOnly
                                                    }
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="sign-up-btn-wrap tester_signup_btns mt-32">
                                        <Button
                                            type="secondary"
                                            size="large"
                                            id="r_registration"
                                            label="Back"
                                            iconLeft={
                                                <Icon
                                                    colorClass="gray-900-svg"
                                                    value="back-arrow"
                                                    size="large"
                                                />
                                            }
                                            tag={"link"}
                                            state={""}
                                            href={
                                                existingTester
                                                    ? "/tester/profile/complete/step4"
                                                    : "/tester/profile/step4"
                                            }
                                        />

                                        <Button
                                            type="primary"
                                            size="large"
                                            id="r_registration"
                                            label="Next"
                                            iconRight={
                                                <Icon
                                                    colorClass="gray-50-svg"
                                                    value="forward-arrow"
                                                    size="large"
                                                />
                                            }
                                            microLoading={formLoading}
                                            state={
                                                checkBtnState()
                                                    ? "disabled"
                                                    : "active"
                                            }
                                        />
                                    </div>
                                </div>
                                {!existingTester && (
                                    <div className="col-md-6 graytext position-relative none-for-mobile">
                                        <SignUpInformationalCard
                                            icon={
                                                <Icon
                                                    size={"large"}
                                                    value={"credits-gold"}
                                                />
                                            }
                                            title="Request a payout when you reach $30"
                                            info="Take between 15/20 short tests to redeem your cash reward."
                                        />
                                    </div>
                                )}
                            </div>
                        </div>
                    </form>
                )}
            </div>
        </LayoutTester>
    );
}
