import React, { useEffect, useState, useRef } from "react";
import { useNavigate } from 'react-router-dom';
import { Modal } from "react-bootstrap";
import { LoadingIcon } from "../../../themes/userq/Loader/loadingIcon";
import { Icon } from  "../../../themes/userq/Icon/Icon";
import { Text } from "../../../themes/userq/Text/Text";
import { Button } from "../../../themes/userq/Button/Button";


export default function GoalScreen({open, file_key, file_name, device, source_node_id, goal_node_id, updateGoalScreen, rtl, version, close}){

    const [node_id, setNode] = useState(null);

    const iframeRef = useRef(null);

    useEffect(()=>{
        if(open){

            setNode(null);

        }
    },[open])
    const closeModal = () => {
        window.removeEventListener("message", receiveMessage, false);
        close();
    };
    window.addEventListener("message", receiveMessage, false);

    function receiveMessage(event) {
        var data = event.data;

        if(data.type=="PRESENTED_NODE_CHANGED" && data.data.presentedNodeId){
            var  node_loc_id = data.data.presentedNodeId;

            setNode(node_loc_id)
        }
    }

    const wrapperRef = useRef(null);

    function useOutsideAlerter(ref) {
        useEffect(() => {
            function handleClickOutside(event) {
                if (ref.current && !ref.current.contains(event.target)) {
                    if(event.target.nodeName =="DIV"){
                        closeModal();
                    }
                }
            }
            // Bind the event listener
            document.addEventListener("mousedown", handleClickOutside);
            return () => {
                // Unbind the event listener on clean up
                document.removeEventListener("mousedown", handleClickOutside);
            };
        }, [ref]);
    }

    useOutsideAlerter(wrapperRef);

    const maximizeIframe = ()=>{
        if (iframeRef.current) {
            if (iframeRef.current.requestFullscreen) {
                iframeRef.current.requestFullscreen();
            } else if (iframeRef.current.mozRequestFullScreen) { // Firefox
                iframeRef.current.mozRequestFullScreen();
            } else if (iframeRef.current.webkitRequestFullscreen) { // Chrome, Safari, Opera
                iframeRef.current.webkitRequestFullscreen();
            } else if (iframeRef.current.msRequestFullscreen) { // IE/Edge
                iframeRef.current.msRequestFullscreen();
            }
        }
    }
    return (
        <Modal
            show={open}
            centered
            className={`fade custom-modal-wrap ${device && device=="mobile" ? "mobile-iframe-wrapper":""}  ${rtl?"arabic-wrapper":""}  confirmation-modal-wrap goal-screen-modal-wrap set-goal-modal-wrap set-goal-screen-with-buttons`}
            onHide={() => {
                closeModal();
            }}

        >
            <Modal.Body className=""  ref={wrapperRef}>

                <div className="modal-inner-text">
                    <div className="modal-header-top">
                        <Text type={'h3'}>Set a goal screen</Text>
                       
                        <div className="d-flex align-items-center modal-set-goal-top">
                            <Button
                                type={"primary"}
                                size={"small"}
                                label={rtl?"حدد صفحة الهدف":"Set as goal screen"}
                                iconLeft={
                                    <Icon value={"target"} colorClass={'gray-50-svg'} size={"medium"}  />
                                }
                                state={(source_node_id==node_id || !node_id )?"disabled":"active"}
                                onClick={()=>{
                                    if(source_node_id!=node_id && node_id!=null){
                                        updateGoalScreen(node_id)
                                    }
                                }}
                            />
                            <button
                                type="button"
                                className="close"
                                onClick={() => {

                                    closeModal();
                                }}
                                aria-label="Close"
                            >
                            <span aria-hidden="true">
                                <Icon value="Close" size="medium" hover={true} />
                            </span>
                            </button> 
                        </div>
                    </div>

                    <Text type={"body-text-3"} cssClasses="set-goal-sub-heading" fontWeight={"medium-font"}>Navigate the prototype until the end screen and press “set as goal screen”. A task is successful when the participant reaches the goal screen.</Text>
                    <div className="iframewrap-max-height">
                        <iframe
                            ref={iframeRef}
                            id="figma_prototype_iframe" 
                            height="450"
                            width="800"
                            src={"https://embed.figma.com/proto/"+file_key+"/"+file_name+"?node-id="+source_node_id+"&version-id="+version+"&starting-point-node-id="+source_node_id+"&fullscreen=1&hide-ui=1&hotspot-hints=0&scaling=scale-down-width&embed-host=userq&client-id="+process.env.REACT_APP_FIGMA_CLIENT_ID}
                            allowFullScreen

                        /> 
                        <div onClick={(e)=>{maximizeIframe()}} className="figma-prototype-controls figma-prototype-control-maximize">
                            <Icon value={"maximize"} size={"medium"} />
                        </div>
                    </div>
                    <div className="set-goal-btn d-flex align-items-center justify-content-center">

                        <Button
                            type={"primary"}
                            label={rtl?"حدد صفحة الهدف":"Set as goal screen"}
                            size={"medium"}
                            iconLeft={
                                <Icon value={"target"} colorClass={'gray-50-svg'} size={"medium"}  />
                            }
                            state={(source_node_id==node_id || !node_id )?"disabled":"active"}
                            onClick={()=>{
                                if(source_node_id!=node_id && node_id!=null){
                                    updateGoalScreen(node_id);
                                }
                            }}
                        />
                    </div>

                </div>

            </Modal.Body>
        </Modal>
    );
}