import React, { useEffect, useState, useRef, useImperativeHandle } from "react";
import { Icon } from "./../Icon/Icon";
import { Button } from "./../Button/Button";
import { Text } from "../Text/Text";
import { Tag } from "./../Tag/Tag";
import { Input } from "./../Input/Input";
import toast from "react-hot-toast";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import Options from "./options";
import { isEmpty } from "lodash";
import { Dropdown } from "react-bootstrap";
import { ToggleButton } from "../ToggleButton/ToggleButton";
import { FilterButtons } from "../FilterButtons/FilterButtons";
import { DropdownComponent } from "../Dropdown/Dropdown";
import { ToastMessage } from "../ToastMessage/ToastMessage";
import { settings } from "../../../../data/settings";
import { InputPlaceholder } from "../Input/InputPlaceholder";
import { GuideLink } from "../GuideLink/GuideLink";
import { QuestionImage } from "../QuestionImage/QuestionImage";
import { RadioButton } from "../RadioButton/RadioButton";

export const QuestionInput = React.forwardRef(
    (
        {
            id_prefix,
            questionType,
            isOptionalNotAllowed,
            cancelQuestion,
            addQuestions,
            language,
            questionno,
            questionlist,
            changeQuestionType,
            islogic,
            methodology,
            onUpdate,
        },
        ref
    ) => {
        const [minMaxRules, setMinMaxRules] = useState(false);

        const [minMaxRulesType, setMinMaxRulesType] = useState("");

        const [minOptionsSet, setMinOptionsSet] = useState([0, 0]);

        const [maxOptionsSet, setMaxOptionsSet] = useState([0, 0]);

        const [exactOptionsSet, setExactOptionsSet] = useState([0, 0]);

        const [minMaxRulesState, setMinMaxRulesState] = useState(false);

        const [preservedAction, setPreservedAction] = useState(false);

        const [preservedTooltips, setPreservedTooltips] = useState({
            other: "",
            none_of_the_above: "",
        });

        const [questionFormValues, setQuestionFormValues] = useState({
            question: "",
            image_id: null,
            image_url: null,
            is_optional: false,
            is_logic: false,
            jump_to: "End Survey",
        });
        const [questionFormErrors, setQuestionFormErrors] = useState({
            question: null,
            min: null,
            max: null,
            exact: null,
            error_class: null,
        });
        const [allOptions, setAllOptions] = useState(["", ""]);
        const [optionImages, setOptionImages] = useState([
            { image_id: null, image_url: null },
            { image_id: null, image_url: null },
        ]);
        const [isNoneOfTheAbove, setNoneOfTheAbove] = useState(false);
        const [noneOfTheAboveEnText] = useState("None of the above");
        const [noneOfTheAboveArText] = useState("لا شيء مما بالأعلى");

        const [tempAllOptions, setTempAllOptions] = useState(["", ""]);
        const [allJumpTo, setAllJumpTo] = useState([]);
        const [unsavedOptions, setUnsavedOptions] = useState([""]);
        const [optionErrorIndex, setOptionErrorIndex] = useState(null);
        const [otherOptionIncluded, setOtherOptionIncluded] = useState(false);
        const [likertSubQuestions, setLikertSubQuestions] = useState([""]);
        const queryAttr = "data-rbd-drag-handle-draggable-id";
        const [placeholderProps, setPlaceholderProps] = useState({});
        const [logicAdded, setLogicAdded] = useState(false);
        const [jumpToListItems, setJumpToListItems] = useState([]);
        const [likertScaleLength, setLikertScaleLength] = useState("5 points");
        const [likertScaleType, setLikertScaleType] = useState(
            language === "en" ? "Satisfaction" : "مقياس الرضا"
        );

        const [dropdownOptions, setDropdownOptions] = useState([]);
        const [likertStatement, setLikertStateMent] = useState(
            "“How satisfied are you with the speed of response to your enquiry?”"
        );

        const [ratingScaleLength, setRatingScaleLength] = useState("3 points");
        const [ratingScaleType, setRatingScaleType] = useState("Numeric");
        const [ratingScaleLabels, setRatingScaleLabels] = useState(["", "", ""]);

        const wrapperRef = useRef(null);

        const questionTypes = [
            { label: "Free text", value: "free" },
            { label: "Single choice", value: "singlechoice" },
            { label: "Multiple choice", value: "multiplechoice" },
            { label: "Likert scale", value: "likertscale" },
            { label: "Rating scale", value: "ratingscale" },
            { label: "Ranking scale", value: "rankingscale" },
        ];
        const likert5ScaleOptions = [
            {
                label: language === "en" ? "Satisfaction" : "مقياس الرضا",
                value: language === "en" ? "Satisfaction" : "مقياس الرضا",
            },
            {
                label: language === "en" ? "Likelihood" : "مقياس الاحتمالية",
                value: language === "en" ? "Likelihood" : "مقياس الاحتمالية",
            },
            {
                label: language === "en" ? "Level of concern" : "مقياس مستوى القلق",
                value: language === "en" ? "Level of concern" : "مقياس مستوى القلق",
            },
            {
                label: language === "en" ? "Agreement" : "مقياس الاتفاق",
                value: language === "en" ? "Agreement" : "مقياس الاتفاق",
            },
            {
                label: language === "en" ? "Frequency" : "مقياس التكرار",
                value: language === "en" ? "Frequency" : "مقياس التكرار",
            },
            {
                label: language === "en" ? "Awareness" : "مقياس الوعي",
                value: language === "en" ? "Awareness" : "مقياس الوعي",
            },
            {
                label: language === "en" ? "Familiarity" : "مقياس الألفة",
                value: language === "en" ? "Familiarity" : "مقياس الألفة",
            },
            {
                label: language === "en" ? "Quality" : "مقياس الجودة",
                value: language === "en" ? "Quality" : "مقياس الجودة",
            },
            {
                label: language === "en" ? "Importance" : "مقياس الأهمية",
                value: language === "en" ? "Importance" : "مقياس الأهمية",
            },
        ];

        const likert7ScaleOptions = [
            {
                label: language === "en" ? "Agreement" : "مقياس الاتفاق",
                value: language === "en" ? "Agreement" : "مقياس الاتفاق",
            },
            {
                label: language === "en" ? "Frequency" : "مقياس التكرار",
                value: language === "en" ? "Frequency" : "مقياس التكرار",
            },
            {
                label: language === "en" ? "Appropriateness" : "مقياس الملاءمة",
                value: language === "en" ? "Appropriateness" : "مقياس الملاءمة",
            },
            {
                label: language === "en" ? "Satisfaction" : "مقياس الرضا",
                value: language === "en" ? "Satisfaction" : "مقياس الرضا",
            },
            {
                label: language === "en" ? "Reflective of me" : "مقياس عكس الشخصية",
                value: language === "en" ? "Reflective of me" : "مقياس عكس الشخصية",
            },
            {
                label: language === "en" ? "Level of difficulty" : "مستوى الصعوبة",
                value: language === "en" ? "Level of difficulty" : "مستوى الصعوبة",
            },
            {
                label: language === "en" ? "Priority" : "مقياس الأولوية",
                value: language === "en" ? "Priority" : "مقياس الأولوية",
            },
            {
                label: language === "en" ? "Quality" : "مقياس الجودة",
                value: language === "en" ? "Quality" : "مقياس الجودة",
            },
            {
                label: language === "en" ? "Importance" : "مقياس الأهمية",
                value: language === "en" ? "Importance" : "مقياس الأهمية",
            },
        ];

        const ratingScaleOptions = [
            {
                label: (
                    <span className="dropdown-icon">
                        <Icon value={"Numeric"} size={"small"} /> {"   "}Numeric
                    </span>
                ),
                value: "Numeric",
            },
            {
                label: (
                    <span className="dropdown-icon">
                        <Icon value={"Star"} size={"small"} /> {"   "}Stars
                    </span>
                ),
                value: "Stars",
            },
            {
                label: (
                    <span className="dropdown-icon">
                        <Icon value={"Emotions"} size={"small"} /> {"   "}
                        Emotions
                    </span>
                ),
                value: "Emotions",
            },
        ];

        const mapLikertOptions = (scaleType) => {
            if (likertScaleLength == "5 points") {
                if (scaleType == "Satisfaction" || scaleType == "مقياس الرضا") {
                    if (language == "en") {
                        setAllOptions([
                            "Very dissatisfied",
                            "Dissatisfied",
                            "Neither dissatisfied or satisfied",
                            "Satisfied",
                            "Very satisfied",
                            "Not applicable",
                        ]);
                    } else {
                        setAllOptions([
                            "مستاء جدا",
                            "غير راض",
                            "لا غير راض أو راض",
                            "راضي",
                            "راضي جدا",
                            "لا ينطبق عليّ",
                        ]);
                    }
                    setLikertStateMent(
                        "“How satisfied are you with the speed of response to your enquiry?”"
                    );
                }
                if (scaleType == "Likelihood" || scaleType == "مقياس الاحتمالية") {
                    if (language == "en") {
                        setAllOptions([
                            "Very unlikely",
                            "Unlikely",
                            "Neutral",
                            "Likely",
                            "Very likely",
                            "Not applicable",
                        ]);
                    } else {
                        setAllOptions([
                            "مستبعد جدا",
                            "غير مرجح",
                            "محايد",
                            "محتمل",
                            "مرجح جدا",
                            "لا ينطبق عليّ",
                        ]);
                    }
                    setLikertStateMent(
                        "“Would you recommend our customer care service to others, based on your experience?”"
                    );
                }
                if (
                    scaleType == "Level of concern" ||
                    scaleType == "مقياس مستوى القلق"
                ) {
                    if (language == "en") {
                        setAllOptions([
                            "Very unconcerned",
                            "Unconcerned",
                            "Neutral",
                            "concerned",
                            "Very Concerned",
                            "Not applicable",
                        ]);
                    } else {
                        setAllOptions([
                            " غير قلق ابداً",
                            "غير قلق",
                            "محايد",
                            "قلق",
                            "قلق جداً",
                            "لا ينطبق عليّ",
                        ]);
                    }
                    setLikertStateMent(
                        "“How concerned are you about the customer care service's ability to address your inquiries and issues?”"
                    );
                }
                if (scaleType == "Agreement" || scaleType == "مقياس الاتفاق") {
                    if (language == "en") {
                        setAllOptions([
                            "Strongly disagree",
                            "Disagree",
                            "Neither agree or disagree",
                            "Agree",
                            "Strongly agree",
                            "Not applicable",
                        ]);
                    } else {
                        setAllOptions([
                            "لا أوافق بشدة",
                            "غير موافق",
                            "لا أوافق ولا أعارض",
                            "أوافق",
                            "أوافق بشدة",
                            "لا ينطبق عليّ",
                        ]);
                    }
                    setLikertStateMent(
                        "“The customer service representatives were helpful and knowledgeable”"
                    );
                }
                if (scaleType == "Frequency" || scaleType == "مقياس التكرار") {
                    if (language == "en") {
                        setAllOptions([
                            "Never",
                            "Rarely",
                            "Sometimes",
                            "Often",
                            "Always",
                            "Not applicable",
                        ]);
                    } else {
                        setAllOptions([
                            "أبدا",
                            "نادرا",
                            "أحيانا",
                            "غالبا",
                            "دائما",
                            "لا ينطبق عليّ",
                        ]);
                    }
                    setLikertStateMent(
                        "“How often do you call our customer service line?”"
                    );
                }
                if (scaleType == "Awareness" || scaleType == "مقياس الوعي") {
                    if (language == "en") {
                        setAllOptions([
                            "Very unaware",
                            "Unaware",
                            "Neither aware or unaware",
                            "Aware",
                            "Very aware",
                            "Not applicable",
                        ]);
                    } else {
                        setAllOptions([
                            "غير مدرك جدا",
                            "غير مدرك",
                            "لا مدرك ولا غير مدرك",
                            "مدرك",
                            "مدرك جدا",
                            "لا ينطبق عليّ",
                        ]);
                    }
                    setLikertStateMent(
                        "“Are you aware of our customer care service channels and resources for support?”"
                    );
                }
                if (scaleType == "Familiarity" || scaleType == "مقياس الألفة") {
                    if (language == "en") {
                        setAllOptions([
                            "Very unfamiliar",
                            "Unfamiliar",
                            "Somewhat familiar",
                            "Familiar",
                            "Very familiar",
                            "Not applicable",
                        ]);
                    } else {
                        setAllOptions([
                            "غير مألوف",
                            "غير مألوف",
                            "مألوف إلى حد ما",
                            "مألوف",
                            "مألوف جدا",
                            "لا ينطبق عليّ",
                        ]);
                    }
                    setLikertStateMent(
                        "“How familiar are you with the various support options offered by the customer care service?”"
                    );
                }
                if (scaleType == "Quality" || scaleType == "مقياس الجودة") {
                    if (language == "en") {
                        setAllOptions([
                            "Very poor",
                            "Poor",
                            "Acceptable",
                            "Good",
                            "Very good",
                            "Not applicable",
                        ]);
                    } else {
                        setAllOptions([
                            "قليلة الجودة جدا",
                            "قليلة الجودة",
                            "مقبولة",
                            "جيدة",
                            "جيدة جدا",
                            "لا ينطبق عليّ",
                        ]);
                    }
                    setLikertStateMent(
                        "“Attention to detail and accuracy in addressing my inquiries”"
                    );
                }
                if (scaleType == "Importance" || scaleType == "مقياس الأهمية") {
                    if (language == "en") {
                        setAllOptions([
                            "Very unimportant",
                            "Not important",
                            "Neutral",
                            "Important",
                            "Very important",
                            "Not applicable",
                        ]);
                    } else {
                        setAllOptions([
                            "غير مهمة للغاية",
                            "غير مهمة",
                            "محايدة",
                            "مهمة",
                            "مهمة جدا",
                            "لا ينطبق عليّ",
                        ]);
                    }
                    setLikertStateMent(
                        "“How important is timely and satisfactory support from our customer care service?”"
                    );
                }
            }
            if (likertScaleLength == "7 points") {
                if (scaleType == "Agreement" || scaleType == "مقياس الاتفاق") {
                    if (language == "en") {
                        setAllOptions([
                            "Strongly disagree",
                            "Disagree",
                            "Somewhat disagree",
                            "Neither agree or disagree",
                            "Somewhat agree",
                            "Agree",
                            "Strongly agree",
                            "Not applicable",
                        ]);
                    } else {
                        setAllOptions([
                            "أعارض بشدة",
                            "أعارض",
                            "لا أوافق إلى حد ما",
                            "لا أوافق ولا أعارض",
                            "أوافق إلى حد ما",
                            "أوافق",
                            "أوافق بشدة",
                            "لا ينطبق عليّ",
                        ]);
                    }
                    setLikertStateMent(
                        "“The customer service representatives were helpful and knowledgeable”"
                    );
                }
                if (scaleType == "Frequency" || scaleType == "مقياس التكرار") {
                    if (language == "en") {
                        setAllOptions([
                            "Never",
                            "Rarely (less than 10% of the time)",
                            "Occasionally (about 30% of the time)",
                            "Sometimes (about 50% of the time)",
                            "Frequently (about 70% of the time)",
                            "Usually (about 90% of the time)",
                            "Everytime",
                            "Not applicable",
                        ]);
                    } else {
                        setAllOptions([
                            "أبدا",
                            "نادرا (أقل من 10% من المرات)",
                            "من حين الى آخر (حوالي 30% من المرات)",
                            "أحيانا (حوالي 50% من المرات)",
                            "في كثير من الأحيان (حوالي 70% من المرات)",
                            "عادة (حوالي 90% من المرات)",
                            "كل مرة",
                            "لا ينطبق عليّ",
                        ]);
                    }
                    setLikertStateMent(
                        "“How often do you call our customer service line?”"
                    );
                }
                if (scaleType == "Appropriateness" || scaleType == "مقياس الملاءمة") {
                    if (language == "en") {
                        setAllOptions([
                            "Absolutely inappropriate",
                            "Inappropriate",
                            "Slightly Inappropriate",
                            "Neutral",
                            "Slightly appropriate",
                            "Appropriate",
                            "Absolutely appropriate",
                            "Not applicable",
                        ]);
                    } else {
                        setAllOptions([
                            "غير ملائم  إطلاقا",
                            "غير ملائم",
                            "غير ملائم إلى حد ما",
                            "محايد",
                            "ملائم إلى حد ما",
                            "ملائم",
                            "ملائم تمام",
                            "لا ينطبق عليّ",
                        ]);
                    }
                    setLikertStateMent(
                        "“The tone and language used by our customer care representatives”"
                    );
                }
                if (scaleType == "Satisfaction" || scaleType == "مقياس الرضا") {
                    if (language == "en") {
                        setAllOptions([
                            "Very dissatisfied",
                            "Dissatisfied",
                            "Slightly dissatisfied",
                            "Neutral",
                            "Slightly satisfied",
                            "Satisfied",
                            "Very satisfied",
                            "Not applicable",
                        ]);
                    } else {
                        setAllOptions([
                            "مستاء جدا",
                            "غير راض",
                            "غير راض إلى حد ما",
                            "محايد",
                            "راض إلى حد ما",
                            "راضي",
                            "راضي جدا",
                            "لا ينطبق عليّ",
                        ]);
                    }
                    setLikertStateMent(
                        "“How satisfied are you with the speed of response to your enquiry?”"
                    );
                }
                if (
                    scaleType == "Reflective of me" ||
                    scaleType == "مقياس عكس الشخصية"
                ) {
                    if (language == "en") {
                        setAllOptions([
                            "Very untrue of me",
                            "Untrue of me",
                            "Somewhat untrue of me",
                            "Neutral",
                            "Somewhat true of me",
                            "True of me",
                            "Very true of me",
                            "Not applicable",
                        ]);
                    } else {
                        setAllOptions([
                            "غير حقيقي ابدا بالنسبة لي",
                            "غير حقيقي بالنسبة لي",
                            "غير حقيقي بالنسبة لي إلى حد ما",
                            "محايد",
                            "حقيقي بالنسبة لي إلى حد ما",
                            "حقيقي بالنسبة لي",
                            "حقيقي جدا بالنسبة لي",
                            "لا ينطبق عليّ",
                        ]);
                    }
                    setLikertStateMent(
                        "“I prefer customer care to respond to me via email, as I value an uninterrupted workday”"
                    );
                }
                if (
                    scaleType == "Level of difficulty" ||
                    scaleType == "مستوى الصعوبة"
                ) {
                    if (language == "en") {
                        setAllOptions([
                            "Very easy",
                            "Easy",
                            "Somewhat easy",
                            "Neutral",
                            "Somewhat hard",
                            "Hard",
                            "Very hard",
                            "Not applicable",
                        ]);
                    } else {
                        setAllOptions([
                            "سهل جدا",
                            "سهل",
                            "سهل إلى حد ما",
                            "محايد",
                            "صعب إلى حد ما",
                            "صعب",
                            "صعب جدا",
                            "لا ينطبق عليّ",
                        ]);
                    }
                    setLikertStateMent(
                        "“How easy is it to reach our customer care service via phone?”"
                    );
                }
                if (scaleType == "Priority" || scaleType == "مقياس الأولوية") {
                    if (language == "en") {
                        setAllOptions([
                            "Not a priority",
                            "Low priority",
                            "Somewhat a priority",
                            "Neutral",
                            "Moderate priority",
                            "High priority",
                            "Essential priority",
                            "Not applicable",
                        ]);
                    } else {
                        setAllOptions([
                            "ليست أولوية",
                            "أولوية منخفضة",
                            "أولوية إلى حد ما",
                            "محايد",
                            "أولوية معتدلة",
                            "ذو أهمية عالية",
                            "أولوية أساسية",
                            "لا ينطبق عليّ",
                        ]);
                    }
                    setLikertStateMent(
                        "“Receiving proactive communication and updates from the customer care service”"
                    );
                }
                if (scaleType == "Quality" || scaleType == "مقياس الجودة") {
                    if (language == "en") {
                        setAllOptions([
                            "Very poor",
                            "Poor",
                            "Below average",
                            "Average",
                            "Above Average",
                            "Good",
                            "Excellent",
                            "Not applicable",
                        ]);
                    } else {
                        setAllOptions([
                            "قليلة الجودة جدا",
                            "قليلة الجودة",
                            "أقل من المتوسط",
                            "متوسط",
                            "فوق المتوسط",
                            "جيد",
                            "ممتاز",
                            "لا ينطبق عليّ",
                        ]);
                    }
                    setLikertStateMent(
                        "“Attention to detail and accuracy in addressing my inquiries”"
                    );
                }
                if (scaleType == "Importance" || scaleType == "مقياس الأهمية") {
                    if (language == "en") {
                        setAllOptions([
                            "Very unimportant",
                            "Not important",
                            "Slightly unimportant",
                            "Neutral",
                            "Slightly important",
                            "Important",
                            "Very important",
                            "Not applicable",
                        ]);
                    } else {
                        setAllOptions([
                            "غير مهمة للغاية",
                            "غير مهمة",
                            "غير مهمة إلى حد ما",
                            "محايد",
                            "مهمة إلى حد ما",
                            "مهمة",
                            "مهمة جدا",
                            "لا ينطبق عليّ",
                        ]);
                    }
                    setLikertStateMent(
                        "“How important is timely and satisfactory support from our customer care service?”"
                    );
                }
            }
        };
        useEffect(() => {
            if (onUpdate) {
                onUpdate({
                    ...questionFormValues,
                    options: allOptions,
                    optionImages: optionImages,
                });
            }
            if (questionType == "multiplechoice") {
                let nonEmptyOptions = 0;

                allOptions.forEach((option) => {
                    if (option) {
                        nonEmptyOptions++;
                    }
                });

                let question_edit_form_values = { ...questionFormValues };

                let question_errors = { ...questionFormErrors };

                let edit_form_values_update = false;

                if (nonEmptyOptions > 2) {
                    setMinMaxRulesState(false);
                } else {
                    setMinMaxRulesState(true);

                    if (minMaxRules) {
                        setMinMaxRules(false);

                        setQuestionFormErrors({
                            ...questionFormErrors,
                            min_max: null,
                            exact: null,
                            question: null,
                        });
                    }
                }
                if (
                    questionFormValues.max &&
                    questionFormValues.max < nonEmptyOptions
                ) {
                    setMinOptionsSet([1, questionFormValues.max - 1]);
                } else {
                    setMinOptionsSet([1, nonEmptyOptions - 1]);

                    if (
                        questionFormValues.max &&
                        !(
                            questionFormValues.max >= 1 &&
                            questionFormValues.max <= nonEmptyOptions - 1
                        )
                    ) {
                        question_edit_form_values.max = null;

                        question_errors.min_max = null;

                        edit_form_values_update = true;
                    }
                }

                if (
                    questionFormValues.min &&
                    questionFormValues.min < nonEmptyOptions
                ) {
                    setMaxOptionsSet([questionFormValues.min + 1, nonEmptyOptions - 1]);
                } else {
                    setMaxOptionsSet([1, nonEmptyOptions - 1]);

                    if (
                        questionFormValues.min &&
                        !(
                            questionFormValues.min >= 1 &&
                            questionFormValues.min <= nonEmptyOptions - 1
                        )
                    ) {
                        question_edit_form_values.min = null;

                        edit_form_values_update = true;

                        question_errors.min_max = null;
                    }
                }

                setExactOptionsSet([1, nonEmptyOptions - 1]);

                if (
                    questionFormValues.exact &&
                    !(
                        questionFormValues.exact >= 1 &&
                        questionFormValues.exact <= nonEmptyOptions - 1
                    )
                ) {
                    question_edit_form_values.exact = null;

                    edit_form_values_update = true;

                    question_errors.min_max = null;
                }
                if (edit_form_values_update) {
                    setQuestionFormValues(question_edit_form_values);

                    setQuestionFormErrors(question_errors);
                }
            }
        }, [questionFormValues, allOptions]);

        const setQuestion = (name) => {
            return ({ target: { value } }) => {
                setQuestionFormValues((oldValues) => ({
                    ...oldValues,
                    [name]: value,
                }));
                if (onUpdate) {
                    onUpdate({ ...questionFormValues, question: value });
                }
                if (value.length > 0) {
                    setQuestionFormErrors({
                        question: null,
                        error_class: null,
                    });
                }
            };
        };
        const showError = (error) => {
            toast(
                <ToastMessage
                    type={"error"}
                    message={error}
                    closable={true}
                    onClose={() => {
                        toast.dismiss();
                    }}
                />,
                {
                    id: "error_question",
                    className: "errortoast",
                    position: "bottom-center",
                    duration: settings.toast_duration,
                }
            );
        };

        const showSuccess = (success) => {
            toast(
                <ToastMessage
                    type={"success"}
                    message={success}
                    closable={true}
                    onClose={() => {
                        toast.dismiss();
                    }}
                />,
                {
                    className: "successtoast",
                    position: "bottom-center",
                    duration: settings.toast_duration,
                }
            );
        };
        const closeOptionHandler = (index) => {
            const list = [...allOptions];
            list.splice(index, 1);

            setAllOptions(list);

            allJumpTo.splice(index, 1);

            setAllJumpTo([...allJumpTo]);

            optionImages.splice(index, 1);

            setOptionImages([...optionImages]);

            console.log(list);
        };
        const closeOtherOptionHandler = () => {
            console.log("adasdas");
            const list = [...allOptions];
            list.pop();
            setAllOptions(list);
            const optionsList = [...optionImages];
            optionsList.pop();
            setOptionImages(optionsList);
            setOtherOptionIncluded(false);
        };
        const saveOptionHandler = (index, value) => {
            if (value == "Other" && language == "en") {
                if (!allOptions.includes("Other")) {
                    allOptions.splice(allOptions.length - 1, 1, value);
                    setAllOptions([...allOptions]);
                    optionImages.splice(optionImages.length - 1, 1, {
                        image_id: null,
                        image_url: null,
                    });
                    setOptionImages([...optionImages]);
                }

                setOtherOptionIncluded(true);
            } else {
                allOptions.splice(index, 1, value);
                setAllOptions([...allOptions]);
            }
        };
        const saveOptionJumpToHandler = (index, value) => {
            allJumpTo.splice(index, 1, value);
            setAllJumpTo([...allJumpTo]);
        };
        const addSubQuestionHandler = () => {
            likertSubQuestions.push("");
            setLikertSubQuestions([...likertSubQuestions]);
        };
        const removeSubQuestionHandler = (index) => {
            if (likertSubQuestions.length == 1) {
                showError("Minimum one option is required.");
            } else {
                const list = [...likertSubQuestions];
                list.splice(index, 1);
                setLikertSubQuestions(list);
            }
        };
        const editOptionHandler = (index) => {
            const list = [...allOptions];
            const optionToBeEdited = list[index];
            list.splice(index, 1);
            setAllOptions(list);
            const listUnsavedOptions = [...unsavedOptions];
            listUnsavedOptions.splice(0, 0, optionToBeEdited);
            setUnsavedOptions(listUnsavedOptions);
        };
        const showQuestionFormError = (name, value) => {
            let formErrorsLocal = Object.assign({}, questionFormErrors);
            for (var key in questionFormErrors) {
                formErrorsLocal[key] = null;
            }
            formErrorsLocal[name] = value;
            formErrorsLocal["error_class"] = "input_error";
            setQuestionFormErrors(formErrorsLocal);
        };

        useImperativeHandle(ref, () => ({
            handleQuestionSubmit: handleQuestionSubmit,
            resetErrors: resetErrors,
        }));
        const resetErrors = () => {
            let formErrorsLocal = Object.assign({}, questionFormErrors);
            for (var key in questionFormErrors) {
                formErrorsLocal[key] = null;
            }
            formErrorsLocal["error_class"] = "input_error";
            setQuestionFormErrors(formErrorsLocal);
        };

        const checkBtnstatus = () => {
            var error = false;

            if (questionFormValues.question === "") {
                error = true;
            }
            if (
                !error &&
                (questionType == "singlechoice" || questionType == "multiplechoice")
            ) {
                if (preservedAction) {
                    error = true;
                }
            }
            if (
                !error &&
                (questionType == "singlechoice" ||
                    questionType == "multiplechoice" ||
                    questionType == "multiplechoice" ||
                    questionType == "rankingscale")
            ) {
                let nulloptioncount = 0;
                allOptions.forEach((element) => {
                    if (element !== "") {
                        nulloptioncount = nulloptioncount + 1;
                    }
                });

                const notEmptyOptions = [...allOptions];
                for (var i = 0; i < notEmptyOptions.length; i++) {
                    if (notEmptyOptions[i] == "" || notEmptyOptions[i] == " ") {
                        notEmptyOptions.splice(i, 1);
                        i--;
                    }
                }
                if (nulloptioncount < 2) {
                    error = true;
                }
            }
            if (!error && questionType == "likertscale") {
                let nulloptioncount = 0;
                likertSubQuestions.forEach((element) => {
                    if (element !== "") {
                        nulloptioncount = nulloptioncount + 1;
                    }
                });

                const notEmptyOptions = [...likertSubQuestions];
                for (var i = 0; i < notEmptyOptions.length; i++) {
                    if (notEmptyOptions[i] == "" || notEmptyOptions[i] == " ") {
                        notEmptyOptions.splice(i, 1);
                        i--;
                    }
                }
                if (nulloptioncount < 1) {
                    error = true;
                }
            }
            if (!error && questionType == "multiplechoice") {
                let nonEmptyOptions = 0;

                allOptions.forEach((option) => {
                    if (option) {
                        nonEmptyOptions++;
                    }
                });
                // if (nonEmptyOptions <= 2 && minMaxRules) {
                //   error = true;
                // } else
                if (nonEmptyOptions > 2) {
                    if (minMaxRules) {
                        // if (!minMaxRulesType) {
                        //   error = true;
                        // } else
                        if (minMaxRulesType == "exact") {
                            if (!questionFormValues.exact) {
                                error = true;
                            }
                        } else if (minMaxRulesType == "min_max") {
                            if (!questionFormValues.min && !questionFormValues.max) {
                                error = true;
                            }
                            // } else if (questionFormValues.min && !questionFormValues.max) {
                            //   if (!(questionFormValues.min <= nonEmptyOptions)) {
                            //     error = true;
                            //   }
                            // } else if (!questionFormValues.min && questionFormValues.max) {
                            //   if (!(questionFormValues.max <= nonEmptyOptions - 1)) {
                            //     error = true;
                            //   }
                            // } else if (questionFormValues.min && questionFormValues.max) {
                            //   if (
                            //     !(
                            //       questionFormValues.min <= nonEmptyOptions &&
                            //       questionFormValues.max <= nonEmptyOptions - 1
                            //     ) ||
                            //     !(questionFormValues.min < questionFormValues.max)
                            //   ) {
                            //     error = true;
                            //   }
                            // }
                        }
                    }
                }
            }
            return error;
        };
        const handleQuestionSubmit = (showToast) => {
            if (questionType == "singlechoice" || questionType == "multiplechoice") {
                if (preservedAction) {
                    return;
                }
            }

            setOptionErrorIndex(null);

            var checkIfErrorExits = false;

            if (questionFormValues.question === "") {
                showQuestionFormError("question", "Required field");
                if (
                    document.getElementById("question" + (id_prefix ? id_prefix : ""))
                ) {
                    document
                        .getElementById("question" + (id_prefix ? id_prefix : ""))
                        .scrollIntoView({
                            behavior: "smooth",
                            block: "center",
                        });
                }

                checkIfErrorExits = true; // check if error exists while adding question
            } else {
                if (questionType === "free") {
                    if (addQuestions) {
                        addQuestions({
                            questionType: questionType,
                            question: questionFormValues.question,
                            is_optional: questionFormValues.is_optional,
                            is_logic:
                                methodology === "Survey" ? questionFormValues.is_logic : false,
                            jump_to_question: questionFormValues.is_logic
                                ? questionFormValues.jump_to
                                : "",
                            error: 0,
                            errorvalue: "",
                            manuallyupdated: false,
                            logicupdated: false,
                            image_id: questionFormValues.image_id,
                            image_url: questionFormValues.image_url,
                        });
                        setQuestionFormValues({
                            question: "",
                            is_optional: true,
                        });
                    }
                }
                if (questionType === "singlechoice") {
                    let nulloptioncount = 0;
                    var errorlist = [];
                    var logicupdatedlist = [];
                    var manuallyupdatedlist = [];
                    var errorValuelist = [];
                    allOptions.forEach((element, index) => {
                        if (element !== "") {
                            nulloptioncount = nulloptioncount + 1;
                        }
                        errorlist.push(0);
                        errorValuelist.push("");
                        manuallyupdatedlist.push(false);
                        logicupdatedlist.push(false);
                    });
                    const list = [...allOptions];
                    const option_images_list = [...optionImages];
                    for (var i = 0; i < list.length; i++) {
                        if (list[i] == "" || list[i] == " ") {
                            list.splice(i, 1);
                            option_images_list.splice(i, 1);
                            i--;
                        }
                    }

                    if (nulloptioncount !== 0) {
                        if (nulloptioncount < 2) {
                            if (showToast) {
                                if (
                                    document.getElementById(
                                        "question-options" + (id_prefix ? id_prefix : "")
                                    )
                                ) {
                                    document
                                        .getElementById(
                                            "question-options" + (id_prefix ? id_prefix : "")
                                        )
                                        .scrollIntoView({
                                            behavior: "smooth",
                                            block: "center",
                                        });
                                }
                                showError(
                                    "Your answer is empty. Please enter at least two options."
                                );
                                checkIfErrorExits = true; // check if error exists while adding question
                            }
                        } else {
                            if (addQuestions) {
                                console.log(list);
                                let all_jump_to_loc = [...allJumpTo];
                                if (isNoneOfTheAbove) {
                                    list.push(
                                        language === "en"
                                            ? noneOfTheAboveEnText
                                            : noneOfTheAboveArText
                                    );
                                    // all_jump_to_loc.push("End Survey");
                                }
                                console.log(allJumpTo);

                                addQuestions({
                                    questionType: questionType,
                                    question: questionFormValues.question,
                                    is_optional: questionFormValues.is_optional,
                                    is_logic: questionFormValues.is_logic,
                                    options: list,
                                    jumpTo: all_jump_to_loc,
                                    jump_to_question: "",
                                    error: errorlist,
                                    errorValue: errorValuelist,
                                    manuallyupdated: manuallyupdatedlist,
                                    logicupdated: logicupdatedlist,
                                    image_id: questionFormValues.image_id,
                                    image_url: questionFormValues.image_url,
                                    optionImages: option_images_list,
                                });
                                setQuestionFormValues({
                                    question: "",
                                    is_optional: true,
                                });
                                setAllOptions([]);
                                setOtherOptionIncluded(false);
                            }
                        }
                    } else {
                        if (showToast) {
                            if (
                                document.getElementById(
                                    "question-options" + (id_prefix ? id_prefix : "")
                                )
                            ) {
                                document
                                    .getElementById(
                                        "question-options" + (id_prefix ? id_prefix : "")
                                    )
                                    .scrollIntoView({
                                        behavior: "smooth",
                                        block: "center",
                                    });
                            }
                            showError(
                                "Your answer is empty. Please enter at least two options."
                            );
                            checkIfErrorExits = true; // check if error exists while adding question
                        }
                    }
                }

                if (questionType === "multiplechoice") {
                    checkIfErrorExits = validateMCQuestion();
                }
                if (questionType === "rankingscale") {
                    let nulloptioncount = 0;
                    allOptions.forEach((element) => {
                        if (element !== "") {
                            nulloptioncount = nulloptioncount + 1;
                        }
                    });
                    const list = [...allOptions];
                    for (var i = 0; i < list.length; i++) {
                        if (list[i] == "" || list[i] == " ") {
                            list.splice(i, 1);
                            i--;
                        }
                    }
                    if (nulloptioncount !== 0) {
                        if (nulloptioncount < 2) {
                            if (showToast) {
                                if (
                                    document.getElementById(
                                        "question-options" + (id_prefix ? id_prefix : "")
                                    )
                                ) {
                                    document
                                        .getElementById(
                                            "question-options" + (id_prefix ? id_prefix : "")
                                        )
                                        .scrollIntoView({
                                            behavior: "smooth",
                                            block: "center",
                                        });
                                }
                                showError(
                                    "Your answer is empty. Please enter at least two options."
                                );
                                checkIfErrorExits = true; // check if error exists while adding question
                            }
                        } else {
                            if (addQuestions) {
                                addQuestions({
                                    questionType: questionType,
                                    question: questionFormValues.question,
                                    is_optional: questionFormValues.is_optional,
                                    is_logic:
                                        methodology === "Survey"
                                            ? questionFormValues.is_logic
                                            : false,
                                    jump_to_question: questionFormValues.jump_to,
                                    options: list,
                                    error: 0,
                                    errorvalue: "",
                                    manuallyupdated: false,
                                    logicupdated: false,
                                    image_id: questionFormValues.image_id,
                                    image_url: questionFormValues.image_url,
                                });
                                setQuestionFormValues({
                                    question: "",
                                    is_optional: true,
                                });
                                setAllOptions([]);
                                setOtherOptionIncluded(false);
                            }
                        }
                    } else {
                        if (showToast) {
                            if (
                                document.getElementById(
                                    "question-options" + (id_prefix ? id_prefix : "")
                                )
                            ) {
                                document
                                    .getElementById(
                                        "question-options" + (id_prefix ? id_prefix : "")
                                    )
                                    .scrollIntoView({
                                        behavior: "smooth",
                                        block: "center",
                                    });
                            }
                            showError(
                                "Your answer is empty. Please enter at least two options."
                            );
                            checkIfErrorExits = true; // check if error exists while adding question
                        }
                    }
                }

                if (questionType === "likertscale") {
                    let nulloptioncount = 0;
                    allOptions.forEach((element) => {
                        if (element == "") {
                            nulloptioncount = nulloptioncount + 1;
                        }
                    });

                    if (nulloptioncount < 1) {
                        let nullsubQuestioncount = 0;
                        likertSubQuestions.forEach((element) => {
                            if (element !== "") {
                                nullsubQuestioncount = nullsubQuestioncount + 1;
                            }
                        });

                        const list = [...likertSubQuestions];
                        for (var i = 0; i < list.length; i++) {
                            if (list[i] == "" || list[i] == " ") {
                                list.splice(i, 1);
                                i--;
                            }
                        }

                        // console.log(nullsubQuestioncount)
                        if (nullsubQuestioncount === 0) {
                            if (showToast) {
                                if (
                                    document.getElementById(
                                        "question-options" + (id_prefix ? id_prefix : "")
                                    )
                                ) {
                                    document
                                        .getElementById(
                                            "question-options" + (id_prefix ? id_prefix : "")
                                        )
                                        .scrollIntoView({
                                            behavior: "smooth",
                                            block: "center",
                                        });
                                }
                                showError(
                                    "Your answer is empty. Please enter at least one option."
                                );
                                checkIfErrorExits = true; // check if error exists while adding question
                            }
                        } else {
                            if (addQuestions) {
                                addQuestions({
                                    questionType: questionType,
                                    question: questionFormValues.question,
                                    is_optional: questionFormValues.is_optional,
                                    is_logic:
                                        methodology === "Survey"
                                            ? questionFormValues.is_logic
                                            : false,
                                    jump_to_question: questionFormValues.is_logic
                                        ? questionFormValues.jump_to
                                        : "",
                                    options: allOptions,
                                    subQuestions: list,
                                    scale_length: likertScaleLength,
                                    scale_type: likertScaleType,
                                    error: 0,
                                    errorvalue: "",
                                    manuallyupdated: false,
                                    logicupdated: false,
                                    image_id: questionFormValues.image_id,
                                    image_url: questionFormValues.image_url,
                                });
                                setQuestionFormValues({
                                    question: "",
                                    is_optional: true,
                                });
                                setAllOptions([]);
                            }
                        }
                    } else {
                        if (showToast) {
                            if (
                                document.getElementById(
                                    "question-options" + (id_prefix ? id_prefix : "")
                                )
                            ) {
                                document
                                    .getElementById(
                                        "question-options" + (id_prefix ? id_prefix : "")
                                    )
                                    .scrollIntoView({
                                        behavior: "smooth",
                                        block: "center",
                                    });
                            }
                            showError(
                                "Your answer is empty. Please enter at least one option."
                            );
                            checkIfErrorExits = true; // check if error exists while adding question
                        }
                    }
                }

                if (questionType === "ratingscale") {
                    if (addQuestions) {
                        addQuestions({
                            questionType: questionType,
                            question: questionFormValues.question,
                            is_optional: questionFormValues.is_optional,
                            is_logic:
                                methodology === "Survey" ? questionFormValues.is_logic : false,
                            jump_to_question: questionFormValues.is_logic
                                ? questionFormValues.jump_to
                                : "",
                            options: allOptions,
                            error: 0,
                            errorvalue: "",
                            manuallyupdated: false,
                            logicupdated: false,
                            labels: ratingScaleLabels,
                            scale_length: ratingScaleLength,
                            scale_type: ratingScaleType,
                            image_id: questionFormValues.image_id,
                            image_url: questionFormValues.image_url,
                        });
                        setQuestionFormValues({
                            question: "",
                            is_optional: true,
                        });
                        setAllOptions([]);
                        setOtherOptionIncluded(false);
                    }
                }
            }

            return checkIfErrorExits;
        };
        const validateMCQuestion = () => {
            var error = false;

            var formErrors = {
                min: null,
                max: null,
                min_max: null,
                exact: null,
                question: null,
            };

            var toastError = false;

            var element_id = "";

            if (questionFormValues.question === "") {
                formErrors = { ...formErrors, question: "Required field" };

                error = true;

                element_id = "question" + (id_prefix ? id_prefix : "");
            }
            let nulloptioncount = 0;

            allOptions.forEach((element) => {
                if (element !== "") {
                    nulloptioncount = nulloptioncount + 1;
                }
            });

            const list = [...allOptions];

            if (
                isNoneOfTheAbove &&
                !list.includes(
                    language === "en" ? noneOfTheAboveEnText : noneOfTheAboveArText
                )
            ) {
                list.push(
                    language === "en" ? noneOfTheAboveEnText : noneOfTheAboveArText
                );
            } else {
                if (
                    !isNoneOfTheAbove &&
                    list.includes(
                        language === "en" ? noneOfTheAboveEnText : noneOfTheAboveArText
                    )
                ) {
                    list.splice(list.length - 1, 1);
                }
            }

            const option_images_list = [...optionImages];

            for (var i = 0; i < list.length; i++) {
                if (list[i] == "" || list[i] == " ") {
                    list.splice(i, 1);
                    option_images_list.splice(i, 1);
                    i--;
                }
            }
            if (nulloptioncount !== 0) {
                if (nulloptioncount < 2) {
                    error = true;

                    if (!element_id) {
                        element_id = "question-options" + (id_prefix ? id_prefix : "");
                    }
                    showError("Your answer is empty. Please enter at least two options.");
                    toastError = true;
                }
            } else {
                error = true;

                if (!element_id) {
                    element_id = "question-options" + (id_prefix ? id_prefix : "");
                }
                showError("Your answer is empty. Please enter at least two options.");
            }

            /*if(nulloptioncount <=2 && minMaxRules){
                  error = true;
      
                  if(!element_id){
                      element_id = "options-rules-" +
                              (props.id_prefix ? props.id_prefix : "");
                  }
                  if(!toastError){
                      showError(
                          "You cannot set the rules for selecting options"
                      );
                  }   
                  toastError = true;
      
              } else*/

            if (nulloptioncount > 2) {
                if (minMaxRules) {
                    if (!minMaxRulesType) {
                        error = true;

                        if (!element_id) {
                            element_id = "options-rules-" + (id_prefix ? id_prefix : "");
                        }
                        if (!toastError) {
                            showError(
                                "Please set the rules for selecting options (maximum, minimum, or exact) before proceeding."
                            );
                            toastError = true;
                        }
                    } else if (minMaxRulesType == "exact") {
                        if (!questionFormValues.exact) {
                            error = true;

                            formErrors = {
                                ...formErrors,
                                exact:
                                    "Please select the exact number of options before proceeding.",
                            };

                            if (!element_id) {
                                element_id =
                                    "options-rules-exact-" + (id_prefix ? id_prefix : "");
                            }
                        }
                    } else if (minMaxRulesType == "min_max") {
                        if (!questionFormValues.min && !questionFormValues.max) {
                            error = true;

                            /*if(!toastError){
                                          showError(
                                              "Please set the rules for either min or max before proceeding."
                                          );
                                          toastError =true;  
                                      }*/
                            formErrors = {
                                ...formErrors,
                                min_max:
                                    "Please select the minimum/maximum number of options before proceeding.",
                            };

                            if (!element_id) {
                                element_id =
                                    "options-rules-min-max-" + (id_prefix ? id_prefix : "");
                            }
                        } /*else if(questionFormValues.min && !questionFormValues.max){
                        if(!(questionFormValues.min <= nulloptioncount)){
                            error = true;

                            formErrors = {...formErrors, min: "Invalid number"};

                            if(!element_id){
                                element_id = "options-rules-min-" +
                                        (props.id_prefix ? props.id_prefix : "");
                            }
                        }
                    }  else if(!questionFormValues.min && questionFormValues.max){
                        if(!(questionFormValues.max <= nulloptioncount-1)){
                            error = true;

                            formErrors = {...formErrors, max: "Invalid number"};

                            if(!element_id){
                                element_id = "options-rules-max-" +
                                        (props.id_prefix ? props.id_prefix : "");
                            }
                        }
                    }  else if(questionFormValues.min && questionFormValues.max){
                        if(!(
                            questionFormValues.min <= nulloptioncount && 
                            questionFormValues.max <= nulloptioncount-1
                        ) || !(questionFormValues.min < questionFormValues.max)){
                            error = true;

                            formErrors = {...formErrors, min: "Invalid number", max: "Invalid number"};

                            if(!element_id){
                                element_id = "options-rules-max-" +
                                        (props.id_prefix ? props.id_prefix : "");
                            }
                        }
                    }*/
                    }
                }
            }
            if (error && element_id) {
                if (document.getElementById(element_id)) {
                    document.getElementById(element_id).scrollIntoView({
                        behavior: "smooth",
                        block: "center",
                    });
                }
            }
            setQuestionFormErrors(formErrors);

            if (!error) {
                addQuestions({
                    questionType: questionType,
                    question: questionFormValues.question,
                    is_optional: questionFormValues.is_optional,
                    is_logic:
                        methodology === "Survey" ? questionFormValues.is_logic : false,
                    jump_to_question: questionFormValues.is_logic
                        ? questionFormValues.jump_to
                        : "",
                    options: list,
                    error: error,
                    errorvalue: "",
                    manuallyupdated: false,
                    logicupdated: false,
                    image_id: questionFormValues.image_id,
                    image_url: questionFormValues.image_url,
                    optionImages: option_images_list,
                    min: questionFormValues.min,
                    max: questionFormValues.max,
                    exact: questionFormValues.exact,
                });
                setQuestionFormValues({
                    question: "",
                    is_optional: true,
                });
                cancelQuestion();
                setAllOptions([]);
            }

            /*           
                  // error = true;
                  checkIfErrorExits = true; // check if error exists while adding question
        */

            return error;
        };

        function useOutsideAlerter(ref, questionFormValues) {
            useEffect(() => {
                /**
                 * Alert if clicked on outside of element
                 */

                function handleClickOutside(event) {
                    // console.log(event.target);
                    // console.log(event.target.nodeName);
                    // console.log(ref.current);
                    if (ref.current && !ref.current.contains(event.target)) {
                        //console.log(ref.current.contains(event.target));
                        if (event.target.nodeName != "svg") {
                            if (event.target.nodeName != "path") {
                                if (questionFormValues.question !== "") {
                                    if (!event.target.classList.contains("icon-medium")) {
                                        if (
                                            !event.target.classList.contains("toast-close-icon") &&
                                            !event.target.classList.contains("btn")
                                        ) {
                                            handleQuestionSubmit(false);
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
                // Bind the event listener
                document.addEventListener("mousedown", handleClickOutside);
                return () => {
                    // Unbind the event listener on clean up
                    document.removeEventListener("mousedown", handleClickOutside);
                };
            }, [
                ref,
                questionFormValues,
                allOptions,
                likertSubQuestions,
                ratingScaleLabels,
                ratingScaleLength,
                ratingScaleType,
            ]);
        }

        useOutsideAlerter(wrapperRef, questionFormValues);

        const handleDragStart = (event) => {
            const draggedDOM = getDraggedDom(event.draggableId);
            if (!draggedDOM) {
                return;
            }

            const { clientHeight, clientWidth } = draggedDOM;
            const sourceIndex = event.source.index;
            var clientY =
                parseFloat(window.getComputedStyle(draggedDOM.parentNode).paddingTop) +
                [...draggedDOM.parentNode.children]
                    .slice(0, sourceIndex)
                    .reduce((total, curr) => {
                        const style = curr.currentStyle || window.getComputedStyle(curr);
                        const marginBottom = parseFloat(style.marginBottom);
                        const marginTop = parseFloat(style.marginTop);
                        return total + curr.clientHeight + marginTop;
                    }, 0);
            setPlaceholderProps({
                clientHeight,
                clientWidth,
                clientY,
                clientX: parseFloat(
                    window.getComputedStyle(draggedDOM.parentNode).paddingLeft
                ),
                background: "#CCCCCC",
                border: "none",
            });
        };
        const handleDragUpdate = (event) => {
            if (!event.destination) {
                return;
            }

            const draggedDOM = getDraggedDom(event.draggableId);
            if (!draggedDOM) {
                return;
            }

            const { clientHeight, clientWidth } = draggedDOM;
            const destinationIndex = event.destination.index;
            const sourceIndex = event.source.index;
            const childrenArray = [...draggedDOM.parentNode.children];
            const movedItem = childrenArray[sourceIndex];
            childrenArray.splice(sourceIndex, 1);
            const updatedArray = [
                ...childrenArray.slice(0, destinationIndex),
                movedItem,
                ...childrenArray.slice(destinationIndex + 1),
            ];
            var clientY =
                parseFloat(window.getComputedStyle(draggedDOM.parentNode).paddingTop) +
                updatedArray.slice(0, destinationIndex).reduce((total, curr) => {
                    const style = curr.currentStyle || window.getComputedStyle(curr);
                    const marginBottom = parseFloat(style.marginBottom);
                    const marginTop = parseFloat(style.marginTop);
                    return total + curr.clientHeight + marginTop;
                }, 0);
            setPlaceholderProps({
                clientHeight,
                clientWidth,
                clientY,
                clientX: parseFloat(
                    window.getComputedStyle(draggedDOM.parentNode).paddingLeft
                ),
                background: "none",
                border: "1px dashed",
            });
        };
        const getDraggedDom = (draggableId) => {
            const domQuery = `[${queryAttr}='${draggableId}']`;
            const draggedDOM = document.querySelector(domQuery);
            return draggedDOM;
        };
        useEffect(() => {
            var list = [];
            for (var i = questionno + 1; i <= questionlist.length; i++) {
                list.push(i);
            }
            setJumpToListItems(list);
            var jumptolist = [];
            for (var i = 0; i < allOptions.length; i++) {
                jumptolist.push(
                    questionno < questionlist.length ? questionno + 1 : "End Survey"
                );
            }
            setAllJumpTo(jumptolist);

            // set dropdown option for free, multiple, likert, ranking
            var dropdownOptionsArray = [];
            dropdownOptionsArray.push({
                label: "End survey",
                value: "End Survey",
            });
            setDropdownOptions(dropdownOptionsArray);

            if (questionType == "ratingscale") {
                if (language == "en") {
                    setAllOptions([
                        "Strongly agree",
                        "Somewhat agree",
                        "Neutral",
                        "Somewhat disagree",
                        "Strongly disagree",
                    ]);
                } else {
                    setAllOptions([
                        "أوافق بشدة",
                        "أوافق إلى حد ما",
                        "محايد",
                        "اختلف إلى حد ما",
                        "اختلف بشده",
                    ]);
                }
            }
            if (questionType == "likertscale") {
                if (language == "en") {
                    setAllOptions([
                        "Very dissatisfied",
                        "Dissatisfied",
                        "Neither dissatisfied or satisfied",
                        "Satisfied",
                        "Very satisfied",
                        "Not applicable",
                    ]);
                } else {
                    setAllOptions([
                        "مستاء جدا",
                        "غير راض",
                        "لا غير راض أو راض",
                        "راضي",
                        "راضي جدا",
                        "لا ينطبق عليّ",
                    ]);
                }
            }
        }, []);

        const addOther = () => {
            setPreservedAction(false);

            setPreservedTooltips({ other: "", none_of_the_above: "" });

            if (!otherOptionIncluded) {
                if (allOptions.length < 200) {
                    if (language == "ar") {
                        allOptions.push("آخر");
                        allJumpTo.push(
                            questionno < questionlist.length ? questionno + 1 : "End Survey"
                        );
                    } else {
                        allOptions.push("Other");
                        allJumpTo.push(
                            questionno < questionlist.length ? questionno + 1 : "End Survey"
                        );
                    }
                    setOtherOptionIncluded(true);
                    setAllOptions([...allOptions]);
                    setAllJumpTo([...allJumpTo]);
                } else {
                    showError("Max two hundred options can be added.");
                }
            }
        };
        const addNoneOfTheAbove = () => {
            setPreservedAction(false);
            setPreservedTooltips({ other: "", none_of_the_above: "" });
            if (!isNoneOfTheAbove) {
                allJumpTo.push(
                    questionno < questionlist.length ? questionno + 1 : "End Survey"
                );
                setNoneOfTheAbove(true);
            }
        };
        return (
            <div ref={wrapperRef}>
                {questionType == "free" && (
                    <div
                        className={`survey-add-question-wrapper questioninputbox ${language == "ar" ? "arabic_wrapper" : ""
                            }`}
                    >
                        <div
                            className={`bd-question-top-slide d-flex justify-content-between survey-question-dropdoen-wrap ${language == "ar" ? "arabic_wrapper" : ""
                                }`}
                        >
                            <div className="bd-que-slide-left">
                                <div className="db-que-list-wrap survey-question-drop-down">
                                    <div className="dropdown">Question {questionno} -</div>
                                </div>
                                <div className="small-dropdown change-questiontype-dropdown">
                                    <Dropdown>
                                        <Dropdown.Toggle
                                            id="dropdownMenuButton"
                                            className="dropdown-toggle"
                                        >
                                            <div className="dropdown dropdown-question-type">
                                                Free text{" "}
                                            </div>
                                            <svg
                                                width="21"
                                                height="21"
                                                viewBox="0 0 18 18"
                                                fill="none"
                                                xmlns="http://www.w3.org/2000/svg"
                                            >
                                                <path
                                                    d="M15 6L9 12L3 6"
                                                    stroke="#9C9C9C"
                                                    stroke-width="0.75"
                                                />
                                            </svg>
                                        </Dropdown.Toggle>

                                        <Dropdown.Menu>
                                            <div className="drop-down-link-hold">
                                                {questionTypes.map(
                                                    (item, i) =>
                                                        item.value != questionType && (
                                                            <Dropdown.Item
                                                                key={item + "-" + i}
                                                                onClick={() => {
                                                                    if (changeQuestionType) {
                                                                        changeQuestionType(item.value);
                                                                    }

                                                                    if (item.value == "likertscale") {
                                                                        setTempAllOptions([...allOptions]);
                                                                        if (language == "en") {
                                                                            mapLikertOptions(likertScaleType);
                                                                        } else {
                                                                            setAllOptions([
                                                                                "أوافق بشدة",
                                                                                "أوافق إلى حد ما",
                                                                                "محايد",
                                                                                "اختلف إلى حد ما",
                                                                                "اختلف بشده",
                                                                                "لا ينطبق",
                                                                            ]);
                                                                        }
                                                                    }
                                                                    if (item.value == "ratingscale") {
                                                                        setTempAllOptions([...allOptions]);
                                                                        if (language == "en") {
                                                                            setAllOptions([
                                                                                "Strongly agree",
                                                                                "Somewhat agree",
                                                                                "Neutral",
                                                                                "Somewhat disagree",
                                                                                "Strongly disagree",
                                                                            ]);
                                                                        } else {
                                                                            setAllOptions([
                                                                                "أوافق بشدة",
                                                                                "أوافق إلى حد ما",
                                                                                "محايد",
                                                                                "اختلف إلى حد ما",
                                                                                "اختلف بشده",
                                                                            ]);
                                                                        }
                                                                    }
                                                                    if (item.value == "singlechoice") {
                                                                        setAllOptions([...tempAllOptions]);
                                                                        setQuestionFormValues({
                                                                            ...questionFormValues,
                                                                            is_logic: 0,
                                                                        });
                                                                        setLogicAdded(false);
                                                                    }
                                                                    if (
                                                                        item.value == "multiplechoice" ||
                                                                        item.value == "rankingscale"
                                                                    ) {
                                                                        setAllOptions([...tempAllOptions]);
                                                                    }
                                                                }}
                                                            >
                                                                {item.label}
                                                            </Dropdown.Item>
                                                        )
                                                )}
                                            </div>
                                        </Dropdown.Menu>
                                    </Dropdown>

                                    <div
                                        className={"cross-q m-cross-q mobile-view"}
                                        onClick={() => {
                                            if (cancelQuestion) {
                                                cancelQuestion();
                                                setQuestionFormValues({
                                                    question: "",
                                                    is_optional: true,
                                                });
                                                setAllOptions([]);
                                            }
                                        }}
                                    >
                                        <Icon value="delete" size="medium" hover={true} />
                                    </div>
                                </div>
                            </div>
                            <div className="bd-que-slide-arrow">
                                <div className="sqa-check-top">
                                    <div className="d-flex form-group custom-control custom-checkbox mb-0 dark-checkbox">
                                        <div className="optional-text-hold d-flex align-items-center">
                                            <span>Optional</span>
                                            <span className="info-icon info-wrap position-relative">
                                                <Icon value="information" size="medium" hover={true} />
                                                <div className="tooltip-wrapper top-tooltip">
                                                    <Text
                                                        type="body-text-2"
                                                        id="tooltip"
                                                        children={
                                                            "By enabling the optional toggle you’re allowing testers to skip this question"
                                                        }
                                                        fontWeight="normal-font"
                                                    />
                                                </div>
                                            </span>
                                        </div>

                                        <ToggleButton
                                            id="customCheck1"
                                            isChecked={Boolean(questionFormValues.is_optional)}
                                            onChange={(e) => {
                                                if (isOptionalNotAllowed) {
                                                    showError(
                                                        "At least one question has to be mandatory"
                                                    );
                                                } else {
                                                    setQuestionFormValues({
                                                        ...questionFormValues,
                                                        is_optional: e.target.checked ? 1 : 0,
                                                    });
                                                }
                                            }}
                                        />

                                        {methodology === "Survey" && islogic && (
                                            <div className="form-group custom-control mb-0 logicbuttonbox">
                                                <ToggleButton
                                                    id="logicCheck"
                                                    isChecked={Boolean(questionFormValues.is_logic)}
                                                    label={
                                                        <Text
                                                            display="inline"
                                                            fontWeight={"medium-font"}
                                                            type="body-text-2"
                                                        >
                                                            {" "}
                                                            Add logic{" "}
                                                        </Text>
                                                    }
                                                    onChange={(e) => {
                                                        setQuestionFormValues({
                                                            ...questionFormValues,
                                                            is_logic: e.target.checked ? 1 : 0,
                                                        });
                                                        setLogicAdded(logicAdded == true ? false : true);
                                                    }}
                                                />
                                            </div>
                                        )}
                                        <div
                                            className={"cross-q"}
                                            onClick={() => {
                                                if (cancelQuestion) {
                                                    cancelQuestion();
                                                    setQuestionFormValues({
                                                        question: "",
                                                        is_optional: true,
                                                    });
                                                    setAllOptions([]);
                                                }
                                            }}
                                        >
                                            <Icon value="delete" size="medium" hover={true} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div
                            className={`create-projectmodal-form-group d-flex ${!methodology ||
                                    methodology == "" ||
                                    methodology != "preference_test"
                                    ? "input-with-image"
                                    : ""
                                }  ${language === "ar" ? "arabic-add-logic-true" : ""} ${questionFormValues.is_logic ? "add-logic-true" : ""
                                }`}
                        >
                            {(!methodology ||
                                methodology == "" ||
                                methodology != "preference_test") && (
                                    <QuestionImage
                                        url={questionFormValues.image_url}
                                        id={questionFormValues.image_id}
                                        onImageUpload={(image_id, image_url) => {
                                            setQuestionFormValues({
                                                ...questionFormValues,
                                                image_url: image_url,
                                                image_id: image_id,
                                            });
                                        }}
                                    />
                                )}
                            <Input
                                label="Your question"
                                onChange={setQuestion("question")}
                                onClear={() => {
                                    if (onUpdate) {
                                        onUpdate({
                                            ...questionFormValues,
                                            question: "",
                                        });
                                    }
                                    setQuestionFormValues((oldValues) => ({
                                        ...oldValues,
                                        question: "",
                                    }));
                                }}
                                required={true}
                                value={questionFormValues.question}
                                maxLength={"350"}
                                error={questionFormErrors.question}
                                error_class={questionFormErrors.error_class}
                                id={"question" + (id_prefix ? id_prefix : "")}
                                rtl={language == "ar" ? true : false}
                                onKeyDown={(e) => {
                                    if (e.key === "Enter") {
                                        handleQuestionSubmit(true);
                                    }
                                }}
                            />

                            {methodology === "Survey" && questionFormValues.is_logic ? (
                                <DropdownComponent
                                    label="Jump to"
                                    options={dropdownOptions}
                                    value={
                                        questionFormValues.jump_to
                                            ? questionFormValues.jump_to
                                            : "End Survey"
                                    }
                                    onChange={(item) => {
                                        setQuestionFormValues((oldValues) => ({
                                            ...oldValues,
                                            jump_to: item.value,
                                        }));
                                    }}
                                />
                            ) : (
                                ""
                            )}
                        </div>
                        <div className="button-wrap d-flex justify-content-start">
                            <Button
                                label="Save"
                                onClick={() => handleQuestionSubmit(true)}
                                state={
                                    questionFormValues.question == "" ? "disabled" : "active"
                                }
                            />
                        </div>

                        <div className="create-test-preview-area">
                            <Text type={"overline"} children={"Preview"} />
                            <div className="create-test-preview-area-inner">
                                <img
                                    src={
                                        process.env.REACT_APP_URL + "img/free-question-preview.svg"
                                    }
                                    alt="img"
                                />
                            </div>
                            {methodology == "Survey" && <GuideLink methodology={"survey"} />}
                        </div>
                    </div>
                )}

                {(questionType == "singlechoice" ||
                    questionType == "multiplechoice" ||
                    questionType == "rankingscale") && (
                        <>
                            <div
                                className={`survey-add-question-wrapper questioninputbox ${language == "ar" ? "arabic_wrapper" : ""
                                    }`}
                            >
                                <div
                                    className={`bd-question-top-slide d-flex justify-content-between survey-question-dropdoen-wrap ${language == "ar" ? "arabic_wrapper" : ""
                                        }   ${questionType == "singlechoice" && islogic
                                            ? "addlogic-singlelogicbox-outer"
                                            : ""
                                        }`}
                                >
                                    <div className="bd-que-slide-left question-heading-left">
                                        <div className="db-que-list-wrap survey-question-drop-down">
                                            <div className="dropdown">Question {questionno} -</div>
                                        </div>
                                        <div className="small-dropdown change-questiontype-dropdown">
                                            <Dropdown>
                                                <Dropdown.Toggle
                                                    id="dropdownMenuButton1"
                                                    className="dropdown-toggle"
                                                >
                                                    {questionType == "singlechoice" && (
                                                        <div className="dropdown dropdown-question-type">
                                                            Single choice{" "}
                                                        </div>
                                                    )}
                                                    {questionType == "multiplechoice" && (
                                                        <div className="dropdown dropdown-question-type">
                                                            Multi choice{" "}
                                                        </div>
                                                    )}
                                                    {questionType == "rankingscale" && (
                                                        <div className="dropdown dropdown-question-type">
                                                            Ranking scale{" "}
                                                        </div>
                                                    )}
                                                    <svg
                                                        width="21"
                                                        height="21"
                                                        viewBox="0 0 18 18"
                                                        fill="none"
                                                        xmlns="http://www.w3.org/2000/svg"
                                                    >
                                                        <path
                                                            d="M15 6L9 12L3 6"
                                                            stroke="#9C9C9C"
                                                            stroke-width="0.75"
                                                        />
                                                    </svg>
                                                </Dropdown.Toggle>

                                                <Dropdown.Menu>
                                                    <div className="drop-down-link-hold">
                                                        {questionTypes.map(
                                                            (item, i) =>
                                                                item.value != questionType && (
                                                                    <Dropdown.Item
                                                                        key={item + "-" + i}
                                                                        onClick={() => {
                                                                            if (changeQuestionType) {
                                                                                changeQuestionType(item.value);
                                                                            }
                                                                            if (item.value == "likertscale") {
                                                                                setTempAllOptions([...allOptions]);
                                                                                if (language == "en") {
                                                                                    mapLikertOptions(likertScaleType);
                                                                                } else {
                                                                                    setAllOptions([
                                                                                        "أوافق بشدة",
                                                                                        "أوافق إلى حد ما",
                                                                                        "محايد",
                                                                                        "اختلف إلى حد ما",
                                                                                        "اختلف بشده",
                                                                                        "لا ينطبق",
                                                                                    ]);
                                                                                }
                                                                            }
                                                                            if (item.value == "ratingscale") {
                                                                                setTempAllOptions([...allOptions]);
                                                                                if (language == "en") {
                                                                                    setAllOptions([
                                                                                        "Strongly agree",
                                                                                        "Somewhat agree",
                                                                                        "Neutral",
                                                                                        "Somewhat disagree",
                                                                                        "Strongly disagree",
                                                                                    ]);
                                                                                } else {
                                                                                    setAllOptions([
                                                                                        "أوافق بشدة",
                                                                                        "أوافق إلى حد ما",
                                                                                        "محايد",
                                                                                        "اختلف إلى حد ما",
                                                                                        "اختلف بشده",
                                                                                    ]);
                                                                                }
                                                                            }
                                                                            if (item.value == "singlechoice") {
                                                                                if (
                                                                                    questionType == "likertscale" ||
                                                                                    questionType == "likertscale"
                                                                                ) {
                                                                                    setAllOptions([...tempAllOptions]);
                                                                                }
                                                                                setQuestionFormValues({
                                                                                    ...questionFormValues,
                                                                                    is_logic: 0,
                                                                                });
                                                                                setLogicAdded(false);
                                                                            }
                                                                            if (
                                                                                item.value == "multiplechoice" ||
                                                                                item.value == "rankingscale"
                                                                            ) {
                                                                                setLogicAdded(false);
                                                                                setQuestionFormValues({
                                                                                    ...questionFormValues,
                                                                                    is_logic: 0,
                                                                                });
                                                                            }
                                                                            if (item.value == "free") {
                                                                                setTempAllOptions([...allOptions]);
                                                                            }

                                                                            //console.log(item.value)
                                                                            if (item.value == "rankingscale") {
                                                                                //console.log('asdas')
                                                                                setNoneOfTheAbove(false);

                                                                                //console.log(allOptions)
                                                                                var checkIndexOther = "";
                                                                                if (language === "ar") {
                                                                                    checkIndexOther = allOptions.findIndex(
                                                                                        (x) => x === "آخر"
                                                                                    );
                                                                                    if (checkIndexOther !== -1) {
                                                                                        //allOptions.splice(checkIndexOther, 1, 'آخر');
                                                                                        //setAllOptions([...allOptions]);
                                                                                        closeOtherOptionHandler();
                                                                                    }
                                                                                } else {
                                                                                    checkIndexOther = allOptions.findIndex(
                                                                                        (x) => x === "Other"
                                                                                    );
                                                                                    if (checkIndexOther !== -1) {
                                                                                        //allOptions.splice(checkIndexOther, 1);

                                                                                        //console.log(allOptions);

                                                                                        closeOtherOptionHandler();

                                                                                        // setAllOptions([...newAllOptions]);
                                                                                    }
                                                                                }

                                                                                //console.log(checkIndexOther)
                                                                            }
                                                                        }}
                                                                    >
                                                                        {item.label}
                                                                    </Dropdown.Item>
                                                                )
                                                        )}
                                                    </div>
                                                </Dropdown.Menu>
                                            </Dropdown>

                                            <div
                                                className={"cross-q m-cross-q mobile-view"}
                                                onClick={() => {
                                                    if (cancelQuestion) {
                                                        cancelQuestion();
                                                        setQuestionFormValues({
                                                            question: "",
                                                            is_optional: true,
                                                        });
                                                        setAllOptions([]);
                                                    }
                                                }}
                                            >
                                                <Icon value="delete" size="medium" hover={true} />
                                            </div>
                                        </div>
                                    </div>

                                    <div
                                        className={`bd-que-slide-arrow add-logic-survey-option ${questionType == "singlechoice" && islogic
                                                ? "addlogic-singlelogicbox"
                                                : ""
                                            }`}
                                    >
                                        <div className="sqa-check-top">
                                            <div className="sqa-check-top singlechoice-addlogic">
                                                <div className="form-group custom-control d-flex">
                                                    <div className="optional-text-hold d-flex align-items-center">
                                                        <span>Optional</span>
                                                        <span className="info-icon info-wrap position-relative">
                                                            <Icon
                                                                value="information"
                                                                size="medium"
                                                                hover={true}
                                                            />
                                                            <div className="tooltip-wrapper top-tooltip">
                                                                <Text
                                                                    type="body-text-2"
                                                                    id="tooltip"
                                                                    children={
                                                                        "By enabling the optional toggle you’re allowing testers to skip this question"
                                                                    }
                                                                    fontWeight="normal-font"
                                                                />
                                                            </div>
                                                        </span>
                                                    </div>

                                                    <ToggleButton
                                                        id="customCheck1"
                                                        isChecked={Boolean(questionFormValues.is_optional)}
                                                        onChange={(e) => {
                                                            if (isOptionalNotAllowed) {
                                                                showError(
                                                                    "At least one question has to be mandatory"
                                                                );
                                                            } else {
                                                                setQuestionFormValues({
                                                                    ...questionFormValues,
                                                                    is_optional: e.target.checked ? 1 : 0,
                                                                });
                                                            }
                                                        }}
                                                    />

                                                    {(methodology === "Survey" ||
                                                        questionType === "singlechoice") &&
                                                        islogic && (
                                                            <div className="form-group custom-control mb-0 logicbuttonbox">
                                                                <ToggleButton
                                                                    id="logicCheck"
                                                                    isChecked={Boolean(questionFormValues.is_logic)}
                                                                    label={
                                                                        <Text
                                                                            display="inline"
                                                                            fontWeight={"medium-font"}
                                                                            type="body-text-2"
                                                                        >
                                                                            {" "}
                                                                            Add logic{" "}
                                                                        </Text>
                                                                    }
                                                                    onChange={(e) => {
                                                                        setQuestionFormValues({
                                                                            ...questionFormValues,
                                                                            is_logic: e.target.checked ? 1 : 0,
                                                                        });
                                                                        setLogicAdded(
                                                                            logicAdded == true ? false : true
                                                                        );
                                                                    }}
                                                                />
                                                            </div>
                                                        )}
                                                    <div
                                                        className={"cross-q"}
                                                        onClick={() => {
                                                            if (cancelQuestion) {
                                                                cancelQuestion();
                                                                setQuestionFormValues({
                                                                    question: "",
                                                                    is_optional: true,
                                                                });
                                                                setAllOptions([]);
                                                            }
                                                        }}
                                                    >
                                                        <Icon value="delete" size="medium" hover={true} />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div
                                    className={`create-projectmodal-form-group d-flex ${!methodology ||
                                            methodology == "" ||
                                            methodology != "preference_test"
                                            ? "input-with-image"
                                            : ""
                                        } ${language === "ar" ? "arabic-add-logic-true" : ""} ${methodology === "Survey" &&
                                            questionType !== "singlechoice" &&
                                            questionFormValues.is_logic
                                            ? "add-logic-true"
                                            : ""
                                        }`}
                                >
                                    {(!methodology ||
                                        methodology == "" ||
                                        methodology != "preference_test") && (
                                            <QuestionImage
                                                url={questionFormValues.image_url}
                                                id={questionFormValues.image_id}
                                                onImageUpload={(image_id, image_url) => {
                                                    setQuestionFormValues({
                                                        ...questionFormValues,
                                                        image_url: image_url,
                                                        image_id: image_id,
                                                    });
                                                }}
                                            />
                                        )}
                                    <Input
                                        label="Your question"
                                        onChange={setQuestion("question")}
                                        onClear={() => {
                                            if (onUpdate) {
                                                onUpdate({
                                                    ...questionFormValues,
                                                    question: "",
                                                });
                                            }
                                            setQuestionFormValues((oldValues) => ({
                                                ...oldValues,
                                                question: "",
                                            }));
                                        }}
                                        required={true}
                                        value={questionFormValues.question}
                                        maxLength={"350"}
                                        error={questionFormErrors.question}
                                        error_class={questionFormErrors.error_class}
                                        id={"question" + (id_prefix ? id_prefix : "")}
                                        rtl={language == "ar" ? true : false}
                                        onKeyDown={(e) => {
                                            if (e.key === "Enter") {
                                                handleQuestionSubmit(true);
                                            }
                                        }}
                                    />

                                    {methodology === "Survey" &&
                                        questionType !== "singlechoice" &&
                                        questionFormValues.is_logic ? (
                                        <DropdownComponent
                                            label="Jump to"
                                            options={dropdownOptions}
                                            value={
                                                questionFormValues.jump_to
                                                    ? questionFormValues.jump_to
                                                    : "End Survey"
                                            }
                                            onChange={(item) => {
                                                setQuestionFormValues((oldValues) => ({
                                                    ...oldValues,
                                                    jump_to: item.value,
                                                }));
                                            }}
                                        />
                                    ) : (
                                        ""
                                    )}
                                </div>
                                <div
                                    id={"question-options" + (id_prefix ? id_prefix : "")}
                                    className={`ans-list-wrap ${questionFormValues.is_logic == 1 ? "survey-logic-added" : ""
                                        } ${language == "ar" ? "arabic_wrapper" : ""}`}
                                >
                                    <p className="small-info-heading">
                                        {questionType == "rankingscale"
                                            ? "Ranking options"
                                            : "Answers"}
                                    </p>
                                    <Options
                                        optionImages={optionImages}
                                        allOptions={allOptions}
                                        edit={(index) => editOptionHandler(index)}
                                        close={(index) => closeOptionHandler(index)}
                                        closeOtherOption={(index) => closeOtherOptionHandler()}
                                        save={(index, value) => {
                                            saveOptionHandler(index, value);
                                        }}
                                        saveJumpTo={(index, value) =>
                                            saveOptionJumpToHandler(index, value)
                                        }
                                        saveOptionImage={(index, image_id, image_url) => {
                                            optionImages.splice(index, 1, {
                                                image_id: image_id,
                                                image_url: image_url,
                                            });
                                            setOptionImages([...optionImages]);
                                        }}
                                        language={language}
                                        questionType={questionType}
                                        addOption={(index) => {
                                            if (allOptions.length < 200) {
                                                allOptions.splice(index + 1, 0, "");
                                                setAllOptions([...allOptions]);
                                                allJumpTo.splice(
                                                    index + 1,
                                                    0,
                                                    questionno < questionlist.length
                                                        ? questionno + 1
                                                        : "End Survey"
                                                );
                                                setAllJumpTo([...allJumpTo]);

                                                optionImages.splice(index + 1, 0, {
                                                    image_id: null,
                                                    image_url: null,
                                                });
                                                setOptionImages([...optionImages]);
                                            } else {
                                                showError("Max two hundred options can be added");
                                            }
                                        }}
                                        logicAdded={logicAdded}
                                        jumpToList={jumpToListItems}
                                        allJumpTo={allJumpTo}
                                        questionno={questionno}
                                        noneOfTheAboveOption={isNoneOfTheAbove}
                                        closeNoneOfTheAboveOption={() => {
                                            setNoneOfTheAbove(false);
                                        }}
                                        methodology={methodology}
                                        saveNoneOftheAbove={addNoneOfTheAbove}
                                        addOther={addOther}
                                        updatePreservedAction={(action, tooltip = null) => {
                                            if (action) {
                                                setPreservedAction(true);
                                            } else {
                                                setPreservedAction(false);
                                            }
                                            if (tooltip == "other") {
                                                setPreservedTooltips({
                                                    other: "Adding other option is available here",
                                                    none_of_the_above: "",
                                                });
                                            } else if (tooltip == "none_of_the_above") {
                                                setPreservedTooltips({
                                                    other: "",
                                                    none_of_the_above:
                                                        "Adding none of the above option is available here",
                                                });
                                            } else {
                                                setPreservedTooltips({
                                                    other: "",
                                                    none_of_the_above: "",
                                                });
                                            }
                                        }}
                                    />
                                </div>

                                <div className=" add-survey-answer-btn">
                                    <Button
                                        iconLeft={<Icon value="add" />}
                                        label={
                                            questionType != "rankingscale"
                                                ? "Add answer"
                                                : "Add options"
                                        }
                                        type="ghost"
                                        onClick={() => {
                                            if (allOptions.length < 200) {
                                                if (otherOptionIncluded == true) {
                                                    allOptions.splice(-1, 0, "");
                                                    setAllOptions([...allOptions]);
                                                    optionImages.splice(-1, 0, {
                                                        image_id: null,
                                                        image_url: null,
                                                    });
                                                    setOptionImages([...optionImages]);
                                                    allJumpTo.splice(
                                                        -1,
                                                        0,
                                                        questionno < questionlist.length
                                                            ? questionno + 1
                                                            : "End Survey"
                                                    );
                                                    setAllJumpTo([...allJumpTo]);
                                                } else {
                                                    allOptions.push("");
                                                    setAllOptions([...allOptions]);
                                                    optionImages.push({
                                                        image_id: null,
                                                        image_url: null,
                                                    });
                                                    setOptionImages([...optionImages]);

                                                    allJumpTo.push(
                                                        questionno < questionlist.length
                                                            ? questionno + 1
                                                            : "End Survey"
                                                    );
                                                    setAllJumpTo([...allJumpTo]);
                                                }
                                                console.log(allOptions);
                                            } else {
                                                showError("Max two hundred options can be added");
                                            }
                                        }}
                                    />

                                    {questionType != "rankingscale" && (
                                        <div className="add-other-survey add-othrnone-survey">
                                            <span className="add-other-survey-left">
                                                <span className="body-text body-text-3 medium-font">
                                                    Do you want to add "Other" option or "None of the
                                                    above"?
                                                </span>
                                            </span>

                                            <div className="add-othrnone-survey-btn position-relative">
                                                <Button
                                                    size={"small"}
                                                    type={"secondary"}
                                                    state={otherOptionIncluded ? "disabled" : ""}
                                                    iconLeft={<Icon value={"add"} />}
                                                    label={`Add other`}
                                                    onClick={addOther}
                                                    tooltip={preservedTooltips["other"]}
                                                />

                                                <Button
                                                    size={"small"}
                                                    type={"secondary"}
                                                    state={isNoneOfTheAbove ? "disabled" : ""}
                                                    iconLeft={<Icon value={"add"} />}
                                                    label={`Add none of the above`}
                                                    cssclass={"none-of-the-above-text"}
                                                    onClick={addNoneOfTheAbove}
                                                    tooltip={preservedTooltips["none_of_the_above"]}
                                                />
                                            </div>
                                        </div>
                                    )}
                                    {questionType == "multiplechoice" && (
                                        <div className="w-100" onClick={(e) => {
                                            if (minMaxRulesState) {
                                                showError("Please add at least 3 options to enable this feature.")
                                            }
                                        }}>
                                            <div
                                                className={"d-flex mb-20 font-14"}
                                                id={"options-rules-" + (id_prefix ? id_prefix : "")}
                                            >
                                                <div className="toggle-btn-relative">
                                                    <ToggleButton
                                                        isChecked={minMaxRules ? true : false}
                                                        name={"optionsrules_toggle"}
                                                        value={"1"}
                                                        onChange={() => {
                                                            setMinMaxRules(!minMaxRules);
                                                            if (!minMaxRules) {
                                                                setMinMaxRulesType("min_max");
                                                                setQuestionFormValues({
                                                                    ...questionFormValues,
                                                                    min: null,
                                                                    max: null,
                                                                    exact: null,
                                                                });
                                                            } else {
                                                                setMinMaxRulesType("");

                                                                setQuestionFormValues({
                                                                    ...questionFormValues,
                                                                    min: null,
                                                                    max: null,
                                                                    exact: null,
                                                                });
                                                            }
                                                        }}
                                                        isDisabled={minMaxRulesState}
                                                        id={minMaxRulesState ? "" : "optionsrules_toggle"}
                                                    />
                                                    <label
                                                        for="optionsrules_toggle"
                                                        className="mb-0 cursor-pointer"
                                                    >
                                                        <Text
                                                            type="body-text-3"
                                                            fontWeight="medium-font"
                                                            cssClasses={"ml-12"}
                                                        >
                                                            Set limits for the number of options the tester can select

                                                        </Text>
                                                    </label>
                                                </div>
                                            </div>
                                            {minMaxRules && (
                                                <div
                                                    className={`min-max-option-container`}
                                                    id={
                                                        "options-rules-min-max-" +
                                                        (id_prefix ? id_prefix : "")
                                                    }
                                                >
                                                    <div className="radio-btn-relative">
                                                        <RadioButton
                                                            name={
                                                                "optionsrules_set" + (id_prefix ? id_prefix : "")
                                                            }
                                                            value={"min_max"}
                                                            cssClasses={"font-14 line-22"}
                                                            onChange={() => {
                                                                setMinMaxRulesType("min_max");
                                                                setQuestionFormValues({
                                                                    ...questionFormValues,
                                                                    exact: null,
                                                                });
                                                            }}
                                                            isChecked={
                                                                minMaxRulesType == "min_max" ? true : false
                                                            }
                                                            id={
                                                                "optionrules-minmax-" +
                                                                (id_prefix ? id_prefix : "")
                                                            }
                                                        />
                                                        <div>
                                                            <label
                                                                className="mb-0 cursor-pointer"
                                                                htmlFor={
                                                                    "optionrules-minmax-" +
                                                                    (id_prefix ? id_prefix : "")
                                                                }
                                                            >
                                                                <Text
                                                                    type="body-text-3"
                                                                    fontWeight="medium-font"
                                                                    cssClasses={"ml-12"}
                                                                >
                                                                    Select the minimum/maximum number of options
                                                                </Text>
                                                            </label>
                                                            <br />
                                                            <label
                                                                className="mb-0 cursor-pointer"
                                                                htmlFor={
                                                                    "optionrules-minmax-" +
                                                                    (id_prefix ? id_prefix : "")
                                                                }
                                                            >
                                                                <Text
                                                                    type="body-text-3"
                                                                    fontWeight="medium-font"
                                                                    cssClasses={"ml-12 gray-text"}
                                                                >
                                                                    You can define the minimum, maximum, or a
                                                                    combination of both to limit the number of
                                                                    options the user can select.
                                                                </Text>
                                                            </label>
                                                        </div>
                                                    </div>
                                                    {minMaxRulesType == "min_max" && (
                                                        <div
                                                            className={`row mt-16 container options-container`}
                                                            onClick={(e) => {
                                                                e.stopPropagation();
                                                            }}
                                                            onMouseDown={(e) => {
                                                                e.stopPropagation();
                                                            }}
                                                        >
                                                            <div
                                                                className="column-dropdown d-flex align-items-center"
                                                                id={
                                                                    "options-rules-min-" +
                                                                    (id_prefix ? id_prefix : "")
                                                                }
                                                            >
                                                                <Text
                                                                    type={"body-text-3"}
                                                                    fontWeight={"medium-font"}
                                                                    children={"Minimum number of options"}
                                                                />

                                                                <div className="multiselect-cap-dropdowns">
                                                                    <DropdownComponent
                                                                        label={questionFormValues.min ? "" : "Select"}
                                                                        options={(() => {
                                                                            let array = [];
                                                                            for (
                                                                                var i = minOptionsSet[0];
                                                                                i <= minOptionsSet[1];
                                                                                i++
                                                                            ) {
                                                                                array.push({ label: i, value: i });
                                                                            }

                                                                            return array;
                                                                        })()}
                                                                        value={questionFormValues.min}
                                                                        onChange={(option) => {
                                                                            if (option) {
                                                                                setQuestionFormValues({
                                                                                    ...questionFormValues,
                                                                                    min: option.value,
                                                                                });
                                                                            } else {
                                                                                setQuestionFormValues({
                                                                                    ...questionFormValues,
                                                                                    min: null,
                                                                                });
                                                                            }
                                                                            setQuestionFormErrors({
                                                                                ...questionFormErrors,
                                                                                min_max: null,
                                                                            });
                                                                        }}
                                                                        isClearable={true}
                                                                        readOnly={
                                                                            (() => {
                                                                                let array = [];
                                                                                for (
                                                                                    var i = minOptionsSet[0];
                                                                                    i <= minOptionsSet[1];
                                                                                    i++
                                                                                ) {
                                                                                    array.push({ label: i, value: i });
                                                                                }

                                                                                return array.length == 0 ? true : false;
                                                                            })()
                                                                                ? true
                                                                                : false
                                                                        }
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div
                                                                className="column-dropdown d-flex align-items-center ml-64"
                                                                id={
                                                                    "options-rules-max-" +
                                                                    (id_prefix ? id_prefix : "")
                                                                }
                                                            >
                                                                <Text
                                                                    type={"body-text-3"}
                                                                    fontWeight={"medium-font"}
                                                                    children={" Maximum number of options"}
                                                                />
                                                                <div className="multiselect-cap-dropdowns">
                                                                    <DropdownComponent
                                                                        label={questionFormValues.max ? "" : "Select"}
                                                                        options={(() => {
                                                                            let array = [];
                                                                            for (
                                                                                var i = maxOptionsSet[0];
                                                                                i <= maxOptionsSet[1];
                                                                                i++
                                                                            ) {
                                                                                array.push({ label: i, value: i });
                                                                            }

                                                                            return array;
                                                                        })()}
                                                                        value={questionFormValues.max}
                                                                        onChange={(option) => {
                                                                            if (option) {
                                                                                setQuestionFormValues({
                                                                                    ...questionFormValues,
                                                                                    max: option.value,
                                                                                });
                                                                            } else {
                                                                                setQuestionFormValues({
                                                                                    ...questionFormValues,
                                                                                    max: null,
                                                                                });
                                                                            }
                                                                            setQuestionFormErrors({
                                                                                ...questionFormErrors,
                                                                                min_max: null,
                                                                            });
                                                                        }}
                                                                        readOnly={
                                                                            (() => {
                                                                                let array = [];
                                                                                for (
                                                                                    var i = maxOptionsSet[0];
                                                                                    i <= maxOptionsSet[1];
                                                                                    i++
                                                                                ) {
                                                                                    array.push({ label: i, value: i });
                                                                                }

                                                                                return array.length == 0 ? true : false;
                                                                            })()
                                                                                ? true
                                                                                : false
                                                                        }
                                                                        isClearable={true}
                                                                    />
                                                                </div>
                                                            </div>
                                                            {questionFormErrors.min_max && (
                                                                <Text
                                                                    type={"body-text-3"}
                                                                    fontWeight={"medium-font"}
                                                                    cssClasses={"font-14 red-color-txt mt-16"}
                                                                >
                                                                    {" "}
                                                                    {questionFormErrors.min_max}
                                                                </Text>
                                                            )}
                                                        </div>
                                                    )}
                                                    <div className="radio-btn-relative mt-16">
                                                        <RadioButton
                                                            name={
                                                                "optionsrules_set" + (id_prefix ? id_prefix : "")
                                                            }
                                                            value={"exact"}
                                                            onChange={() => {
                                                                setMinMaxRulesType("exact");
                                                                setQuestionFormValues({
                                                                    ...questionFormValues,
                                                                    min: null,
                                                                    max: null,
                                                                });
                                                                setQuestionFormErrors({
                                                                    ...questionFormErrors,
                                                                    min_max: null,
                                                                    exact: null,
                                                                });
                                                            }}
                                                            isChecked={
                                                                minMaxRulesType == "exact" ? true : false
                                                            }
                                                            cssClasses={"font-14 line-22"}
                                                            id={
                                                                "optionrules-exact-" +
                                                                (id_prefix ? id_prefix : "")
                                                            }
                                                        />{" "}
                                                        <div>
                                                            <label
                                                                className="mb-0 cursor-pointer"
                                                                htmlFor={
                                                                    "optionrules-exact-" +
                                                                    (id_prefix ? id_prefix : "")
                                                                }
                                                            >
                                                                <Text
                                                                    type="body-text-3"
                                                                    fontWeight="medium-font"
                                                                    cssClasses={"ml-12"}
                                                                >
                                                                    Select the exact number of options
                                                                </Text>
                                                            </label>
                                                            <br />
                                                            <label
                                                                className="mb-0 cursor-pointer"
                                                                for="optionrules-exact"
                                                            >
                                                                <Text
                                                                    type="body-text-3"
                                                                    fontWeight="medium-font"
                                                                    cssClasses={"ml-12 gray-text"}
                                                                >
                                                                    You can require testers to select a specific
                                                                    number of options to proceed.
                                                                </Text>
                                                            </label>
                                                        </div>
                                                    </div>

                                                    {minMaxRulesType == "exact" && (
                                                        <div
                                                            className={`row mt-16 options-container container`}
                                                        >
                                                            <div className="column-dropdown d-flex align-items-center">
                                                                <Text
                                                                    type={"body-text-3"}
                                                                    fontWeight={"medium-font"}
                                                                    children={"Exact number options"}
                                                                />

                                                                <div
                                                                    className="multiselect-cap-dropdowns"
                                                                    id={"options-rules-exact-" + id_prefix}
                                                                >
                                                                    <DropdownComponent
                                                                        label={
                                                                            questionFormValues.exact ? "" : "Select"
                                                                        }
                                                                        options={(() => {
                                                                            let array = [];
                                                                            for (
                                                                                var i = exactOptionsSet[0];
                                                                                i <= exactOptionsSet[1];
                                                                                i++
                                                                            ) {
                                                                                array.push({ label: i, value: i });
                                                                            }

                                                                            return array;
                                                                        })()}
                                                                        value={questionFormValues.exact}
                                                                        onChange={(option) => {
                                                                            if (option) {
                                                                                setQuestionFormValues({
                                                                                    ...questionFormValues,
                                                                                    exact: option.value,
                                                                                });
                                                                            } else {
                                                                                setQuestionFormValues({
                                                                                    ...questionFormValues,
                                                                                    exact: null,
                                                                                });
                                                                            }
                                                                            setQuestionFormErrors({
                                                                                ...questionFormErrors,
                                                                                exact: null,
                                                                            });
                                                                        }}
                                                                        isClearable={true}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )}
                                                    {questionFormErrors.exact && (
                                                        <Text
                                                            type={"body-text-3"}
                                                            fontWeight={"medium-font"}
                                                            cssClasses={"font-14 red-color-txt mt-16"}
                                                        >
                                                            {" "}
                                                            {questionFormErrors.exact}
                                                        </Text>
                                                    )}
                                                </div>
                                            )}
                                        </div>
                                    )}
                                </div>
                                <div className="button-wrap d-flex justify-content-start mt-32">
                                    <Button
                                        label="Save"
                                        onClick={handleQuestionSubmit}
                                        state={checkBtnstatus() ? "disabled" : "active"}
                                    />
                                </div>
                                <div className="create-test-preview-area">
                                    <Text type={"overline"} children={"Preview"} />
                                    <div className="create-test-preview-area-inner">
                                        {questionType == "rankingscale" ? (
                                            <img
                                                src={
                                                    process.env.REACT_APP_URL +
                                                    "img/ranking-question-preview.svg"
                                                }
                                                alt="img"
                                            />
                                        ) : (
                                            <></>
                                        )}

                                        {questionType == "multiplechoice" ? (
                                            <>
                                                {" "}
                                                <img
                                                    src={
                                                        process.env.REACT_APP_URL +
                                                        "img/multiple-question-preview.svg"
                                                    }
                                                    alt="img"
                                                />
                                            </>
                                        ) : (
                                            <></>
                                        )}

                                        {questionType == "singlechoice" ? (
                                            <>
                                                <img
                                                    src={
                                                        process.env.REACT_APP_URL +
                                                        "img/single-question-preview.svg"
                                                    }
                                                    alt="img"
                                                />
                                            </>
                                        ) : (
                                            <></>
                                        )}
                                    </div>
                                    {methodology == "Survey" && (
                                        <GuideLink methodology={"survey"} />
                                    )}
                                </div>
                            </div>
                        </>
                    )}

                {/* Akshay:Added Code to Add Ratingscale */}
                {questionType == "ratingscale" && (
                    <div
                        className={`survey-question-holder questioninputbox ${language == "ar" ? "arabic_wrapper" : ""
                            }`}
                    >
                        <div
                            className={`bd-question-top-slide d-flex justify-content-between survey-question-dropdoen-wrap ${language == "ar" ? "arabic_wrapper" : ""
                                }`}
                        >
                            <div className="bd-que-slide-left">
                                <div className="db-que-list-wrap survey-question-drop-down">
                                    <div className="dropdown">Question {questionno} -</div>
                                </div>
                                <div className="small-dropdown change-questiontype-dropdown">
                                    <Dropdown>
                                        <Dropdown.Toggle
                                            id="dropdownMenuButton"
                                            className="dropdown-toggle"
                                        >
                                            <div className="dropdown dropdown-question-type">
                                                Rating scale{" "}
                                            </div>
                                            <svg
                                                width="21"
                                                height="21"
                                                viewBox="0 0 18 18"
                                                fill="none"
                                                xmlns="http://www.w3.org/2000/svg"
                                            >
                                                <path
                                                    d="M15 6L9 12L3 6"
                                                    stroke="#9C9C9C"
                                                    stroke-width="0.75"
                                                />
                                            </svg>
                                        </Dropdown.Toggle>

                                        <Dropdown.Menu>
                                            <div className="drop-down-link-hold">
                                                {questionTypes.map(
                                                    (item, i) =>
                                                        item.value != questionType && (
                                                            <Dropdown.Item
                                                                key={item + "-" + i}
                                                                onClick={() => {
                                                                    if (changeQuestionType) {
                                                                        changeQuestionType(item.value);
                                                                    }
                                                                    if (item.value == "likertscale") {
                                                                        if (language == "en") {
                                                                            mapLikertOptions(likertScaleType);
                                                                        } else {
                                                                            setAllOptions([
                                                                                "أوافق بشدة",
                                                                                "أوافق إلى حد ما",
                                                                                "محايد",
                                                                                "اختلف إلى حد ما",
                                                                                "اختلف بشده",
                                                                                "لا ينطبق",
                                                                            ]);
                                                                        }
                                                                    }
                                                                    if (item.value == "singlechoice") {
                                                                        setAllOptions([...tempAllOptions]);
                                                                        setQuestionFormValues({
                                                                            ...questionFormValues,
                                                                            is_logic: 0,
                                                                        });
                                                                        setLogicAdded(false);
                                                                    }
                                                                    if (
                                                                        item.value == "multiplechoice" ||
                                                                        item.value == "ratingscale" ||
                                                                        item.value == "rankingscale"
                                                                    ) {
                                                                        setAllOptions([...tempAllOptions]);
                                                                    }
                                                                }}
                                                            >
                                                                {item.label}
                                                            </Dropdown.Item>
                                                        )
                                                )}
                                            </div>
                                        </Dropdown.Menu>
                                    </Dropdown>

                                    <div
                                        className={"cross-q m-cross-q mobile-view"}
                                        onClick={() => {
                                            if (cancelQuestion) {
                                                cancelQuestion();
                                                setQuestionFormValues({
                                                    question: "",
                                                    is_optional: true,
                                                });
                                                setAllOptions([]);
                                            }
                                        }}
                                    >
                                        <Icon value="delete" size="medium" hover={true} />
                                    </div>
                                </div>
                            </div>
                            <div className="bd-que-slide-arrow add-logic-survey">
                                <div className="sqa-check-top">
                                    <div className="form-group custom-control custom-checkbox mb-0 dark-checkbox">
                                        <div className="optional-text-hold d-flex align-items-center">
                                            <span>Optional</span>
                                            <span className="info-icon info-wrap position-relative">
                                                <Icon value="information" size="medium" hover={true} />
                                                <div className="tooltip-wrapper top-tooltip">
                                                    <Text
                                                        type="body-text-2"
                                                        id="tooltip"
                                                        children={
                                                            "By enabling the optional toggle you’re allowing testers to skip this question"
                                                        }
                                                        fontWeight="normal-font"
                                                    />
                                                </div>
                                            </span>
                                        </div>

                                        <ToggleButton
                                            id="customCheck1"
                                            isChecked={Boolean(questionFormValues.is_optional)}
                                            onChange={(e) => {
                                                if (isOptionalNotAllowed) {
                                                    showError(
                                                        "At least one question has to be mandatory"
                                                    );
                                                } else {
                                                    setQuestionFormValues({
                                                        ...questionFormValues,
                                                        is_optional: e.target.checked ? 1 : 0,
                                                    });
                                                }
                                            }}
                                        />

                                        {methodology === "Survey" && islogic && (
                                            <div className="form-group custom-control mb-0 logicbuttonbox">
                                                <ToggleButton
                                                    id="logicCheck"
                                                    isChecked={Boolean(questionFormValues.is_logic)}
                                                    label={
                                                        <Text
                                                            display="inline"
                                                            fontWeight={"medium-font"}
                                                            type="body-text-2"
                                                        >
                                                            {" "}
                                                            Add logic{" "}
                                                        </Text>
                                                    }
                                                    onChange={(e) => {
                                                        setQuestionFormValues({
                                                            ...questionFormValues,
                                                            is_logic: e.target.checked ? 1 : 0,
                                                        });
                                                        setLogicAdded(logicAdded == true ? false : true);
                                                    }}
                                                />
                                            </div>
                                        )}

                                        <div
                                            className={"cross-q"}
                                            onClick={() => {
                                                if (cancelQuestion) {
                                                    cancelQuestion();
                                                    setQuestionFormValues({
                                                        question: "",
                                                        is_optional: true,
                                                    });
                                                    setAllOptions([]);
                                                }
                                            }}
                                        >
                                            <Icon value="delete" size="medium" hover={true} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div
                            className={`create-projectmodal-form-group d-flex ${!methodology ||
                                    methodology == "" ||
                                    methodology != "preference_test"
                                    ? "input-with-image"
                                    : ""
                                } ${language === "ar" ? "arabic-add-logic-true" : ""} ${questionFormValues.is_logic ? "add-logic-true" : ""
                                }`}
                        >
                            {(!methodology ||
                                methodology == "" ||
                                methodology != "preference_test") && (
                                    <QuestionImage
                                        url={questionFormValues.image_url}
                                        id={questionFormValues.image_id}
                                        onImageUpload={(image_id, image_url) => {
                                            setQuestionFormValues({
                                                ...questionFormValues,
                                                image_url: image_url,
                                                image_id: image_id,
                                            });
                                        }}
                                    />
                                )}
                            <Input
                                label="Your question"
                                onChange={setQuestion("question")}
                                onClear={() => {
                                    if (onUpdate) {
                                        onUpdate({
                                            ...questionFormValues,
                                            question: "",
                                        });
                                    }
                                    setQuestionFormValues((oldValues) => ({
                                        ...oldValues,
                                        question: "",
                                    }));
                                }}
                                required={true}
                                value={questionFormValues.question}
                                maxLength={"350"}
                                error={questionFormErrors.question}
                                error_class={questionFormErrors.error_class}
                                id={"question" + (id_prefix ? id_prefix : "")}
                                rtl={language == "ar" ? true : false}
                                onKeyDown={(e) => {
                                    if (e.key === "Enter") {
                                        handleQuestionSubmit(true);
                                    }
                                }}
                            />
                            {methodology === "Survey" && questionFormValues.is_logic ? (
                                <DropdownComponent
                                    label="Jump to"
                                    options={dropdownOptions}
                                    value={
                                        questionFormValues.jump_to
                                            ? questionFormValues.jump_to
                                            : "End Survey"
                                    }
                                    onChange={(item) => {
                                        setQuestionFormValues((oldValues) => ({
                                            ...oldValues,
                                            jump_to: item.value,
                                        }));
                                    }}
                                />
                            ) : (
                                ""
                            )}
                        </div>
                        <div className="points-outer rating-scale-dropdown-wrap">
                            <div className="scalebuttonbox rating-scale-option-wrap">
                                <span>Scale length</span>
                                <FilterButtons
                                    options={
                                        ratingScaleType == "Stars"
                                            ? ["3 points", "5 points"]
                                            : ["3 points", "5 points", "7 points", "10 points"]
                                    }
                                    selected={ratingScaleLength}
                                    onChange={(value) => {
                                        setRatingScaleLength(value);
                                    }}
                                />
                            </div>
                            <DropdownComponent
                                label="Scale type"
                                options={ratingScaleOptions}
                                value={ratingScaleType}
                                onChange={(item) => {
                                    setRatingScaleType(item.value);
                                    if (item.value == "Stars") {
                                        if (
                                            ratingScaleLength == "7 points" ||
                                            ratingScaleLength == "10 points"
                                        ) {
                                            setRatingScaleLength("3 points");
                                        }
                                    }
                                }}
                            />
                        </div>
                        <div className="rating-scale-labels-container d-flex">
                            {ratingScaleLabels.map((label, index) => (
                                <div className="rating-scale-label">
                                    <InputPlaceholder
                                        required={true}
                                        clearField={false}
                                        value={label}
                                        label={
                                            index == 0
                                                ? "Left label"
                                                : index == 1
                                                    ? "Middle label"
                                                    : "Right label"
                                        }
                                        placeholder={
                                            index == 0
                                                ? "Left label"
                                                : index == 1
                                                    ? "Middle label"
                                                    : "Right label"
                                        }
                                        onChange={(e) => {
                                            ratingScaleLabels.splice(index, 1, e.target.value);
                                            setRatingScaleLabels([...ratingScaleLabels]);
                                        }}
                                        onKeyDown={(e) => { }}
                                        autoFocus={false}
                                        rtl={language == "ar" ? true : false}
                                        maxLength={50}
                                    />
                                </div>
                            ))}
                        </div>

                        <div className="button-wrap d-flex justify-content-start">
                            <Button
                                label="Save"
                                onClick={() => handleQuestionSubmit(true)}
                                state={
                                    questionFormValues.question == "" ? "disabled" : "active"
                                }
                            />
                        </div>
                        <div className="create-test-preview-area rating-scale-preview">
                            <Text type={"overline"} children={"Preview"} />
                            <div className="create-test-preview-area-inner">
                                <img
                                    src={
                                        process.env.REACT_APP_URL +
                                        "img/rating-question-preview.svg"
                                    }
                                    alt="img"
                                />
                            </div>
                            {methodology == "Survey" && <GuideLink methodology={"survey"} />}
                        </div>
                    </div>
                )}

                {questionType == "likertscale" && (
                    <div
                        className={`survey-question-holder questioninputbox ${language == "ar" ? "arabic_wrapper" : ""
                            }`}
                    >
                        <div
                            className={`bd-question-top-slide d-flex justify-content-between survey-question-dropdoen-wrap ${language == "ar" ? "arabic_wrapper" : ""
                                }`}
                        >
                            <div className="bd-que-slide-left">
                                <Text type="body-text-2" fontWeight="medium">
                                    Question {questionno} -
                                </Text>
                                <div className="small-dropdown change-questiontype-dropdown">
                                    <Dropdown>
                                        <Dropdown.Toggle
                                            id="dropdownMenuButton"
                                            className="dropdown-toggle"
                                        >
                                            <div className="dropdown dropdown-question-type">
                                                Likert scale{" "}
                                            </div>
                                            <svg
                                                width="21"
                                                height="21"
                                                viewBox="0 0 18 18"
                                                fill="none"
                                                xmlns="http://www.w3.org/2000/svg"
                                            >
                                                <path
                                                    d="M15 6L9 12L3 6"
                                                    stroke="#9C9C9C"
                                                    stroke-width="0.75"
                                                />
                                            </svg>
                                        </Dropdown.Toggle>

                                        <Dropdown.Menu>
                                            <div className="drop-down-link-hold">
                                                {questionTypes.map(
                                                    (item, i) =>
                                                        item.value != questionType && (
                                                            <Dropdown.Item
                                                                key={item + "-" + i}
                                                                onClick={() => {
                                                                    if (changeQuestionType) {
                                                                        changeQuestionType(item.value);
                                                                    }

                                                                    if (item.value == "singlechoice") {
                                                                        setAllOptions([...tempAllOptions]);
                                                                    }
                                                                    if (
                                                                        item.value == "multiplechoice" ||
                                                                        item.value == "free"
                                                                    ) {
                                                                        setAllOptions([...tempAllOptions]);
                                                                    }
                                                                    if (item.value == "rankingscale") {
                                                                        setAllOptions([...tempAllOptions]);
                                                                    }
                                                                }}
                                                            >
                                                                {item.label}
                                                            </Dropdown.Item>
                                                        )
                                                )}
                                            </div>
                                        </Dropdown.Menu>
                                    </Dropdown>

                                    <div
                                        className={"cross-q m-cross-q mobile-view"}
                                        onClick={() => {
                                            if (cancelQuestion) {
                                                cancelQuestion();
                                                setQuestionFormValues({
                                                    question: "",
                                                    is_optional: true,
                                                });
                                                setAllOptions([]);
                                            }
                                        }}
                                    >
                                        <Icon value="delete" size="medium" hover={true} />
                                    </div>
                                </div>
                            </div>
                            <div className="bd-que-slide-arrow add-logic-survey-option ">
                                <div className="sqa-check-top">
                                    <div className="form-group custom-control d-flex">
                                        <div className="optional-text-hold d-flex align-items-center">
                                            <Text type="body-text-3" fontWeight="medium">
                                                Optional
                                            </Text>
                                            <span className="info-icon info-wrap position-relative">
                                                <Icon value="information" size="medium" hover={true} />
                                                <div className="tooltip-wrapper top-tooltip">
                                                    <Text
                                                        type="body-text-2"
                                                        id="tooltip"
                                                        children={
                                                            "By enabling the optional toggle you’re allowing testers to skip this question"
                                                        }
                                                        fontWeight="normal-font"
                                                    />
                                                </div>
                                            </span>
                                        </div>
                                        <ToggleButton
                                            isChecked={Boolean(questionFormValues.is_optional)}
                                            onChange={(e) =>
                                                setQuestionFormValues({
                                                    ...questionFormValues,
                                                    is_optional: e.target.checked ? 1 : 0,
                                                })
                                            }
                                        />

                                        {methodology === "Survey" && islogic && (
                                            <div className="form-group custom-control mb-0 logicbuttonbox">
                                                <ToggleButton
                                                    id="logicCheck"
                                                    isChecked={Boolean(questionFormValues.is_logic)}
                                                    label={
                                                        <Text
                                                            display="inline"
                                                            fontWeight={"medium-font"}
                                                            type="body-text-2"
                                                        >
                                                            {" "}
                                                            Add logic{" "}
                                                        </Text>
                                                    }
                                                    onChange={(e) => {
                                                        setQuestionFormValues({
                                                            ...questionFormValues,
                                                            is_logic: e.target.checked ? 1 : 0,
                                                        });
                                                        setLogicAdded(logicAdded == true ? false : true);
                                                    }}
                                                />
                                            </div>
                                        )}
                                        <div
                                            className={"cross-q"}
                                            onClick={() => {
                                                if (cancelQuestion) {
                                                    cancelQuestion();
                                                    setQuestionFormValues({
                                                        question: "",
                                                        is_optional: true,
                                                    });
                                                    setAllOptions([]);
                                                }
                                            }}
                                        >
                                            <Icon value="delete" size="medium" hover={true} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div
                            className={`inputpoints-outer d-flex ${!methodology ||
                                    methodology == "" ||
                                    methodology != "preference_test"
                                    ? "input-with-image"
                                    : ""
                                } ${language === "ar" ? "arabic-add-logic-true" : ""} ${methodology === "Survey" && questionFormValues.is_logic
                                    ? "add-logic-true"
                                    : ""
                                }`}
                        >
                            {(!methodology ||
                                methodology == "" ||
                                methodology != "preference_test") && (
                                    <QuestionImage
                                        url={questionFormValues.image_url}
                                        id={questionFormValues.image_id}
                                        onImageUpload={(image_id, image_url) => {
                                            setQuestionFormValues({
                                                ...questionFormValues,
                                                image_url: image_url,
                                                image_id: image_id,
                                            });
                                        }}
                                    />
                                )}
                            <InputPlaceholder
                                required={true}
                                label="Your question"
                                placeholder="Add an overall statement. Eg. ‘‘Please rate the following statements on your customer support experience’’"
                                onChange={setQuestion("question")}
                                onClear={() => {
                                    if (onUpdate) {
                                        onUpdate({
                                            ...questionFormValues,
                                            question: "",
                                        });
                                    }
                                    setQuestionFormValues((oldValues) => ({
                                        ...oldValues,
                                        question: "",
                                    }));
                                }}
                                value={questionFormValues.question}
                                maxLength="350"
                                error={questionFormErrors.question}
                                error_class={questionFormErrors.error_class}
                                id={"question" + (id_prefix ? id_prefix : "")}
                                rtl={language == "ar" ? true : false}
                                onKeyDown={(e) => {
                                    if (e.key === "Enter") {
                                        handleQuestionSubmit(true);
                                    }
                                }}
                            />

                            {methodology === "Survey" && questionFormValues.is_logic ? (
                                <DropdownComponent
                                    label="Jump to"
                                    options={dropdownOptions}
                                    value={
                                        questionFormValues.jump_to
                                            ? questionFormValues.jump_to
                                            : "End Survey"
                                    }
                                    onChange={(item) => {
                                        setQuestionFormValues((oldValues) => ({
                                            ...oldValues,
                                            jump_to: item.value,
                                        }));
                                    }}
                                />
                            ) : (
                                ""
                            )}
                        </div>
                        <div className="points-outer likert-scale-length-wrap">
                            <div className="scalebuttonbox">
                                <span>Scale length</span>
                                <FilterButtons
                                    options={["5 points", "7 points"]}
                                    selected={likertScaleLength}
                                    onChange={(value) => {
                                        setLikertScaleLength(value);
                                        if (value == "5 points") {
                                            setLikertScaleType(
                                                language === "en" ? "Satisfaction" : "مقياس الرضا"
                                            );
                                            if (language == "en") {
                                                setAllOptions([
                                                    "Very dissatisfied",
                                                    "Dissatisfied",
                                                    "Neither dissatisfied or satisfied",
                                                    "Satisfied",
                                                    "Very satisfied",
                                                    "Not applicable",
                                                ]);
                                            } else {
                                                setAllOptions([
                                                    "مستاء جدا",
                                                    "غير راض",
                                                    "لا غير راض أو راض",
                                                    "راضي",
                                                    "راضي جدا",
                                                    "لا ينطبق عليّ",
                                                ]);
                                            }
                                            setLikertStateMent(
                                                "“How satisfied are you with the speed of response to your enquiry?”"
                                            );
                                        }
                                        if (value == "7 points") {
                                            setLikertScaleType(
                                                language === "en" ? "Agreement" : "مقياس الاتفاق"
                                            );
                                            if (language == "en") {
                                                setAllOptions([
                                                    "Strongly disagree",
                                                    "Disagree",
                                                    "Somewhat disagree",
                                                    "Neither agree or disagree",
                                                    "Somewhat agree",
                                                    "Agree",
                                                    "Strongly agree",
                                                    "Not applicable",
                                                ]);
                                            } else {
                                                setAllOptions([
                                                    "أعارض بشدة",
                                                    "أعارض",
                                                    "لا أوافق إلى حد ما",
                                                    "لا أوافق ولا أعارض",
                                                    "أوافق إلى حد ما",
                                                    "أوافق",
                                                    "أوافق بشدة",
                                                    "لا ينطبق عليّ",
                                                ]);
                                            }
                                            setLikertStateMent(
                                                "“The customer service representatives were helpful and knowledgeable”"
                                            );
                                        }
                                    }}
                                />
                            </div>
                            <DropdownComponent
                                label={language === "en" ? "Scale type" : "نوع المقياس"}
                                options={
                                    likertScaleLength == "5 points"
                                        ? likert5ScaleOptions
                                        : likert7ScaleOptions
                                }
                                value={likertScaleType}
                                onChange={(item) => {
                                    setLikertScaleType(item.value);
                                    mapLikertOptions(item.value);
                                }}
                            />
                        </div>
                        <div className="tagbox">
                            {allOptions.map((option, index) => (
                                <label htmlFor="radio4" key={option + "-" + index}>
                                    {option == "Not applicable" || option == "لا ينطبق عليّ" ? (
                                        <Tag
                                            title={option}
                                            type="purple-tag"
                                            onClose={() => {
                                                const list = [...allOptions];
                                                list.pop();
                                                setAllOptions(list);
                                            }}
                                        />
                                    ) : (
                                        <Tag title={option} type="purple-tag" />
                                    )}
                                </label>
                            ))}
                            {language == "en" && !allOptions.includes("Not applicable") && (
                                <Tag
                                    title={"Not applicable"}
                                    type="purple-tag"
                                    cssClasses="not-applicable-notselected"
                                    onAdd={() => {
                                        const list = [...allOptions];
                                        list.push("Not applicable");
                                        setAllOptions(list);
                                    }}
                                />
                            )}
                            {language == "ar" && !allOptions.includes("لا ينطبق عليّ") && (
                                <Tag
                                    title={"لا ينطبق عليّ"}
                                    type="purple-tag"
                                    cssClasses="not-applicable-notselected"
                                    onAdd={() => {
                                        const list = [...allOptions];
                                        list.push("لا ينطبق عليّ");
                                        setAllOptions(list);
                                    }}
                                />
                            )}
                        </div>
                        <div
                            className={`  ${language == "ar"
                                    ? "arabic_wrapper statementbox"
                                    : "statementbox"
                                }`}
                        >
                            <Text type="body-text-2" fontWeight="medium">
                                Your statements
                            </Text>
                            <div
                                id={"question-options" + (id_prefix ? id_prefix : "")}
                                className=""
                            >
                                <div style={{ position: "relative" }}>
                                    <DragDropContext
                                        onDragEnd={(param) => {
                                            const srcI = param.source.index;
                                            const desI = param.destination.index;
                                            if (likertSubQuestions) {
                                                likertSubQuestions.splice(
                                                    desI,
                                                    0,
                                                    likertSubQuestions.splice(srcI, 1)[0]
                                                );
                                            }
                                            setPlaceholderProps({});
                                        }}
                                        onDragStart={handleDragStart}
                                        onDragUpdate={handleDragUpdate}
                                    >
                                        <Droppable droppableId="droppable-1">
                                            {(provided, snapshot) => (
                                                <div
                                                    ref={provided.innerRef}
                                                    {...provided.droppableProps}
                                                >
                                                    {likertSubQuestions.map((subQuestion, index) => (
                                                        <Draggable
                                                            key={index}
                                                            draggableId={"optiondraggable-" + index}
                                                            index={index}
                                                        >
                                                            {(provided, snapshot) => (
                                                                <div
                                                                    className={`ans-list-repeat saved-ans-repeat ${snapshot.isDragging
                                                                            ? "question-answers-dragging draggable-element-start"
                                                                            : ""
                                                                        } `}
                                                                    ref={provided.innerRef}
                                                                    {...provided.draggableProps}
                                                                    {...provided.dragHandleProps}
                                                                >
                                                                    <div
                                                                        className={`full draginput-main ${language == "ar" ? "arabic_wrapper" : ""
                                                                            }`}
                                                                        key={index}
                                                                    >
                                                                        <div className="full draginput-outer">
                                                                            <div className="draginput-inner">
                                                                                <div
                                                                                    className="draginputbox"
                                                                                    {...provided.dragHandleProps}
                                                                                >
                                                                                    <Icon
                                                                                        value="move-object"
                                                                                        colorClass={"gray-900-svg"}
                                                                                        size="medium"
                                                                                        hover={true}
                                                                                    />
                                                                                </div>
                                                                                <div
                                                                                    className={`draginput-main ${snapshot.isDragging
                                                                                            ? "draggable-inner-element-start"
                                                                                            : ""
                                                                                        }`}
                                                                                >
                                                                                    <InputPlaceholder
                                                                                        required={true}
                                                                                        clearField={false}
                                                                                        value={subQuestion}
                                                                                        label={
                                                                                            index == 0
                                                                                                ? "First statement"
                                                                                                : index == 1
                                                                                                    ? "Second statement"
                                                                                                    : index == 2
                                                                                                        ? "Third statement"
                                                                                                        : index == 3
                                                                                                            ? "Fourth statement"
                                                                                                            : index == 4
                                                                                                                ? "Fifth statement"
                                                                                                                : index == 5
                                                                                                                    ? "Sixth statement"
                                                                                                                    : index == 6
                                                                                                                        ? "Seventh statement"
                                                                                                                        : index == 7
                                                                                                                            ? "Eighth statement"
                                                                                                                            : index == 8
                                                                                                                                ? "Ninth statement"
                                                                                                                                : index == 9
                                                                                                                                    ? "Tenth statement"
                                                                                                                                    : "Another statement"
                                                                                        }
                                                                                        placeholder={
                                                                                            index == 0
                                                                                                ? "Add your first statement to rate. Eg. " +
                                                                                                likertStatement
                                                                                                : index == 1
                                                                                                    ? "Second statement"
                                                                                                    : index == 2
                                                                                                        ? "Third statement"
                                                                                                        : index == 3
                                                                                                            ? "Fourth statement"
                                                                                                            : index == 4
                                                                                                                ? "Fifth statement"
                                                                                                                : index == 5
                                                                                                                    ? "Sixth statement"
                                                                                                                    : index == 6
                                                                                                                        ? "Seventh statement"
                                                                                                                        : index == 7
                                                                                                                            ? "Eighth statement"
                                                                                                                            : index == 8
                                                                                                                                ? "Ninth statement"
                                                                                                                                : index == 9
                                                                                                                                    ? "Tenth statement"
                                                                                                                                    : "Another statement"
                                                                                        }
                                                                                        onChange={(e) => {
                                                                                            likertSubQuestions.splice(
                                                                                                index,
                                                                                                1,
                                                                                                e.target.value
                                                                                            );
                                                                                            setLikertSubQuestions([
                                                                                                ...likertSubQuestions,
                                                                                            ]);
                                                                                        }}
                                                                                        onKeyDown={(e) => {
                                                                                            if (e.key === "Enter") {
                                                                                                likertSubQuestions.splice(
                                                                                                    index + 1,
                                                                                                    0,
                                                                                                    ""
                                                                                                );
                                                                                                setLikertSubQuestions([
                                                                                                    ...likertSubQuestions,
                                                                                                ]);
                                                                                            }
                                                                                        }}
                                                                                        autoFocus={
                                                                                            likertSubQuestions.length == 1
                                                                                                ? false
                                                                                                : true
                                                                                        }
                                                                                        rtl={
                                                                                            language == "ar" ? true : false
                                                                                        }
                                                                                        maxLength={"250"}
                                                                                    />
                                                                                </div>
                                                                            </div>

                                                                            <div className="draginput-crossicon-outer">
                                                                                {likertSubQuestions.length > 1 && (
                                                                                    <div className="draginput-crossicon-inner">
                                                                                        <span
                                                                                            onClick={() =>
                                                                                                removeSubQuestionHandler(index)
                                                                                            }
                                                                                        >
                                                                                            <Icon
                                                                                                value="close"
                                                                                                size="small"
                                                                                                hover={true}
                                                                                            />
                                                                                        </span>
                                                                                    </div>
                                                                                )}
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            )}
                                                        </Draggable>
                                                    ))}
                                                    {provided.placeholder}
                                                    {!isEmpty(placeholderProps) && (
                                                        <div
                                                            className="placeholder"
                                                            style={{
                                                                top: placeholderProps.clientY,
                                                                left: placeholderProps.clientX,
                                                                height: placeholderProps.clientHeight,
                                                                width: placeholderProps.clientWidth,
                                                                position: "absolute",
                                                                borderColor: "#000000",
                                                                background: placeholderProps.background,
                                                                borderRadius: "10px",
                                                                border: placeholderProps.border,
                                                                margin: "-4px 0px 10px",
                                                            }}
                                                        />
                                                    )}
                                                </div>
                                            )}
                                        </Droppable>
                                    </DragDropContext>
                                </div>
                            </div>
                        </div>
                        <Button
                            iconLeft={<Icon value="add" />}
                            label="Add statement"
                            type="ghost"
                            onClick={addSubQuestionHandler}
                        />

                        <div className="">
                            <Button
                                label="Save"
                                onClick={() => handleQuestionSubmit(true)}
                                type="primary"
                                state={checkBtnstatus() ? "disabled" : "active"}
                                size="small"
                            />
                        </div>

                        <div className="create-test-preview-area">
                            <Text type={"overline"} children={"Preview"} />
                            <div className="create-test-preview-area-inner">
                                <img
                                    src={
                                        process.env.REACT_APP_URL +
                                        "img/likert-question-preview.svg"
                                    }
                                    alt="img"
                                />
                            </div>
                            {methodology == "Survey" && <GuideLink methodology={"survey"} />}
                        </div>
                    </div>
                )}
            </div>
        );
    }
);

QuestionInput.propTypes = {
    // question: PropTypes.string,
    // questionType: PropTypes.string,
};

QuestionInput.defaultProps = {
    // question: "",
    // questionType:"freetext",
};
