import { Box } from "../../../themes/userq/Box/Box";
import { Text } from "../../../themes/userq/Text/Text";
import { Tooltip } from "../../../themes/userq/Tooltip/Tooltip";
import BarChart from "./bar_chart_v2";
import { useRef,useState } from "react";
import { Icon } from "../../../themes/userq/Icon/Icon";
import exportAsImage from "../../../../lib/exportAsImage";
import { DropdownComponent } from "../../../themes/userq/Dropdown/Dropdown";

export const  HorizontalBarChartStatsBoxMultipleChoice = ({title, data, test, marginLeft, marginRight,resultCount}) =>{

	const ref = useRef();
	const tipref = useRef();

	const exportPngRef = useRef();


	const [tooltip, showTooltip] = useState({
		tooltipLeft:0,
		tooltipTop:0,
		tooltipData:"",
		open: false,
		show:false
	});

	const [sortDir, setSortDir] = useState("Default");

	let chart_main = [];

	const handlePointerMove = (key, tooltipdata) => {


		return (
			(event) => {


				let tipPosition = "bottom";

				var tool_width = tipref && tipref.current ?  tipref.current.clientWidth : 150;


				var tool_height =  tipref && tipref.current ?  tipref.current.clientHeight + 12 : 106;




				let containerX = ('clientX' in event ? event.clientX : 0) - ref.current.getBoundingClientRect().left - tool_width/2;
				let containerY = ('clientY' in event ? event.clientY : 0)  - ref.current.getBoundingClientRect().top - tool_height - 10 ;


				var window_width = window.innerWidth;

				var window_height = window.innerHeight;

				if(event.clientX + tool_width/2 > window_width){
					containerX = ('clientX' in event ? event.clientX : 0) - ref.current.getBoundingClientRect().left - tool_width/2 - (event.clientX + tool_width/2 - window_width) ;

				}
				if(event.clientX - tool_width/2 < 0){
					containerX = ('clientX' in event ? event.clientX : 0) - ref.current.getBoundingClientRect().left - tool_width/2 + (tool_width/2 - event.clientX) ;
				}

				showTooltip({
					tooltipLeft: containerX,
					tooltipTop: containerY,
					tooltipData: tooltipdata,
					open:true,
					show: tooltip.open && tipref && tipref.current ? true: false,
					tipPostion: tipPosition
				});
			});
	}

	var colors = [
		{ color: "#FF6DB1", text: "" },
		{ color: "#9759CB", text: "" },
		{ color: "#F2C690", text: "" },
		{ color: "#698CE7", text: "" },
		{ color: "#31F0E5", text: "" },
		{ color: "#378B10", text: "" },
		{ color: "#E32F83", text: "" },
		{ color: "#793EAB", text: "" },
		{ color: "#D7A56A", text: "" },
		{ color: "#3668E7", text: "" },
		{ color: "#11BFB4", text: "" },
		{ color: "#164103", text: "" },
		{ color: "#FF8AC1", text: "" },
		{ color: "#AC7AD5", text: "" },
		{ color: "#FFE2C0", text: "" },
		{ color: "#9EB4ED", text: "" },
		{ color: "#90F2EC", text: "" },
		{ color: "#BFF0A8", text: "" },
		{ color: "#A72561", text: "" },
		{ color: "#5C208D", text: "" },
		{ color: "#C38D4D", text: "" },
		{ color: "#0D3BB0", text: "" },
		{ color: "#0A877F", text: "" },
		{ color: "#A72561", text: "" },
		{ color: "#5C208D", text: "" },
		{ color: "#C38D4D", text: "" },
		{ color: "#06236C", text: "" },
		{ color: "#045A54", text: "" },
	];

	function sortByValue(jsObj) {
		var sortedArray = [];
		for (var i in jsObj) {
			// Push each JSON Object entry in array by [value, key]
			sortedArray.push([jsObj[i], i]);
		}
		// return sortedArray.sort(function(a, b){return a - b});
		return sortedArray.sort(function(a, b){return a[0] - b[0]}).reverse();
	}
	const renderHorizontalBarChart = () => {

		var lChartData = [];

		var index = 0;

		var max = 0;

		

		if(resultCount){

			max = resultCount;
		}else{

			for (var key in data) {

				if (data[key].value > max) {
					max = data[key].value;
				}
			}
		}

		var sum = 0;

		if(resultCount){

			sum = resultCount;
		}else {
			for (var key in data) {

				sum += data[key].value;

			}
		}

		var bars = [];

		//console.log(sum);
		//console.log(data);
		for (var key in data) {
			var percentage = max > 0 ? Math.round(((data[key].value / sum) * 100) * 100) / 100 : 0;
			var percentage_label = sum > 0 ? Math.round(((data[key].value / sum) * 100) * 100) / 100 : 0;

			var color = colors[28];
			if (index < 28) {

				color = colors[index];
			}

			lChartData.push({
				label: data[key].label,
				value: data[key].value,
				color: color.color,
				//textcolor: color.text,
				percentage: percentage,
				percentage_label: percentage + "%"
			});

			index++;

			/*
			bars.push((
				<div className="horiz-chart-data-repeat" key={"bar"+key}>

					<div className={`horizonatal-chart-left-side`}>
						<div className="horiz-chart-top-info d-flex align-items-end">

							<h3  style={{ color: color.color }} className={`h3 heading ${data[key].value != 0 ? color.text : ''}`}>{percentage_label + "%"}</h3>

							<Text type={'caption'} fontWeight={'medium-font'} cssClasses={'color-black ml-2'}>{data[key].value === 1 ? data[key].value + " Participant" : data[key].value + ' Participants'}</Text>

						</div>

						<div className="horiz-chart-hold-bg">
							<div class={`horiz-chart-wrap ${percentage <= 30 ? 'small-width-chart-area' : ''} ${percentage >= 1 ? 'small-width-chart-area-1' : ''}`}
								 style={{ width: percentage + "%", backgroundColor: color.color  }}

							>
								<div
									className={`edu-graph-count ${percentage == 0 ? 'p-0' : ''}`}
									style={{ backgroundColor: color.color }}
								>

								</div>


							</div>
						</div>

					</div>

					<div className="horizonatal-chart-right-side">
						<div className="horiz-chart-text-wrap d-flex align-items-center">
							<Text type={'body-text-2'} fontWeight={'medium-font'} cssClasses={`color-black ${test.language == "ar" ? "arabic-font" : ""}`}>{data[key].label}</Text>
						</div>

					</div>

				</div>
			))*/
		}
		if(sortDir=="Desc"){
            lChartData.sort(function(a, b) {
                var keyA = a.value,
                keyB = b.value;
                if (keyA > keyB) return -1;
                if (keyA < keyB) return 1;
                return 0;
            });
        } else if (sortDir == "Asc"){
            
            lChartData.sort(function(a, b) {
                var keyA = a.value,
                keyB = b.value;
                if (keyA < keyB) return -1;
                if (keyA > keyB) return 1;
                return 0;
            });
        }
		console.log(lChartData);
		for(var key in lChartData){


			bars.push((
				<div className="horiz-chart-data-repeat" key={"bar"+key}>

					<div className={`horizonatal-chart-left-side`}>
						<div className="horiz-chart-top-info d-flex align-items-end">

							<h3  style={{ color: lChartData[key].color }} className={`h3 heading ${lChartData[key].value != 0 ? color.text : ''}`}>{lChartData[key].percentage_label}</h3>

							<Text type={'caption'} fontWeight={'medium-font'} cssClasses={'color-black ml-2'}>{lChartData[key].value === 1 ? lChartData[key].value + " Participant" : lChartData[key].value + ' Participants'}</Text>

						</div>

						<div className="horiz-chart-hold-bg">
							<div class={`horiz-chart-wrap ${lChartData[key].percentage <= 30 ? 'small-width-chart-area' : ''} ${lChartData[key].percentage >= 1 ? 'small-width-chart-area-1' : ''}`}
								 style={{ width: lChartData[key].percentage + "%", backgroundColor: lChartData[key].color  }}

							>
								<div
									className={`edu-graph-count ${lChartData[key].percentage == 0 ? 'p-0' : ''}`}
									style={{ backgroundColor: lChartData[key].color }}
								>

								</div>


							</div>
						</div>

					</div>

					<div className="horizonatal-chart-right-side">
						<div className="horiz-chart-text-wrap d-flex align-items-center">
							<Text type={'body-text-2'} fontWeight={'medium-font'} cssClasses={`color-black ${test.language == "ar" ? "arabic-font" : ""}`}>{lChartData[key].label}</Text>
						</div>

					</div>

				</div>
			))
		}
		if (lChartData.length > 0) {
			return <div className="edu-label-graph-bg" ref={ref} style={{position:'relative'}}><div className="edu-graph-inner-hold"> {bars}</div>
				{tooltip.open ? (
					<>
						<Tooltip
							ref={tipref}
							type={tooltip.tipPosition}
							key={Math.random()} // needed for bounds to update correctly
							style={{ zIndex:0, display: tooltip.show? "block" : 'block', whiteSpace:"nowrap", width:"auto", minWidth:"150px", margin:0, position:"absolute",left:(tooltip.tooltipLeft)+"px",top:(tooltip.tooltipTop)+"px"}}
							data={tooltip.tooltipData}
							className="tooltipChart"

						/>
					</>
				): (
					<></>
				)}
			</div>;

		} else {
			return <div className="info-not-available">No info available</div>
		}
	}
	

	return <>
		
	<div className="position-relative chart-download-with-heading multichoice-chart-hold multichoice-chart-dropdown-download-hold">
		<div className="mb-20 box-outer-heading box-heading-with-dropdown">
			<Text type={"h4"} fontWeight={"medium-font"}>{title}</Text>
			<DropdownComponent 
				options={[{
					label: "High to low",
					value: "Desc"
				},{
					label: "Low to high",
					value: "Asc"
				},{
					label: "Default",
						value: "Default"
				}]}
				value={sortDir}
				onChange={(option)=>{
					setSortDir(option.value);
				}}
			/>
		</div>
		
			
		<div className="download-chart-png cursor-pointer" onClick={() => exportAsImage(exportPngRef.current, "Multiple choice")}>
			<Icon value={'download'} size={'small'} hover={'true'}></Icon>
			<div className="tooltip-wrapper top-tooltip">
				<p className="body-text body-text-2 normal-font ">Download</p>
			</div>
		</div>
		<div ref={exportPngRef}>
			<Box  size={"large"} >
				{renderHorizontalBarChart()}
			</Box></div></div></>;
}