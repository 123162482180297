import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { LayoutResearcher } from "../../../themes/userq/Layouts/layout_researcher";
import { Helmet } from "react-helmet";
import { Text } from "../../../themes/userq/Text/Text";
import { Icon } from "../../../themes/userq/Icon/Icon";
import {
    getListOfWorkspaces,
    getWorkspaceService,
    updateWorkspaceTitleService,
    removeInvitedMemberService,
    revokeInvitationMemberService,
    resendInvitationMemberService,
    leaveWorkspaceService,
    makeWorkspaceAdminService,
} from "../../../../services/workspaces";
import { LoadingIcon } from "../../../themes/userq/Loader/loadingIcon";
import { ReactSession } from "../../../../lib/secure_reactsession";
import { showToast } from "../../../../lib/helpers";
import { Button } from "../../../themes/userq/Button/Button";
import { Tag } from "../../../themes/userq/Tag/Tag";
import { Input } from "../../../themes/userq/Input/Input";
import moment from "moment";
import { ToastMessage } from "../../../themes/userq/ToastMessage/ToastMessage";
import { Dropdown } from "react-bootstrap";
import { CircularNameIcon } from "../../../themes/userq/CircularNameIcon/CircularNameIcon";
import AddMembersModal from "../modals/add-members-modal";
import RemoveMemberModal from "../modals/remove-member-modal";
import LeaveWorkspaceModal from "./leave-workspace-modal/leave-workspace-modal";
import DeleteWorkspaceModal from "../modals/delete-workspace-modal";
import { Tooltip } from "../../../themes/userq/Tooltip/Tooltip";
import Skeleton from "react-loading-skeleton";
import HelperModal from "../modals/helper-modal";
import { useTestBuilderData } from "../../tests/contexts/TestBuilderContext";

const TeamAndSettings = () => {
    ReactSession.setStoreType("localStorage");
    const [pageLoading, setPageLoading] = useState(false);
    const [show_support_panel, setShowSupportPanel] = useState(false);
    const [formValues, setFormValues] = useState({
        workspaceName: "",
    });
    const [formErrors, setFormErrors] = useState({
        workspaceName: null,
        error_class: null,
    });

    const workspaceMaxlength = 30;
    const { workspaceid } = useParams();
    const [teamMembers, setTeamMembers] = useState([]);
    const [maxSeats, setMaxSeats] = useState(0);
    const [numSeats, setNumSeats] = useState(0);
    const [isAdmin, setIsAdmin] = useState(false);
    const [addMemberModal, setAddMemberModal] = useState({
        open: false,
    });
    const [colorUsed, setColorUsed] = useState([]);
    const [removeMemberModal, setRemoveMemberModal] = useState({
        open: false,
        memberId: null,
        memberName: null,
    });
    const navigate = useNavigate();
    const [workSpaceTitleChanged, setWorkSpaceTitleChanged] = useState(false);
    const [workspaceTitleLoading, setWorkspaceTitleLoading] = useState(false);
    const [workspaceName, setWorkspaceName] = useState();
    const [workspaceLoading, setWorkspaceLoading] = useState(false);
    const [removeMemberLoading, setRemoveMemberLoading] = useState(false);

    const [confirmLeaveWorkspace, setConfirmLeaveWorkspace] = useState({
        open: false,
    });
    const [totalWorkspacesCount, setTotalWorkspacesCount] = useState(0);
    const [leaveWorkspaceLoading, setLeaveWorkspaceLoading] = useState(false);
    const [teamMember, setTeamMember] = useState(null);
    const [workspace, setWorkspace] = useState(null);
    const [deleteWorkspaceModal, setDeleteWorkspaceModal] = useState({
        open: false,
    });
    const [makeAdminModal, setMakeAdminModal] = useState({
        open: false,
        memberId: null,
        memberName: null,
    });
    const [makeAdminLoading, setMakeAdminLoading] = useState(false);

    const { events, setFrozenWorkspaceModal } = useTestBuilderData();

    const [fullName, setFullName] = useState();

    useEffect(() => {
        events.forEach(function (event) {
            if (event.event_type === "frozen_workspace_active") {
                if (workspace && workspace.id === event.event_id) {
                    let workspaceLoc = Object.assign({}, workspace);
                    workspaceLoc.status = "active";

                    setWorkspace(workspaceLoc);

                    setFrozenWorkspaceModal({ open: false });
                }
            } else if (event.event_type === "workspace_admin_added") {
                if (workspace && workspace.id === event.event_id) {
                    if (event.user_id === teamMember.user_id) {
                        let teamMemberLoc = Object.assign({}, teamMember);
                        teamMemberLoc.role = "admin";

                        setIsAdmin(true);
                        setTeamMember(teamMemberLoc);

                        let event_param = JSON.parse(event.event_parameter);

                        var newTeamMembers = [];
                        teamMembers.forEach(function (item) {
                            if (
                                item.user_id === event_param.old_admin_user_id
                            ) {
                                item.role = "Member";
                            } else if (item.user_id === event.user_id) {
                                item.role = "Admin";
                            }
                            newTeamMembers.push(item);
                        });
                        setTeamMembers(newTeamMembers);
                    }
                }
            } else if (event.event_type === "workspace_admin_updated") {
                if (workspace && workspace.id === event.event_id) {
                    if (event.user_id === teamMember.user_id) {
                        let event_param = JSON.parse(event.event_parameter);

                        var newTeamMembers = [];
                        teamMembers.forEach(function (item) {
                            if (
                                item.user_id === event_param.new_admin_user_id
                            ) {
                                item.role = "Admin";
                            } else {
                                item.role = "Member";
                            }
                            newTeamMembers.push(item);
                        });
                        setTeamMembers(newTeamMembers);
                    }
                }
            }
        });
    }, [events]);

    const getWorkspaceApi = (tableLoad = false) => {
        let token = ReactSession.get("token");
        if (tableLoad) {
            setWorkspaceLoading(true);
        } else {
            setPageLoading(true);
        }

        getWorkspaceService(token, workspaceid)
            .then((response) => {
                if (tableLoad) {
                    setWorkspaceLoading(false);
                } else {
                    setPageLoading(false);
                }

                if (response.success) {
                    const user = ReactSession.get("user");
                    // console.log("what is the response..", response);

                    setIsAdmin(response.is_admin);

                    if (response.total_workspaces_count) {
                        setTotalWorkspacesCount(
                            response.total_workspaces_count
                        );
                    }

                    if (response.workspace && response.workspace_team_member) {
                        setTeamMember(response.workspace_team_member);

                        setWorkspace(response.workspace);

                        if (
                            response.workspace_team_member.status !==
                                "invitation_accepted" &&
                            response.workspace_team_member.status !== "active"
                        ) {
                            navigate(
                                "/wsp/" + response.workspace.id + "/noaccess"
                            );
                        }
                    }
                    if (
                        response.workspace &&
                        response.workspace.workspace_team_members.length
                    ) {
                        if (
                            response.workspace_team_member.status !==
                                "invitation_accepted" &&
                            response.workspace_team_member.status !== "active"
                        ) {
                            navigate(
                                "/wsp/" + response.workspace.id + "/noaccess"
                            );
                        }
                        let user = ReactSession.get("user");
                        let membersLocal = [];
                        let colorsUsedLocal = [];
                        response.workspace.workspace_team_members.forEach(
                            function (member) {
                                membersLocal.push({
                                    id: member.id,
                                    user_id: member.user_id,
                                    email:
                                        member.user && member.user != null
                                            ? member.user.email
                                            : member.email,
                                    label:
                                        member.user && member.user != null
                                            ? member.user.first_name != null
                                                ? member.user.first_name
                                                      .toUpperCase()
                                                      .charAt(0)
                                                : member.user.email
                                                      .toUpperCase()
                                                      .charAt(0)
                                            : member.email
                                                  .toUpperCase()
                                                  .charAt(0),
                                    memberSince: member.member_since ? (
                                        moment(member.member_since).format(
                                            "DD-MM-YYYY"
                                        )
                                    ) : (
                                        <div className="text-center">-</div>
                                    ),
                                    sentOn: moment(member.updated_at).format(
                                        "DD/MM/YYYY"
                                    ),
                                    role:
                                        member.role.charAt(0).toUpperCase() +
                                        member.role.slice(1),
                                    status: member.status,
                                    color: member.color,
                                    name:
                                        member.user && member.user != null
                                            ? `${member.user.first_name} ${member.user.last_name}`
                                            : "",
                                });
                                colorsUsedLocal.push(member.color);
                            }
                        );
                        setTeamMembers(membersLocal);
                        setNumSeats(membersLocal.length);
                        setColorUsed(colorsUsedLocal);
                    }
                    if (response.workspace.title) {
                        let nameWorkspace = response.workspace.title.slice(
                            0,
                            30
                        );
                        setFormValues({
                            ...formValues,
                            workspaceName: nameWorkspace,
                        });
                        setWorkspaceName(response.workspace.title);
                    } else {
                        let nameWorkspace = (user.first_name + "'s Team").slice(
                            0,
                            30
                        );

                        setFormValues({
                            ...formValues,
                            workspaceName: nameWorkspace,
                        });
                    }
                    if (response.seats) {
                        setMaxSeats(+response.seats);
                    }
                } else {
                    if (response.message == "Workspace not found.") {
                        navigate("/404/");
                    } else if (
                        response.message == "Workspace project not found."
                    ) {
                        navigate("/404/");
                    } else {
                        showToast(response.message, "error");
                    }
                }
            })
            .catch((error) => {
                // console.error("Error fetching workspace:", error);
                if (tableLoad) {
                    setWorkspaceLoading(false);
                } else {
                    setPageLoading(false);
                }
            });
    };

    const saveWorkspaceTitle = () => {
        if (formValues.workspaceName.trim() && !workspaceTitleLoading) {
            let token = ReactSession.get("token");
            setWorkspaceTitleLoading(true);
            let formData = {};
            formData.title = formValues.workspaceName;
            formData.workspace_id = workspaceid;
            updateWorkspaceTitleService(formData, token)
                .then((response) => {
                    setWorkspaceTitleLoading(false);
                    if (response.success) {
                        setWorkspaceName(formValues.workspaceName);
                        setWorkSpaceTitleChanged(false);
                        showToast(response.message, "success");
                        // console.log('response log', response);
                    } else {
                        showToast(response.message, "error");
                    }
                })
                .catch((error) => {
                    setWorkspaceTitleLoading(false);
                });
        }
    };

    useEffect(() => {
        const user = ReactSession.get("user");

        setFullName(user.first_name + " " + user.last_name);

        getWorkspaceApi();
    }, [workspaceid]);

    const removeMemberFromWorkspace = () => {
        // console.log("removeMemberFromWorkspace", removeMemberModal.memberId);
        if (!removeMemberLoading) {
            let token = ReactSession.get("token");
            // setPageLoading(true);
            setRemoveMemberLoading(true);
            let formData = {};
            formData.member_id = removeMemberModal.memberId;
            formData.workspace_id = workspaceid;
            removeInvitedMemberService(formData, token)
                .then((response) => {
                    setRemoveMemberLoading(false);
                    if (response.success) {
                        showToast(response.message, "success");
                        getWorkspaceApi(true);
                        setRemoveMemberModal({
                            ...removeMemberModal,
                            open: false,
                            memberId: null,
                            memberName: null,
                        });
                    } else {
                        showToast(response.message, "error");
                    }
                })
                .catch((error) => {
                    setRemoveMemberLoading(false);
                });
        }
    };

    const revokeInvitation = (memberId) => {
        let token = ReactSession.get("token");
        setWorkspaceLoading(true);
        let formData = {};
        formData.member_id = memberId;
        formData.workspace_id = workspaceid;
        revokeInvitationMemberService(formData, token)
            .then((response) => {
                if (response.success) {
                    showToast(response.message, "success");
                    getWorkspaceApi(true);
                } else {
                    setWorkspaceLoading(false);
                    showToast(response.message, "error");
                }
            })
            .catch((error) => {
                setWorkspaceLoading(false);
            });
    };

    const resendInvitation = (memberId) => {
        let token = ReactSession.get("token");
        setWorkspaceLoading(true);
        let formData = {};
        formData.member_id = memberId;
        formData.workspace_id = workspaceid;
        resendInvitationMemberService(formData, token)
            .then((response) => {
                if (response.success) {
                    var message_lines = response.message.split("<br/>");

                    var message_object = message_lines.map(function (msg) {
                        return <div>{msg}</div>;
                    });
                    showToast(<>{message_object}</>, "success");
                    getWorkspaceApi(true);
                } else {
                    setWorkspaceLoading(false);
                    showToast(response.message, "error");
                }
            })
            .catch((error) => {
                setWorkspaceLoading(false);
            });
    };

    const leaveWorkspaceApi = (signature) => {
        let token = ReactSession.get("token");

        setLeaveWorkspaceLoading(true);
        let formData = {};
        formData.signature = signature;
        formData.workspace_id = workspaceid;
        leaveWorkspaceService(formData, token)
            .then((response) => {
                setLeaveWorkspaceLoading(false);
                if (response.success) {
                    if (response.workspace_id) {
                        navigate("/wsp/" + response.workspace_id);

                        showToast(response.message, "success");
                    }
                } else {
                    showToast(response.message, "error");
                }
            })
            .catch((error) => {
                setWorkspaceLoading(false);
            });
    };

    const frozenPaymentMethodUpdate = (status = null) => {
        let payment_method_modal = {
            open: true,
            workspace_id: workspaceid,
            status: status,
        };
        ReactSession.set("payment_method_modal", payment_method_modal);
        navigate(`/wsp/${workspaceid}/workspace-billing`);
    };

    const deleteWorkspace = (signature = null) => {
        if (signature.toLowerCase() === fullName.toLowerCase()) {
            let workspace_removal = {
                workspace_id: workspaceid,
                type: "Team",
                signature: signature,
                name: workspaceName ? workspaceName : "workspace",
            };
            ReactSession.set("workspace_removal", workspace_removal);
            navigate(`/r/delete-workspace`);
        }
    };

    const closeMakeAdminModal = () => {
        // memberId
        if (!makeAdminLoading) {
            setMakeAdminModal({
                ...makeAdminModal,
                open: false,
                memberId: null,
                memberName: null,
            });
        }
    };

    const makeWorkspaceAdmin = (memberId) => {
        if (!makeAdminLoading) {
            let token = ReactSession.get("token");
            setMakeAdminLoading(true);
            let formData = {};
            formData.member_id = memberId;
            formData.workspace_id = workspaceid;
            makeWorkspaceAdminService(formData, token)
                .then((response) => {
                    if (response.success) {
                        showToast(response.message, "success");
                        setMakeAdminLoading(false);
                        setMakeAdminModal({
                            ...makeAdminModal,
                            open: false,
                            memberId: null,
                            memberName: null,
                        });
                        getWorkspaceApi(true);
                    } else {
                        setMakeAdminLoading(false);
                        showToast(response.message, "error");
                    }
                })
                .catch((error) => {
                    setMakeAdminLoading(false);
                });
        }
    };

    return (
        <LayoutResearcher
            isLoading={false}
            skipCheck={false}
            profileHeader={false}
            activeMenu={"dashboard"}
            openSupportPanel={show_support_panel}
            hideSupportPanel={() => {
                setShowSupportPanel(false);
            }}
            wrapClass={`team-setting-page-wrapper`}
            workspaceName={workspaceName}
            isWorkspaceFrozen={false}
        >
            <Helmet>
                <title>Workspace & team settings | UserQ</title>
            </Helmet>
            <div
                className={`team-setting-page-inner ${
                    workspace && workspace.status === "frozen"
                        ? "frozen-account"
                        : ""
                }`}
            >
                {pageLoading ? (
                    <>
                        <div className="workspace-page-loader">
                            <div
                                className={
                                    "page-loader accountloading-box creditloading-box"
                                }
                            >
                                <LoadingIcon />
                            </div>
                        </div>
                    </>
                ) : (
                    <>
                        {workspace &&
                            workspace.is_downgrade_scheduled == 1 &&
                            teamMember &&
                            teamMember.role === "admin" && (
                                <div className="mb-40">
                                    <ToastMessage
                                        icon={"group"}
                                        type={"info"}
                                        message={
                                            <>
                                                <div className="d-flex align-items-center freez-toast-wrap justify-content-between">
                                                    <span className="mr-3">
                                                        Your Team plan is
                                                        cancelled and will
                                                        expire on{" "}
                                                        {workspace.next_billing_date
                                                            ? moment(
                                                                  workspace.next_billing_date
                                                              ).format(
                                                                  "DD/MM/YYYY"
                                                              )
                                                            : "-"}
                                                        .
                                                    </span>
                                                    <span>
                                                        Changed your mind?{" "}
                                                        <Link
                                                            className="link-text"
                                                            onClick={(e) => {
                                                                e.preventDefault();
                                                                frozenPaymentMethodUpdate(
                                                                    "reactivate"
                                                                );
                                                            }}
                                                            to={"/"}
                                                        >
                                                            Re-activate your
                                                            Team Plan
                                                        </Link>
                                                    </span>
                                                </div>
                                            </>
                                        }
                                        closable={false}
                                    />
                                </div>
                            )}

                        {/* {workspace && workspace.is_new_admin_proposed && !workspace.payment_method_id && teamMember && teamMember.role === "admin" && (
                            <div className="mb-40">
                                <ToastMessage
                                    type={"warning"}
                                    message={
                                        <>
                                            <>
                                                As the admin
                                                of this
                                                workspace,
                                                it's
                                                mandatory to
                                                maintain a
                                                valid
                                                payment
                                                method.
                                                . The next
                                                billing date
                                                is scheduled
                                                for{" "}
                                                {workspace.next_billing_date
                                                    ? moment(
                                                        workspace.next_billing_date
                                                    ).format(
                                                        "DD/MM/YY"
                                                    )
                                                    : "-"}
                                                .<br/> Please add
                                                or update
                                                your payment
                                                method to
                                                ensure it's
                                                in place
                                                before the
                                                next billing
                                                cycle.
                                            </>

                                            <Button
                                                type={"primary"}
                                                size={"small"}
                                                label={
                                                    "Update payment method"
                                                }
                                                onClick={(
                                                    e
                                                ) => {
                                                    e.stopPropagation();

                                                    frozenPaymentMethodUpdate("expired");
                                                }}
                                            />
                                        </>
                                    }
                                    closable={false}
                                />
                            </div>
                        )} */}

                        {workspace && workspace.status === "frozen" ? (
                            <div className="mb-40">
                                <ToastMessage
                                    type={
                                        teamMember &&
                                        teamMember.role == "member"
                                            ? "snowflake"
                                            : workspace &&
                                              workspace.show_payment_method_saved_msg ==
                                                  1
                                            ? "info"
                                            : "error"
                                    }
                                    icon={
                                        teamMember &&
                                        teamMember.role == "member"
                                            ? "snowflake"
                                            : workspace &&
                                              workspace.show_payment_method_saved_msg ==
                                                  1
                                            ? "time"
                                            : "error"
                                    }
                                    message={
                                        <>
                                            {teamMember && (
                                                <>
                                                    {teamMember.role ===
                                                    "admin" ? (
                                                        <>
                                                            {workspace &&
                                                            workspace.show_payment_method_saved_msg ==
                                                                1 ? (
                                                                <>
                                                                    Your payment
                                                                    method has
                                                                    been
                                                                    successfully
                                                                    saved. The
                                                                    subscription
                                                                    payment will
                                                                    be processed
                                                                    soon.
                                                                </>
                                                            ) : (
                                                                <div className="d-flex align-items-center freez-toast-wrap">
                                                                    <span className="mr-3">
                                                                        This
                                                                        workspace
                                                                        is
                                                                        frozen
                                                                        due to
                                                                        unsuccessful
                                                                        transaction.
                                                                        Please
                                                                        update
                                                                        your
                                                                        payment
                                                                        method
                                                                        to
                                                                        retain
                                                                        team
                                                                        capabilities
                                                                        or
                                                                        switch
                                                                        to
                                                                        Pay-As-You-Go
                                                                    </span>
                                                                    <Button
                                                                        type={
                                                                            "primary"
                                                                        }
                                                                        size={
                                                                            "small"
                                                                        }
                                                                        label={
                                                                            "Update payment method"
                                                                        }
                                                                        onClick={(
                                                                            e
                                                                        ) => {
                                                                            e.stopPropagation();

                                                                            frozenPaymentMethodUpdate(
                                                                                "frozen"
                                                                            );
                                                                        }}
                                                                    />
                                                                </div>
                                                            )}
                                                        </>
                                                    ) : (
                                                        <>
                                                            The workspace is not
                                                            available due to
                                                            pending payment.
                                                            Please contact your
                                                            workspace admin to
                                                            resolve the issue.
                                                        </>
                                                    )}
                                                </>
                                            )}
                                        </>
                                    }
                                    closable={false}
                                />
                            </div>
                        ) : (
                            <>
                                {workspace &&
                                    workspace.payment_method &&
                                    workspace.payment_method
                                        .card_expire_status === 1 &&
                                    teamMember &&
                                    teamMember.role == "admin" && (
                                        <div className="mb-40">
                                            <ToastMessage
                                                type={"warning"}
                                                message={
                                                    <>
                                                        {workspace
                                                            .payment_method
                                                            .expired ? (
                                                            <>
                                                                As the admin of
                                                                this workspace,
                                                                it's mandatory
                                                                to maintain a
                                                                valid payment
                                                                method. The
                                                                credit card
                                                                connected to
                                                                this workspace
                                                                has expired on{" "}
                                                                {(function () {
                                                                    if (
                                                                        workspace
                                                                            .payment_method
                                                                            .card_expire_at
                                                                    ) {
                                                                        return moment(
                                                                            workspace
                                                                                .payment_method
                                                                                .card_expire_at
                                                                        ).format(
                                                                            "DD/MM/YY"
                                                                        );
                                                                    } else {
                                                                        return "-";
                                                                    }
                                                                })()}
                                                                . The next
                                                                billing date is
                                                                scheduled for{" "}
                                                                {workspace.next_billing_date
                                                                    ? moment(
                                                                          workspace.next_billing_date
                                                                      ).format(
                                                                          "DD/MM/YY"
                                                                      )
                                                                    : "-"}
                                                                .<br /> Please
                                                                add or update
                                                                your payment
                                                                method to ensure
                                                                it's in place
                                                                before the next
                                                                billing cycle.
                                                            </>
                                                        ) : (
                                                            <>
                                                                As the admin of
                                                                this workspace,
                                                                it's mandatory
                                                                to maintain a
                                                                valid payment
                                                                method. The
                                                                credit card
                                                                connected to
                                                                this workspace
                                                                will expire on{" "}
                                                                {(function () {
                                                                    if (
                                                                        workspace
                                                                            .payment_method
                                                                            .card_expire_at
                                                                    ) {
                                                                        return moment(
                                                                            workspace
                                                                                .payment_method
                                                                                .card_expire_at
                                                                        ).format(
                                                                            "DD/MM/YY"
                                                                        );
                                                                    } else {
                                                                        return "-";
                                                                    }
                                                                })()}
                                                                . The next
                                                                billing date is
                                                                scheduled for{" "}
                                                                {workspace.next_billing_date
                                                                    ? moment(
                                                                          workspace.next_billing_date
                                                                      ).format(
                                                                          "DD/MM/YY"
                                                                      )
                                                                    : "-"}
                                                                . <br />
                                                                Please add or
                                                                update your
                                                                payment method
                                                                to ensure it's
                                                                in place before
                                                                the next billing
                                                                cycle.
                                                            </>
                                                        )}
                                                        <Button
                                                            type={"primary"}
                                                            size={"small"}
                                                            label={
                                                                "Update payment method"
                                                            }
                                                            onClick={(e) => {
                                                                e.stopPropagation();

                                                                frozenPaymentMethodUpdate(
                                                                    "expired"
                                                                );
                                                            }}
                                                        />
                                                    </>
                                                }
                                                closable={false}
                                            />
                                        </div>
                                    )}
                            </>
                        )}
                        <div className="page-heading-wrap accounts-page--heading">
                            <Text type={"h1"}>Team & Settings</Text>
                        </div>

                        <div className="team-setting-section-hold">
                            <div className="team-setting-left-sec">
                                <div className="team-setting-workspace-name d-flex align-items-center">
                                    {isAdmin ? (
                                        <>
                                            <Input
                                                type="text"
                                                id="workspace"
                                                value={formValues.workspaceName}
                                                onChange={(e) => {
                                                    setFormValues({
                                                        ...formValues,
                                                        workspaceName:
                                                            e.target.value,
                                                    });
                                                    setWorkSpaceTitleChanged(
                                                        true
                                                    );
                                                }}
                                                label={"Workspace name"}
                                                clearField={false}
                                                // error={formErrors["workspaceName"]}
                                                // error_class={formErrors.error_class}
                                                maxLength={workspaceMaxlength}
                                            />
                                            {workSpaceTitleChanged ? (
                                                <button
                                                    type="button"
                                                    className={
                                                        "btn primary-medium " +
                                                        (!isAdmin ||
                                                        !formValues.workspaceName.trim()
                                                            ? "btn-disabled"
                                                            : "active") +
                                                        (workspaceTitleLoading
                                                            ? "no-hover-state"
                                                            : "")
                                                    }
                                                    onClick={() => {
                                                        if (
                                                            isAdmin &&
                                                            formValues.workspaceName.trim()
                                                        ) {
                                                            saveWorkspaceTitle();
                                                        }
                                                    }}
                                                    id="r_workspaceName"
                                                >
                                                    {workspaceTitleLoading ? (
                                                        <div className="micro-loading">
                                                            <div className="three-balls">
                                                                <div className="ball ball1"></div>
                                                                <div className="ball ball2"></div>
                                                                <div className="ball ball3"></div>
                                                            </div>
                                                        </div>
                                                    ) : (
                                                        <>{"Save"}</>
                                                    )}
                                                </button>
                                            ) : (
                                                <button
                                                    type="button"
                                                    className={
                                                        "btn primary-medium btn-disabled"
                                                    }
                                                    id="r_workspaceName"
                                                >
                                                    Save
                                                </button>
                                            )}
                                        </>
                                    ) : (
                                        <>
                                            <div className="member-team-workspace-info mt-32">
                                                <Text
                                                    type={"body-text-3"}
                                                    fontWeight={"medium-font"}
                                                    cssClasses={"gray-color"}
                                                >
                                                    Workspace name
                                                </Text>
                                                <Text type={"subtitle-2"}>
                                                    {formValues.workspaceName}
                                                </Text>
                                            </div>
                                        </>
                                    )}
                                </div>

                                <div className="team-setting-page-text mt-60">
                                    <Text
                                        type={"body-text-2"}
                                        fontWeight={"semi-bold-font"}
                                        cssClasses={"mb-8"}
                                    >
                                        Workspace team members
                                    </Text>
                                    <Text
                                        type={"body-text-3"}
                                        fontWeight={"medium-font"}
                                        cssClasses={"gray-color"}
                                    >
                                        Members of a workspace can share credits
                                        and have shared access to projects and
                                        tests within the workspace.
                                    </Text>
                                </div>

                                <div className="team-setting-table-wrap mt-20 mb-32">
                                    <table
                                        className={`table ${
                                            isAdmin
                                                ? "admin-table"
                                                : "member-table"
                                        }`}
                                    >
                                        <thead>
                                            <tr>
                                                <th>Email</th>
                                                <th>Member since</th>
                                                <th>Role</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {workspaceLoading ? (
                                                <>
                                                    <tr>
                                                        <td>
                                                            <Skeleton
                                                                width={200}
                                                                height={32}
                                                                style={{
                                                                    borderRadius:
                                                                        "8px",
                                                                }}
                                                                className={""}
                                                            />
                                                        </td>
                                                        <td>
                                                            <Skeleton
                                                                width={200}
                                                                height={32}
                                                                style={{
                                                                    borderRadius:
                                                                        "8px",
                                                                }}
                                                                className={""}
                                                            />
                                                        </td>
                                                        <td>
                                                            <Skeleton
                                                                width={100}
                                                                height={32}
                                                                style={{
                                                                    borderRadius:
                                                                        "8px",
                                                                }}
                                                                className={""}
                                                            />
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>
                                                            <Skeleton
                                                                width={200}
                                                                height={32}
                                                                style={{
                                                                    borderRadius:
                                                                        "8px",
                                                                }}
                                                                className={""}
                                                            />
                                                        </td>
                                                        <td>
                                                            <Skeleton
                                                                width={200}
                                                                height={32}
                                                                style={{
                                                                    borderRadius:
                                                                        "8px",
                                                                }}
                                                                className={""}
                                                            />
                                                        </td>
                                                        <td>
                                                            <Skeleton
                                                                width={100}
                                                                height={32}
                                                                style={{
                                                                    borderRadius:
                                                                        "8px",
                                                                }}
                                                                className={""}
                                                            />
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>
                                                            <Skeleton
                                                                width={200}
                                                                height={32}
                                                                style={{
                                                                    borderRadius:
                                                                        "8px",
                                                                }}
                                                                className={""}
                                                            />
                                                        </td>
                                                        <td>
                                                            <Skeleton
                                                                width={200}
                                                                height={32}
                                                                style={{
                                                                    borderRadius:
                                                                        "8px",
                                                                }}
                                                                className={""}
                                                            />
                                                        </td>
                                                        <td>
                                                            <Skeleton
                                                                width={100}
                                                                height={32}
                                                                style={{
                                                                    borderRadius:
                                                                        "8px",
                                                                }}
                                                                className={""}
                                                            />
                                                        </td>
                                                    </tr>
                                                </>
                                            ) : (
                                                <>
                                                    {teamMembers.map(
                                                        (member, index) => (
                                                            <tr key={index}>
                                                                <td>
                                                                    <div
                                                                        className={`workspace-member-email-col d-flex align-item-center ${
                                                                            member.status ===
                                                                            "invitation_sent"
                                                                                ? "pending-invitation-label"
                                                                                : ""
                                                                        }`}
                                                                    >
                                                                        <CircularNameIcon
                                                                            label={
                                                                                member.label
                                                                            }
                                                                            style={{
                                                                                background:
                                                                                    member.status ==
                                                                                    "invitation_sent"
                                                                                        ? "#9C9C9C"
                                                                                        : member.color,
                                                                            }}
                                                                        />
                                                                        <span className="info-wrap">
                                                                            <span className="member-email-text">
                                                                                {member
                                                                                    .email
                                                                                    .length >
                                                                                33
                                                                                    ? member.email.slice(
                                                                                          0,
                                                                                          33
                                                                                      ) +
                                                                                      "..."
                                                                                    : member.email}
                                                                            </span>
                                                                            {member
                                                                                .email
                                                                                .length >
                                                                                33 && (
                                                                                <div className="tooltip-hold">
                                                                                    <Tooltip
                                                                                        data={
                                                                                            member.email
                                                                                        }
                                                                                        type="top"
                                                                                    />
                                                                                </div>
                                                                            )}
                                                                        </span>
                                                                        {member.status ===
                                                                            "invitation_sent" && (
                                                                            <>
                                                                                <span className="info-wrap">
                                                                                    <Icon
                                                                                        value={
                                                                                            "time"
                                                                                        }
                                                                                        color={
                                                                                            "gray-900-svg"
                                                                                        }
                                                                                    />

                                                                                    <Tooltip
                                                                                        data={
                                                                                            "Invitation sent on " +
                                                                                            member.sentOn
                                                                                        }
                                                                                        type={
                                                                                            "bottom"
                                                                                        }
                                                                                    />
                                                                                </span>
                                                                            </>
                                                                        )}
                                                                    </div>
                                                                </td>
                                                                <td>
                                                                    {
                                                                        member.memberSince
                                                                    }
                                                                </td>
                                                                <td className="tableDropdown">
                                                                    <div className="workspace-role-col d-flex align-items-center">
                                                                        {
                                                                            member.role
                                                                        }
                                                                        {isAdmin &&
                                                                            member.role !==
                                                                                "Admin" && (
                                                                                <Dropdown className="dropdown">
                                                                                    <Dropdown.Toggle
                                                                                        id={
                                                                                            "dropdownMenuButton" +
                                                                                            member.id
                                                                                        }
                                                                                        className="dropdown-toggle"
                                                                                    >
                                                                                        <span className="menu-item">
                                                                                            <Icon
                                                                                                value={
                                                                                                    "Quick menu"
                                                                                                }
                                                                                                colorClass={
                                                                                                    "gray-900-svg hamburger-menu-img"
                                                                                                }
                                                                                            />
                                                                                        </span>
                                                                                    </Dropdown.Toggle>
                                                                                    {member.status ===
                                                                                    "invitation_sent" ? (
                                                                                        <>
                                                                                            <Dropdown.Menu
                                                                                                className={
                                                                                                    "hamburger-items"
                                                                                                }
                                                                                            >
                                                                                                <Dropdown.Item
                                                                                                    className={`${
                                                                                                        workspace &&
                                                                                                        workspace.status ==
                                                                                                            "frozen"
                                                                                                            ? "disabled"
                                                                                                            : ""
                                                                                                    }`}
                                                                                                    onClick={() => {
                                                                                                        if (
                                                                                                            workspace &&
                                                                                                            workspace.status !==
                                                                                                                "frozen"
                                                                                                        ) {
                                                                                                            resendInvitation(
                                                                                                                member.id
                                                                                                            );
                                                                                                        }
                                                                                                    }}
                                                                                                >
                                                                                                    Re-send
                                                                                                    invitation
                                                                                                </Dropdown.Item>
                                                                                                <Dropdown.Item
                                                                                                    className={`${
                                                                                                        workspace &&
                                                                                                        workspace.status ==
                                                                                                            "frozen"
                                                                                                            ? "disabled"
                                                                                                            : ""
                                                                                                    }`}
                                                                                                    onClick={() => {
                                                                                                        if (
                                                                                                            workspace &&
                                                                                                            workspace.status !==
                                                                                                                "frozen"
                                                                                                        ) {
                                                                                                            revokeInvitation(
                                                                                                                member.id
                                                                                                            );
                                                                                                        }
                                                                                                    }}
                                                                                                >
                                                                                                    Revoke
                                                                                                    invitation
                                                                                                </Dropdown.Item>
                                                                                            </Dropdown.Menu>
                                                                                        </>
                                                                                    ) : (
                                                                                        <>
                                                                                            <Dropdown.Menu
                                                                                                className={
                                                                                                    "hamburger-items"
                                                                                                }
                                                                                            >
                                                                                                <Dropdown.Item
                                                                                                    className={`${
                                                                                                        workspace &&
                                                                                                        workspace.status ==
                                                                                                            "frozen"
                                                                                                            ? "disabled"
                                                                                                            : ""
                                                                                                    }`}
                                                                                                    onClick={() => {
                                                                                                        if (
                                                                                                            workspace &&
                                                                                                            workspace.status !==
                                                                                                                "frozen"
                                                                                                        ) {
                                                                                                            setMakeAdminModal(
                                                                                                                {
                                                                                                                    open: true,
                                                                                                                    memberId:
                                                                                                                        member.id,
                                                                                                                    memberName:
                                                                                                                        member.name,
                                                                                                                }
                                                                                                            );
                                                                                                        }
                                                                                                    }}
                                                                                                >
                                                                                                    Make
                                                                                                    Admin
                                                                                                </Dropdown.Item>
                                                                                                <Dropdown.Item
                                                                                                    className={`${
                                                                                                        workspace &&
                                                                                                        workspace.status ==
                                                                                                            "frozen"
                                                                                                            ? "disabled"
                                                                                                            : ""
                                                                                                    }`}
                                                                                                    onClick={() => {
                                                                                                        if (
                                                                                                            workspace &&
                                                                                                            workspace.status !==
                                                                                                                "frozen"
                                                                                                        ) {
                                                                                                            setRemoveMemberModal(
                                                                                                                {
                                                                                                                    open: true,
                                                                                                                    memberId:
                                                                                                                        member.id,
                                                                                                                    memberName:
                                                                                                                        member.name,
                                                                                                                }
                                                                                                            );
                                                                                                        }
                                                                                                    }}
                                                                                                >
                                                                                                    Remove
                                                                                                    from
                                                                                                    workspace
                                                                                                </Dropdown.Item>
                                                                                            </Dropdown.Menu>
                                                                                        </>
                                                                                    )}
                                                                                </Dropdown>
                                                                            )}
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                        )
                                                    )}
                                                </>
                                            )}
                                        </tbody>
                                    </table>
                                </div>

                                <div className="team-setting-addmoremember-wrap">
                                    {numSeats < maxSeats && (
                                        <div className="d-flex align-items-center justify-content-between">
                                            {isAdmin ? (
                                                <Button
                                                    id="r_email_sign_in"
                                                    type="secondary"
                                                    label="Add member"
                                                    // onClick={handleAddMore}
                                                    onClick={() => {
                                                        if (
                                                            !pageLoading &&
                                                            !workspaceLoading
                                                        ) {
                                                            if (
                                                                workspace &&
                                                                workspace.status !=
                                                                    "frozen"
                                                            ) {
                                                                setAddMemberModal(
                                                                    {
                                                                        ...addMemberModal,
                                                                        open: true,
                                                                    }
                                                                );
                                                            }
                                                        }
                                                    }}
                                                    size={"medium"}
                                                    iconLeft={
                                                        <Icon
                                                            value="add"
                                                            size="small"
                                                            colorClass={
                                                                "gray-900-svg"
                                                            }
                                                        />
                                                    }
                                                    state={
                                                        pageLoading ||
                                                        workspaceLoading ||
                                                        (workspace &&
                                                            workspace.status ==
                                                                "frozen")
                                                            ? "disabled"
                                                            : "active"
                                                    }
                                                />
                                            ) : (
                                                <span>&nbsp;</span>
                                            )}
                                            {(isAdmin || true) && (
                                                <div className="seats-available-count d-flex align-items-center">
                                                    <Icon
                                                        value={"group"}
                                                        size={"small"}
                                                        colorClass={
                                                            "gray-900-svg"
                                                        }
                                                    />
                                                    <Text type={"caption"}>{`${
                                                        maxSeats - numSeats ===
                                                        1
                                                            ? "1 seat available"
                                                            : `${
                                                                  maxSeats -
                                                                  numSeats
                                                              } seats available`
                                                    }`}</Text>
                                                </div>
                                            )}
                                        </div>
                                        
                                    )}
                                    {numSeats === maxSeats && isAdmin &&
                                    <ToastMessage
                                        type={"info"}
                                        closable={false}
                                        icon={"profile"}
                                        message={
                                            <>
                                                To add more members,
                                                please contact our
                                                <Link
                                                    to={
                                                        "/r/support/contact?reason=Team collaboration"
                                                    }
                                                >
                                                    support team
                                                </Link>
                                            </>
                                        }
                                    />
                                    }
                                    {(false && numSeats === maxSeats) && (
                                        <>
                                            <div className="d-flex align-items-center justify-content-between">
                                                <span>&nbsp;</span>
                                                <div className="seats-available-count d-flex align-items-center">
                                                    <Icon
                                                        value={"group"}
                                                        size={"small"}
                                                        colorClass={
                                                            "gray-900-svg"
                                                        }
                                                    />
                                                    <Text
                                                        type={"caption"}
                                                    >{`0 seats available`}</Text>
                                                </div>
                                            </div>
                                            {isAdmin ? (
                                                <ToastMessage
                                                    type={"info"}
                                                    closable={false}
                                                    icon={"profile"}
                                                    message={
                                                        <>
                                                            To add more members,
                                                            please contact our
                                                            <Link
                                                                to={
                                                                    "/r/support/contact?reason=Team collaboration"
                                                                }
                                                            >
                                                                support team
                                                            </Link>
                                                        </>
                                                    }
                                                />
                                            ) : (
                                                <></>
                                            )}
                                        </>
                                    )}
                                </div>

                                <div className="team-setting-right-sec none-for-big-screen">
                                    <div
                                        className={`gray-box  p-32 ${
                                            isAdmin ? "" : "mt-32"
                                        }`}
                                    >
                                        <Text
                                            type={"subtitle-2"}
                                            fontWeight={"semi-bold-font"}
                                        >
                                            How do the roles work?
                                        </Text>

                                        <Text
                                            type={"body-text-3"}
                                            cssClasses={"gray-color mt-32"}
                                            fontWeight={"medium-font"}
                                        >
                                            With the Team plan, you can
                                            collaborate with your team using
                                            workspaces. Each workspace can have
                                            one admin and multiple members.
                                        </Text>

                                        <div className="team-setting-info-repeat mt-20">
                                            <Text
                                                type={"body-text-2"}
                                                fontWeight={"semi-bold-font"}
                                            >
                                                Admin role
                                            </Text>
                                            <Text
                                                type={"body-text-3"}
                                                cssClasses={"gray-color"}
                                                fontWeight={"medium-font"}
                                            >
                                                Admins can manage team members
                                                and workspaces, and are
                                                responsible for billing. They
                                                can also purchase credits for
                                                the team.
                                            </Text>
                                        </div>

                                        <div className="team-setting-info-repeat mt-20">
                                            <Text
                                                type={"body-text-2"}
                                                fontWeight={"semi-bold-font"}
                                            >
                                                Member role
                                            </Text>
                                            <Text
                                                type={"body-text-3"}
                                                cssClasses={"gray-color"}
                                                fontWeight={"medium-font"}
                                            >
                                                Members have full access to
                                                projects and testing
                                                functionalities within the
                                                workspace. They can purchase
                                                credits for the team, publish or
                                                close tests, but do not have
                                                access to team or workspace
                                                settings.
                                            </Text>
                                        </div>
                                    </div>
                                </div>

                                {isAdmin ? (
                                    <div className="delete-leave-workspace-wrap delete-account-section">
                                        <Text
                                            type={"body-text-2"}
                                            fontWeight={"semi-bold-font"}
                                        >
                                            Delete workspace
                                        </Text>
                                        <Text
                                            type={"body-text-3"}
                                            fontWeight={"medium-font"}
                                            cssClasses={"gray-color"}
                                        >
                                            Once you delete the workspace, all
                                            tests and unused credits will be
                                            removed.
                                            <br />
                                            <span className="black-color">
                                                This action cannot be undone.
                                            </span>
                                        </Text>

                                        <Button
                                            type="secondary"
                                            size="medium"
                                            iconLeft={
                                                <Icon
                                                    value={"delete"}
                                                    colorClass={
                                                        "danger-200-svg"
                                                    }
                                                />
                                            }
                                            label="Delete workspace"
                                            onClick={() => {
                                                setDeleteWorkspaceModal({
                                                    ...deleteWorkspaceModal,
                                                    open: true,
                                                });
                                            }}
                                            // state={
                                            //     totalWorkspacesCount > 1
                                            //         ? ""
                                            //         : "disabled"
                                            // }
                                        />
                                    </div>
                                ) : (
                                    <div className="delete-leave-workspace-wrap delete-account-section">
                                        <Text
                                            type={"body-text-2"}
                                            fontWeight={"semi-bold-font"}
                                        >
                                            Leave Workspace
                                        </Text>
                                        <Text
                                            type={"body-text-2"}
                                            fontWeight={"medium-font"}
                                            cssClasses={"gray-color"}
                                        >
                                            Once you leave the workspace, you
                                            will not be able to access any tests
                                            within the workspace until you are
                                            re-invited.&nbsp;
                                            <span className="black-color semibold-font">
                                                This action cannot be undone.
                                            </span>
                                        </Text>

                                        <Button
                                            type="secondary"
                                            size="medium"
                                            iconLeft={
                                                <Icon
                                                    value={"exit"}
                                                    colorClass={
                                                        "danger-200-svg"
                                                    }
                                                />
                                            }
                                            label="Leave workspace"
                                            onClick={() => {
                                                if (totalWorkspacesCount > 1) {
                                                    setConfirmLeaveWorkspace({
                                                        open: true,
                                                    });
                                                } else {
                                                    showToast(
                                                        "You cannot leave your only workspace. Try to delete your account instead.",
                                                        "error"
                                                    );
                                                }
                                            }}
                                            state={
                                                totalWorkspacesCount > 1
                                                    ? "active"
                                                    : "disabled"
                                            }
                                        />
                                    </div>
                                )}
                            </div>

                            <div className="team-setting-right-sec none-for-small-screen">
                                <div
                                    className={`gray-box  p-32 ${
                                        isAdmin ? "" : "mt-32"
                                    }`}
                                >
                                    <Text
                                        type={"subtitle-2"}
                                        fontWeight={"semi-bold-font"}
                                    >
                                        How do the roles work?
                                    </Text>

                                    <Text
                                        type={"body-text-3"}
                                        cssClasses={"gray-color mt-32"}
                                        fontWeight={"medium-font"}
                                    >
                                        With the Team plan, you can collaborate
                                        with your team using workspaces. Each
                                        workspace can have one admin and
                                        multiple members.
                                    </Text>

                                    <div className="team-setting-info-repeat mt-20">
                                        <Text
                                            type={"body-text-2"}
                                            fontWeight={"semi-bold-font"}
                                        >
                                            Admin role
                                        </Text>
                                        <Text
                                            type={"body-text-3"}
                                            cssClasses={"gray-color"}
                                            fontWeight={"medium-font"}
                                        >
                                            Admins can manage team members and
                                            workspaces, and are responsible for
                                            billing. They can also purchase
                                            credits for the team.
                                        </Text>
                                    </div>

                                    <div className="team-setting-info-repeat mt-20">
                                        <Text
                                            type={"body-text-2"}
                                            fontWeight={"semi-bold-font"}
                                        >
                                            Member role
                                        </Text>
                                        <Text
                                            type={"body-text-3"}
                                            cssClasses={"gray-color"}
                                            fontWeight={"medium-font"}
                                        >
                                            Members have full access to projects
                                            and testing functionalities within
                                            the workspace. They can purchase
                                            credits for the team, publish or
                                            close tests, but do not have access
                                            to team or workspace settings.
                                        </Text>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </>
                )}
            </div>
            <AddMembersModal
                open={addMemberModal.open}
                close={(send = false) => {
                    setAddMemberModal({
                        ...addMemberModal,
                        open: false,
                    });
                    if (send) {
                        getWorkspaceApi(true);
                    }
                }}
                availableSeats={+(maxSeats - numSeats)}
                maxSeats={+maxSeats}
                colorsUsed={colorUsed}
                workspaceid={workspaceid}
                oldMembers={teamMembers}
            />
            <RemoveMemberModal
                open={removeMemberModal.open}
                close={() => {
                    setRemoveMemberModal({
                        ...removeMemberModal,
                        open: false,
                        memberId: null,
                        memberName: null,
                    });
                }}
                confirm={() => {
                    removeMemberFromWorkspace();
                }}
                memberName={removeMemberModal.memberName}
                btnLoading={removeMemberLoading}
            />

            <LeaveWorkspaceModal
                openModal={confirmLeaveWorkspace.open}
                confirm_title="Leave workspace"
                confirm_title_class="text-danger"
                cancel_btn_title={"Cancel"}
                confirm_btn_title={"Leave workspace"}
                close={() => {
                    setConfirmLeaveWorkspace({
                        ...confirmLeaveWorkspace,
                        open: false,
                    });
                }}
                confirm={(signature) => {
                    if (signature) {
                        leaveWorkspaceApi(signature);
                    }
                    //deleteUserAccount();
                }}
                btnLoading={leaveWorkspaceLoading}
                fullName={fullName}
            />

            <DeleteWorkspaceModal
                open={deleteWorkspaceModal.open}
                close={() => {
                    setDeleteWorkspaceModal({
                        ...deleteWorkspaceModal,
                        open: false,
                    });
                }}
                workspaceCount={totalWorkspacesCount}
                type="team"
                confirm={(signature) => {
                    if (signature) {
                        deleteWorkspace(signature);
                        setDeleteWorkspaceModal({
                            ...deleteWorkspaceModal,
                            open: false,
                        });
                    }
                }}
                fullName={fullName}
            />

            <HelperModal
                cssClass={"transfer-ownership-modal-wrap"}
                open={makeAdminModal.open}
                close={() => {
                    closeMakeAdminModal();
                }}
                heading={"Transfer account ownership to another team member."}
                body={
                    <>
                        <div className="prototype-criteria-modal-data">
                            <div className="mt-20 mb-20">
                                <ToastMessage
                                    type={"warning"}
                                    message={
                                        <>
                                            Each workspace can only have one
                                            admin.
                                        </>
                                    }
                                    closable={false}
                                />
                            </div>

                            <Text
                                type={"body-text-3"}
                                fontWeight={"medium-font"}
                                cssClasses={"gray-color"}
                            >
                                Re-assigning ownership to another team member
                                will remove you as admin. You will not be able
                                to manage the workspace or team members.
                            </Text>

                            <Text
                                type="body-text-3"
                                cssClasses={"gray-color mt-32"}
                                fontWeight="medium-font"
                            >
                                Do you want to proceed with assigning the
                                ownership of{" "}
                                <span className="black-color">
                                    {workspaceName}
                                </span>{" "}
                                to{" "}
                                <span className="black-color">
                                    {makeAdminModal.memberName}
                                </span>
                                ?
                            </Text>
                        </div>
                        <div className="confirm-buttons-wrap">
                            <Button
                                cssclass={"toast-close-icon"}
                                type="primary"
                                size="large"
                                label={"Yes, proceed"}
                                onClick={() => {
                                    makeWorkspaceAdmin(makeAdminModal.memberId);
                                    // setMakeAdminLoading(true);
                                    // setTimeout(() => {
                                    //     setMakeAdminLoading(false);
                                    // }, 2000);
                                }}
                                microLoading={makeAdminLoading}
                            />
                            <Button
                                cssclass={"toast-close-icon"}
                                type="secondary"
                                size="large"
                                label={"No, cancel"}
                                onClick={(e) => {
                                    e.preventDefault();
                                    e.stopPropagation();
                                    closeMakeAdminModal();
                                }}
                                state={makeAdminLoading ? "disabled" : ""}
                            />
                        </div>
                    </>
                }
                btnLoading={makeAdminLoading}
            />
        </LayoutResearcher>
    );
};

export default TeamAndSettings;
