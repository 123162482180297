import React, { useEffect, useState, useRef } from "react";
import { Link, useNavigate, useParams, useLocation } from "react-router-dom";
// import { ReactSession } from "react-client-session";
import { ReactSession } from "../../../../lib/secure_reactsession";
import Radiobtn from "./../../../layouts/elements/fields/radiobtn";
import RadiobtnRating from "./../../../layouts/elements/fields/radiobtnRating";
import CheckboxNormal from "./../../../layouts/elements/fields/checkbox_normal";
import { removeItemFromArray } from "../../../../lib/helpers";
import { saveTestResultQuestion } from "../../../../services/test_result";
import { saveFiveSecondResultQuestion } from "../../../../services/test_result";
import TextAreaField from './../../../layouts/elements/fields/textarea';
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { isEmpty } from "lodash";
import InputField from "../../../layouts/elements/fields/input";
import FiveSecondsTestIntroduction from "./five_seconds_test_introduction";
import FiveSecondsTestTask from "./five_seconds_test_task";
import { showToast } from "../../../../lib/helpers";
import { Button } from "../../../themes/userq/Button/Button";
import { Icon } from "../../../themes/userq/Icon/Icon";
import { PageProgressBar } from "../../../themes/userq/ProgressBar/page_progress_bar";
import { TextArea } from "../../../themes/userq/TextArea/TextArea";
import { Text } from "../../../themes/userq/Text/Text";
import { RadioButton } from "../../../themes/userq/RadioButton/RadioButton";
import { TesterQuestionOptions } from "../../../themes/userq/TesterQuestionOptions/TesterQuestionOptions";
import { Input } from "../../../themes/userq/Input/Input";
import { RatingScaleTester } from "../../../themes/userq/RatingScaleTester/RatingScaleTester";
import { TakeTestQuestionImage } from "../../../themes/userq/QuestionImage/TakeTestQuestionImage";
import TaskNotCompleted from "../../elements/TaskNotCompleted";

export default function FiveSecondsTest({
    test,
    result,
    questionCallback,
    callback,
    questionType,
    timeSpent,
}) {
    const location = useLocation();
    const [isActiveQuestion, setActiveQuestion] = useState(null);
    const [initialScreen, setInitialScreen] = useState(true);
    const [taskScreen, setTaskScreen] = useState(false);
    const [questionsScreen, setQuestionsScreen] = useState(false);
    const [totalQuestion, setTotalQuestion] = useState(0);
    const [QuestionArrayList, setQuestionArrayList] = useState([]);
    const [formValues, setFormValues] = useState({
        type: null,
        question_id: null,
        answer: "",
        option_id: [],
        is_optional: 0,
    });
    const [formErrors, setFormErrors] = useState({ error: "", error_class: "" });
    const [questionLoading, setQuestionLoading] = useState(false);
    const [questionArray, setQuestionArray] = useState([]);
    const [questionTimeSpend, setQuestionTimeSpend] = useState(0);
    const [likertActive, setLikertActive] = useState(false);

    const [options, setOptions] = useState([]);
    const isActiveQuestionId = useRef(null);
    const isSkipped = useRef(0);
    const isOptional = useRef(false);
    const queryAttr = "data-rbd-drag-handle-draggable-id";
    const [placeholderProps, setPlaceholderProps] = useState({});
    const skippedCount = useRef(0);
    const logicalySkipped = useRef(0);
    const isTyping = useRef(false);
    const nextButtonActive = useRef(false);
    const likertAttemptedSubQuestion = useRef(0);
    const otherOptionSelected = useRef(false);
    const [isNoneOfTheAboveSelected, setIsNoneOfTheAboveSelected] = useState(false);

    useEffect(() => {

        let Questions = [];

        if (questionType === "Five Seconds Test Questions") {
            if (test && test.five_seconds_test_question.length) {
                setActiveQuestion(1);
                setTotalQuestion(test && test.five_seconds_test_question.length);

                test &&
                    test.five_seconds_test_question.forEach((items) => {
                        Questions.push({
                            data: items,
                            questionData: JSON.parse(items.questions),
                        });
                    });



                setQuestionArrayList(Questions);
                isOptional.current = Questions[0]['questionData']['is_optional'];
                const questionTypeData = Questions.filter(
                    (item, indexItem) => indexItem + 1 === 1
                )[0];
                if (questionTypeData.data.question_type === "singlechoice") {
                    setFormValues({ ...formValues, type: "single", is_optional: questionTypeData.questionData.is_optional });
                } else if (questionTypeData.data.question_type === "multiplechoice") {
                    setFormValues({ ...formValues, type: "multiple", is_optional: questionTypeData.questionData.is_optional });
                } else if (questionTypeData.data.question_type === "ratingscale") {
                    setFormValues({ ...formValues, type: "ratingscale", is_optional: questionTypeData.questionData.is_optional });
                } else if (questionTypeData.data.question_type === "likertscale") {
                    setFormValues({ ...formValues, type: "likertscale", is_optional: questionTypeData.questionData.is_optional });
                } else if (questionTypeData.data.question_type === "rankingscale") {
                    let options = [];
                    questionTypeData.data.fivesecondstestoptions.forEach((option) => {
                        options.push(option.id);
                    });

                    setFormValues({ ...formValues, type: "rankingscale", question_id: questionTypeData.data.id, is_optional: questionTypeData.questionData.is_optional, answer: "", option_id: options, });
                    // setFormValues({ ...formValues, type: "rankingscale", is_optional: questionTypeData.questionData.is_optional });
                    //nextButtonActive.current=true;
                } else {
                    setFormValues({ ...formValues, type: "free", is_optional: questionTypeData.questionData.is_optional });
                }
            } else {

                //saveQuestionData([], true);
            }

        }

        window.scrollTo(0, 0);

        function tickTime() {
            setQuestionTimeSpend((prevSeconds) => prevSeconds + 1);
        }
        let timeData = setInterval(() => tickTime(), 1000);

        return () => clearInterval(timeData);
    }, []);

    const handleSubmitQuestion = (e) => {
        e.preventDefault();
        setFormErrors({ error: "", error_class: "" });
        if (isActiveQuestionId.current == null) {
            isActiveQuestionId.current = QuestionArrayList[0]['data']['id']
        }
        if (isSkipped.current == 0) {
            if (formValues.is_optional == 1 && ((formValues.answer === "" && formValues.type !== "multiple" && formValues.type !== "rankingscale" && formValues.type !== "likertscale") || (formValues.type === "multiple" || formValues.type === "rankingscale") && formValues.option_id.length === 0) || (formValues.type === "likertscale" && formValues.option_id.length === 0)) {
                if (skippedCount.current < 2) {

                    if (formValues.is_optional == 0 && formValues.type == "likertscale") {
                        isSkipped.current = 0;
                    } else {
                        isSkipped.current = 1;
                        setFormValues({
                            ...formValues,
                            question_id: isActiveQuestionId.current,
                        });
                        skippedCount.current = skippedCount.current + 1;
                    }
                } else {
                    showToast("You can skip maximum of 2 questions.", "error");
                    return false;
                }
            }
        }

        if (isSkipped.current == 0) {
            if (formValues.answer === "" && formValues.type !== "multiple" && formValues.type !== "rankingscale" && formValues.type !== "likertscale") {
                setFormErrors({ error: "Required field", error_class: "input_error" });

                if ((formValues.type === "single") || (formValues.type === "ratingscale")) {
                    showToast("You need to select one answer before moving to the next question.", "error");
                }
                return false;
            }

            /*if ((formValues.type === "multiple" || formValues.type === "rankingscale") && formValues.option_id.length === 0) {
                setFormErrors({ error: "Required field", error_class: "input_error" });
                showToast("You need to select one answer before moving to the next question.", "error");
                return false;
            }*/

            if ((formValues.type === "rankingscale") && formValues.option_id.length === 0) {
                setFormErrors({ error: "Required field", error_class: "input_error" });
                showToast("You need to select one answer before moving to the next question.", "error");
                return false;
            }
            if ((formValues.type === "rankingscale") && !nextButtonActive.current) {
                return false;
            }
            if ((formValues.type === "multiple")) {

                var mc_question = QuestionArrayList[isActiveQuestion - 1];

                let mc_error = false;

                let mc_error_message = "You need to select at least 1 option before moving to the next question.";

                let other_form_error = false;

                let other_selected = false;

                formValues.option_id.forEach(function (option_id) {

                    if (QuestionArrayList[isActiveQuestion - 1].data.options) {

                        QuestionArrayList[isActiveQuestion - 1].data.options.forEach(function (option) {
                            if (option.id == option_id && ((test.language == "en" && option.option_value == "Other") || (option.option_value == "آخر" && test.language == "ar"))) {
                                other_selected = true;
                            }
                        });
                    }
                });
                if (isNoneOfTheAboveSelected) {
                    mc_error = false;
                } else if (other_selected && !formValues.other_option_value) {

                    other_form_error = true;

                    mc_error = true;
                } else {

                    if (mc_question.questionData) {

                        var mc_question_data = mc_question.questionData;

                        if (mc_question_data) {

                            if (mc_question_data.exact) {

                                if (!(formValues.option_id.length == mc_question_data.exact)) {

                                    mc_error = true;

                                    mc_error_message = "You need to select " + mc_question_data.exact + " options.";
                                }

                            } else if (mc_question_data.min && mc_question_data.max) {

                                if (!(formValues.option_id.length >= mc_question_data.min && formValues.option_id.length <= mc_question_data.max)) {

                                    mc_error = true;

                                    if (formValues.option_id.length < mc_question_data.min) {
                                        mc_error_message = "You need to select at least " + mc_question_data.min + " options before moving to the next question.";
                                    } else {
                                        mc_error_message = "You can select up to " + mc_question_data.max + " options.";
                                    }
                                }

                            } else if (mc_question_data.min) {

                                if (!(formValues.option_id.length >= mc_question_data.min)) {

                                    mc_error = true;

                                    mc_error_message = "You need to select at least " + mc_question_data.min + " options before moving to the next question.";
                                }

                            } else if (mc_question_data.max) {

                                if (!(formValues.option_id.length <= mc_question_data.max)) {

                                    mc_error = true;

                                    mc_error_message = "You can select up to " + mc_question_data.max + " options.";
                                }
                            } else {
                                if (formValues.option_id.length === 0) {

                                    mc_error = true;

                                    mc_error_message = "You need to select one answer before moving to the next question.";

                                }
                            }

                        } else {

                            if (formValues.option_id.length === 0) {

                                mc_error = true;

                                mc_error_message = "You need to select at least 1 option before moving to the next question.";

                            }
                        }
                    } else {

                        if (formValues.option_id.length === 0) {

                            mc_error = true;

                            mc_error_message = "You need to select at least 1 option before moving to the next question.";

                        }
                    }
                }

                if (mc_error) {

                    if (other_form_error) {
                        //showToast("", "error");
                        setFormErrors({ error: 'Required field', error_class: 'input_error' });
                    } else {
                        showToast(mc_error_message, "error");
                    }
                    return false;
                }
            }
            if (formValues.type === "likertscale" && formValues.option_id.length === 0) {
                setFormErrors({ error: "Required field", error_class: "input_error" });
                showToast("You need to answer before moving to the next question.", "error");
                return false;
            }
            else {
                if (formValues.option_id.length < formValues.totalSubQuestions) {
                    setFormErrors({ error: "Required field", error_class: "input_error" });
                    showToast("You need to answer for each subquestion.", "error");
                    return false;
                }
            }


            var other_selected = false;


            if ((formValues.type === "single")) {
                if (QuestionArrayList[isActiveQuestion - 1].data.fivesecondstestoptions && formValues.answer) {

                    QuestionArrayList[isActiveQuestion - 1].data.fivesecondstestoptions.forEach(function (option) {
                        if (option.id == formValues.answer && ((test.language == "en" && option.option_value == "Other") || (option.option_value == "آخر" && test.language == "ar"))) {
                            other_selected = true;
                        }
                    });
                }
            }

            if ((formValues.type === "single") && other_selected && formValues.other_option_value == "") {
                setFormErrors({ error: "Required field", error_class: "input_error" });
                return false;
            }
        }

        // location scroll top
        if (isActiveQuestion < totalQuestion) {
            window.scrollTo(0, 0);
        }
        // check & activate next question
        formValues["question_time_spent"] = questionTimeSpend;
        formValues["is_skipped"] = isSkipped.current;
        if (isActiveQuestionId.current && logicalySkipped.current == "0") {
            formValues["question_id"] = isActiveQuestionId.current;
        }
        setQuestionTimeSpend(0);
        setQuestionArray([...questionArray, formValues]); // set data in array & make form value blank
        if (isActiveQuestion < totalQuestion) {
            var nextquestioncount = 1;
            if (formValues.type == "single") {
                const currentquestionData = QuestionArrayList.filter(
                    (item, indexItem) => indexItem + 1 === isActiveQuestion
                )[0];

                if (formValues.answer != "") {
                    const chosenoption = currentquestionData.data.fivesecondstestoptions.filter(
                        (item) => item.id == formValues.answer
                    )[0];



                    if (chosenoption.jump_to_question == "End Survey") {
                        saveQuestionData([...questionArray, formValues], false);
                        return false;
                    }
                    else if (chosenoption.jump_to_question != null) {
                        const jumpToQuestion = parseInt(chosenoption.jump_to_question);
                        nextquestioncount = jumpToQuestion - isActiveQuestionId.current;
                        logicalySkipped.current = 1;
                    }
                }
            }
            const questionTypeData = QuestionArrayList.filter(
                (item, indexItem) => indexItem + 1 === isActiveQuestion + nextquestioncount
            )[0];

            if (questionTypeData.data.question_type === "singlechoice") {
                var types = "single";
            } else if (questionTypeData.data.question_type === "multiplechoice") {
                var types = "multiple";
            } else if (questionTypeData.data.question_type === "ratingscale") {
                var types = "ratingscale";
            } else if (questionTypeData.data.question_type === "likertscale") {
                var types = "likertscale";
            } else if (questionTypeData.data.question_type === "rankingscale") {
                var types = "rankingscale";
            } else {
                var types = "free";
            }

            let options = [];
            if (types == "rankingscale") {
                isActiveQuestionId.current = questionTypeData.data.id;
                //nextButtonActive.current = true;

                questionTypeData.data.fivesecondstestoptions.forEach((option) => {
                    options.push(option.id);
                });

                setFormValues({
                    type: types,
                    question_id: questionTypeData.data.id,
                    is_optional: questionTypeData.questionData.is_optional,
                    answer: "",
                    option_id: options,
                });
            } else {
                setFormValues({
                    type: types,
                    question_id: null,
                    is_optional: questionTypeData.questionData.is_optional,
                    answer: "",
                    option_id: [],
                });
            }

            setActiveQuestion(isActiveQuestion + nextquestioncount);
            logicalySkipped.current = 0;
            otherOptionSelected.current = false;
            likertAttemptedSubQuestion.current = 0;
            setOptions([]);
            isSkipped.current = 0;
            if (types !== "rankingscale") {
                isActiveQuestionId.current = QuestionArrayList[isActiveQuestion]['data']['id'];
                nextButtonActive.current = false;
            } else {
                nextButtonActive.current = false;
            }
            // isActiveQuestionId.current = QuestionArrayList[isActiveQuestion]['data']['id'];
            isOptional.current = QuestionArrayList[isActiveQuestion]['questionData']['is_optional'];
        } else {
            saveQuestionData([...questionArray, formValues], false);
        }
    };
    const updateNextBtnStateThruOptions = (items, formValues, isNoneOfTheAbove) => {

        let next_btn_active = false;

        let other_selected = false;

        formValues.option_id.forEach(function (option_id) {

            if (QuestionArrayList[isActiveQuestion - 1].data.fivesecondstestoptions) {

                QuestionArrayList[isActiveQuestion - 1].data.fivesecondstestoptions.forEach(function (option) {
                    if (option.id == option_id && ((test.language == "en" && option.option_value == "Other") || (option.option_value == "آخر" && test.language == "ar"))) {
                        other_selected = true;
                    }
                });
            }
        });

        if (isNoneOfTheAbove) {
            next_btn_active = true;
        }
        else if (other_selected && !formValues.other_option_value) {
            next_btn_active = false;
        } else {
            if (items.questionData) {

                if (items.questionData.exact) {

                    if (formValues.option_id.length == items.questionData.exact) {
                        next_btn_active = true;
                    } else {
                        next_btn_active = false;
                    }

                } else if (items.questionData.min && items.questionData.max) {

                    if (formValues.option_id.length >= items.questionData.min && formValues.option_id.length <= items.questionData.max) {
                        next_btn_active = true;
                    } else {
                        next_btn_active = false;
                    }

                } else if (items.questionData.min) {

                    if (formValues.option_id.length >= items.questionData.min) {
                        next_btn_active = true;
                    } else {
                        next_btn_active = false;
                    }

                } else if (items.questionData.max) {

                    if (formValues.option_id.length <= items.questionData.max) {
                        next_btn_active = true;
                    } else {
                        next_btn_active = false;
                    }

                } else {
                    if (formValues.option_id.length > 0) {
                        next_btn_active = true;
                    } else {
                        next_btn_active = false;
                    }
                }
            } else {
                if (formValues.option_id.length > 0) {
                    next_btn_active = true;
                }
            }
        }
        nextButtonActive.current = next_btn_active;

    }
    const updateNextBtnStateThruOther = (items, formValues) => {

        let next_btn_active = false;



        if (isNoneOfTheAboveSelected) {
            next_btn_active = true;
        }
        else if (!formValues.other_option_value) {
            next_btn_active = false;
        } else {
            if (items.questionData) {

                if (items.questionData.exact) {

                    if (formValues.option_id.length == items.questionData.exact) {
                        next_btn_active = true;
                    } else {
                        next_btn_active = false;
                    }

                } else if (items.questionData.min && items.questionData.max) {

                    if (formValues.option_id.length >= items.questionData.min && formValues.option_id.length <= items.questionData.max) {
                        next_btn_active = true;
                    } else {
                        next_btn_active = false;
                    }

                } else if (items.questionData.min) {

                    if (formValues.option_id.length >= items.questionData.min) {
                        next_btn_active = true;
                    } else {
                        next_btn_active = false;
                    }

                } else if (items.questionData.max) {

                    if (formValues.option_id.length <= items.questionData.max) {
                        next_btn_active = true;
                    } else {
                        next_btn_active = false;
                    }

                } else {
                    if (formValues.option_id.length > 0) {
                        next_btn_active = true;
                    } else {
                        next_btn_active = false;
                    }
                }
            } else {
                if (formValues.option_id.length > 0) {
                    next_btn_active = true;
                }
            }
        }
        nextButtonActive.current = next_btn_active;

    }
    const saveQuestionData = (formData, shouldNavigate) => {
        if (location.pathname.substr(1, 1) === "t") {
            let data = Object.assign({}, { questions: formData });
            data["test_id"] = result && result.test_id;
            data["result_id"] = result && result.id;
            data["question_type"] = questionType;
            data["guest_token"] = ReactSession.get("guest_token");
            data["time_spent"] = timeSpent;

            // cannot hit api multiple time if already ruuning api request
            if (questionLoading) {
                return false;
            }

            if (!shouldNavigate) {
                setQuestionLoading(true);
            }

            if (shouldNavigate) {
                questionCallback(formData, questionType, false);
            }

            saveFiveSecondResultQuestion(data, ReactSession.get("token")).then(
                (response) => {
                    if (!shouldNavigate) {
                        setQuestionLoading(false);
                    }
                    if (response.success) {
                        // questionCallback(formData, questionType,response.assignCredits);
                        callback();
                    } else {
                        showToast(response.message, "error");
                    }
                }
            );
        } else {
            // questionCallback(formData, questionType,false);
            callback();
        }
    };
    const movetonextlikertsubquestion = (subquestionindex) => {
        setLikertActive(true);
        var nextquestionid = "subquestion-" + parseInt(subquestionindex + 1, 10);
        const item = document.getElementById(nextquestionid);
        item.scrollIntoView({
            behavior: "smooth",
            block: "center",
        });
    }

    const handleDragStart = (event) => {
        const draggedDOM = getDraggedDom(event.draggableId);

        if (!draggedDOM) {
            return;
        }

        const { clientHeight, clientWidth } = draggedDOM;
        const sourceIndex = event.source.index;
        var clientY =
            parseFloat(window.getComputedStyle(draggedDOM.parentNode).paddingTop) +
            [...draggedDOM.parentNode.children]
                .slice(0, sourceIndex)
                .reduce((total, curr) => {
                    const style = curr.currentStyle || window.getComputedStyle(curr);
                    const marginBottom = parseFloat(style.marginBottom);
                    return total + curr.clientHeight + marginBottom;
                }, 0);

        setPlaceholderProps({
            clientHeight,
            clientWidth,
            clientY,
            clientX: parseFloat(
                window.getComputedStyle(draggedDOM.parentNode).paddingLeft
            ),
            background: '#CCCCCC',
            border: 'none'
        });
    };

    const handleDragUpdate = (event) => {
        if (!event.destination) {
            return;
        }

        const draggedDOM = getDraggedDom(event.draggableId);

        if (!draggedDOM) {
            return;
        }

        const { clientHeight, clientWidth } = draggedDOM;
        const destinationIndex = event.destination.index;

        const sourceIndex = event.source.index;

        const childrenArray = [...draggedDOM.parentNode.children];
        const movedItem = childrenArray[sourceIndex];
        childrenArray.splice(sourceIndex, 1);

        const updatedArray = [
            ...childrenArray.slice(0, destinationIndex),
            movedItem,
            ...childrenArray.slice(destinationIndex + 1),
        ];


        var clientY =
            parseFloat(window.getComputedStyle(draggedDOM.parentNode).paddingTop) +
            updatedArray.slice(0, destinationIndex).reduce((total, curr) => {
                const style = curr.currentStyle || window.getComputedStyle(curr);
                const marginBottom = parseFloat(style.marginBottom);
                return total + curr.clientHeight + marginBottom;
            }, 0);

        setPlaceholderProps({
            clientHeight,
            clientWidth,
            clientY,
            clientX: parseFloat(
                window.getComputedStyle(draggedDOM.parentNode).paddingLeft
            ),
            background: 'none',
            border: '1px dashed'
        });
    };



    const getDraggedDom = (draggableId) => {
        const domQuery = `[${queryAttr}='${draggableId}']`;
        const draggedDOM = document.querySelector(domQuery);

        return draggedDOM;
    };

    const runFunction = (e) => {

        if (
            e.target.type == 'text' || e.target.type == 'textarea'
        ) {
            // e.preventDefault();
            return;
        }
        else {
            // e.preventDefault();
            isTyping.current = false;
            return;
        }
    }
    const taskHasError = () => {
        var error = false;

        if (test) {
            if (test.five_seconds_test_task && !test.five_seconds_test_task.task) {
                error = true;
            } else if (test.five_seconds_test_task && !test.five_seconds_test_task.task_file) {
                error = true;
            } else if (QuestionArrayList.length == 0) {
                error = true;
            }
        }
        return error;
    }
    return (
        <>
            {initialScreen &&
                <FiveSecondsTestIntroduction goToFirstTask={
                    () => {
                        setInitialScreen(false);
                        setTaskScreen(true);
                    }
                }
                    testlanguage={test.language}
                />
            }
            {taskScreen &&

                <>
                    {taskHasError()
                        ?
                        <TaskNotCompleted to={"/r/test/" + test.id + "/five-seconds-test"} />
                        :
                        <FiveSecondsTestTask goToFirstQuestion={
                            () => {
                                setTaskScreen(false);
                                setQuestionsScreen(true);
                            }
                        }
                            test={test}
                        />
                    }
                </>
            }
            {questionsScreen &&
                <div className="taking-test-page-wrap" onClick={(e) => runFunction(e)}>
                    <div className="container">
                        <form onSubmit={(e) => handleSubmitQuestion(e)}>
                            <div className="taking-test-page-height">
                                {QuestionArrayList &&
                                    QuestionArrayList.map((items, index) => {
                                        return (
                                            <div key={index} >
                                                {isActiveQuestion === index + 1 && (
                                                    <div key={index} >
                                                        {items.questionData.questionType === "free" && (
                                                            <div className="free-take-test-wrap medium-box-wrapper">
                                                                <div className="taking-test-progressbar">
                                                                    {/* {logicAdded==false && */}
                                                                    <PageProgressBar language={test.language} current_step={isActiveQuestion} total_steps={QuestionArrayList.length} variant={"take-test"} label={test.language == "en" ? "Question" : "سؤال"} />
                                                                    {/* } */}
                                                                </div>

                                                                <div className="take-test-heading-wrap">
                                                                    <Text type="h2">
                                                                        {items.questionData.question}&nbsp;
                                                                        {items.questionData.is_optional == true &&
                                                                            <span className="optional-span">
                                                                                ({test.language == "en" ? "Optional" : "اختياري"})
                                                                            </span>
                                                                        }
                                                                    </Text>
                                                                </div>

                                                                <TakeTestQuestionImage type={'question'} url={items.questionData && items.questionData.image_url} />

                                                                <div className="test-form-hold mt-40" onClick={() => {
                                                                    isTyping.current = true;
                                                                }}
                                                                >
                                                                    <TextArea
                                                                        autosize={true}
                                                                        value={formValues.answer == null
                                                                            ? ""
                                                                            : formValues.answer}
                                                                        onChange={(e) => {
                                                                            isActiveQuestionId.current = items.data.id;
                                                                            if (e.target.value != " ") {
                                                                                setFormErrors(
                                                                                    { error: "", error_class: "" }
                                                                                );
                                                                                setFormValues({
                                                                                    ...formValues,
                                                                                    question_id: items.data.id,
                                                                                    answer: e.target.value,
                                                                                });
                                                                                if (!(e.target.value == "" || e.target.value == null)) {
                                                                                    nextButtonActive.current = true;
                                                                                } else {
                                                                                    nextButtonActive.current = false;
                                                                                }
                                                                            }
                                                                        }}
                                                                        maxLength="300"
                                                                        label={test.language == "en" ? "Type your answer here" : "جاوب هنا"}
                                                                        language={test.language}
                                                                        onClear={() => {
                                                                            setFormValues({
                                                                                ...formValues,
                                                                                answer: "",
                                                                            });
                                                                        }}
                                                                        wrapClass="tester-text-area"
                                                                        error={formErrors.error}
                                                                    />
                                                                </div>
                                                            </div>
                                                        )}

                                                        {items.questionData.questionType ===
                                                            "singlechoice" && (
                                                                <div className="single-choice-take-test medium-box-wrapper">
                                                                    <div className="taking-test-progressbar">
                                                                        {/* {logicAdded==false && */}
                                                                        <PageProgressBar language={test.language} current_step={isActiveQuestion} total_steps={QuestionArrayList.length} variant={"take-test"} label={test.language == "en" ? "Question" : "سؤال"} />
                                                                        {/* } */}
                                                                    </div>

                                                                    <div className="take-test-heading-wrap">
                                                                        <Text type="h2">
                                                                            {items.questionData.question}&nbsp;
                                                                            {items.questionData.is_optional == true &&
                                                                                <span className="optional-span">
                                                                                    ({test.language == "en" ? "Optional" : "اختياري"})
                                                                                </span>
                                                                            }
                                                                        </Text>
                                                                    </div>

                                                                    <div className="select-option-text-guide mt-24">
                                                                        <Text type="body-text-1" fontWeight="medium-font">{test.language == "en" ? "Select 1 of the following" : "حدد خيار واحد"}</Text>
                                                                    </div>

                                                                    <TakeTestQuestionImage type={'question'} url={items.questionData && items.questionData.image_url} />

                                                                    <div className="test-form-hold mt-32">
                                                                        <div className={`${test.language == "en" ? "singlechoice-option-wrap" : "singlechoice-option-wrap singlechoice-option-arabic-wrap"}`}>
                                                                            {items.data.fivesecondstestoptions &&
                                                                                items.data.fivesecondstestoptions.map(
                                                                                    (optionData) => {
                                                                                        return (
                                                                                            <div key={optionData.id}>
                                                                                                <TesterQuestionOptions
                                                                                                    id={`option-select-${optionData.id}`}
                                                                                                    type="singlechoice"
                                                                                                    value={optionData.id}
                                                                                                    optionImage={optionData.image && optionData.image.image_path}
                                                                                                    label={optionData.option_value} isChecked={
                                                                                                        formValues.answer ===
                                                                                                            optionData.id
                                                                                                            ? true
                                                                                                            : false
                                                                                                    }
                                                                                                    onChange={(e) => {
                                                                                                        isActiveQuestionId.current = items.data.id;
                                                                                                        nextButtonActive.current = true;
                                                                                                        if ((test.language == "en" && optionData.option_value == "Other") || (optionData.option_value == "آخر" && test.language == "ar")) {
                                                                                                            setFormValues({
                                                                                                                ...formValues,
                                                                                                                question_id: items.data.id,
                                                                                                                answer: parseInt(
                                                                                                                    e.target.value
                                                                                                                ),
                                                                                                                other_option_value: "",
                                                                                                            });
                                                                                                            otherOptionSelected.current = true;
                                                                                                        } else {
                                                                                                            setFormValues({
                                                                                                                ...formValues,
                                                                                                                question_id: items.data.id,
                                                                                                                answer: parseInt(
                                                                                                                    e.target.value
                                                                                                                ),
                                                                                                            });
                                                                                                            otherOptionSelected.current = false;
                                                                                                            setFormErrors(
                                                                                                                { error: "", error_class: "" }
                                                                                                            );
                                                                                                        }
                                                                                                        const item = document.getElementById("buttons");
                                                                                                        item.scrollIntoView({
                                                                                                            behavior: "smooth",
                                                                                                            block: "center",
                                                                                                        });
                                                                                                    }} />

                                                                                                {formValues.answer === optionData.id && ((optionData.option_value == "Other" && test.language == "en") || (optionData.option_value == "آخر" && test.language == "ar")) &&
                                                                                                    <div className="other-input-field">
                                                                                                        <Input
                                                                                                            label={test.language == "en" ? "Specify other" : "تحديد آخر"}
                                                                                                            maxLength="100"
                                                                                                            language={test.language}
                                                                                                            value={formValues.other_option_value}
                                                                                                            id="othervalue"
                                                                                                            rtl={test.language == "en" ? false : true}
                                                                                                            readOnly={false}
                                                                                                            onChange={(e) => {
                                                                                                                if (e.target.value != " ") {
                                                                                                                    setFormValues({
                                                                                                                        ...formValues,
                                                                                                                        other_option_value: e.target.value,
                                                                                                                    });
                                                                                                                    setFormErrors(
                                                                                                                        { error: "", error_class: "" }
                                                                                                                    );
                                                                                                                }
                                                                                                            }}
                                                                                                            onClear={() => {
                                                                                                                setFormValues({
                                                                                                                    ...formValues,
                                                                                                                    other_option_value: "",
                                                                                                                });
                                                                                                            }}
                                                                                                            error={formErrors.error}
                                                                                                            required={true}
                                                                                                        />
                                                                                                    </div>
                                                                                                }
                                                                                            </div>
                                                                                        );
                                                                                    }
                                                                                )}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            )}

                                                        {items.questionData.questionType ===
                                                            "multiplechoice" && (
                                                                <div className="single-choice-take-test medium-box-wrapper  multiple-choice-take-test">
                                                                    <div className="taking-test-progressbar">
                                                                        {/* {logicAdded==false && */}
                                                                        <PageProgressBar language={test.language} current_step={isActiveQuestion} total_steps={QuestionArrayList.length} variant={"take-test"} label={test.language == "en" ? "Question" : "سؤال"} />
                                                                        {/* } */}
                                                                    </div>

                                                                    <div className="take-test-heading-wrap">
                                                                        <Text type="h2">
                                                                            {items.questionData.question}&nbsp;
                                                                            {items.questionData.is_optional == true &&
                                                                                <span className="optional-span">
                                                                                    ({test.language == "en" ? "Optional" : "اختياري"})
                                                                                </span>
                                                                            }
                                                                        </Text>
                                                                    </div>
                                                                    <div className={`select-option-text-guide mt-24 ${isNoneOfTheAboveSelected ? "disable-sub-mc-intruction-heading" : ""}`}>
                                                                        <Text type="body-text-1" fontWeight="medium-font">
                                                                            {(() => {
                                                                                let sub_heading =
                                                                                    test.language == "en"
                                                                                        ? "Select 1 or more of the following"
                                                                                        : "حدد خيار واحد أو أكثر من الخيارات أدناه";

                                                                                if (items.questionData) {
                                                                                    if (items.questionData.exact) {
                                                                                        sub_heading =
                                                                                            test.language == "en"
                                                                                                ? "Select " +
                                                                                                items.questionData.exact +
                                                                                                " of the following"
                                                                                                : " اختر " + items.questionData.exact + "  مما يلي";
                                                                                    } else if (
                                                                                        items.questionData.min &&
                                                                                        items.questionData.max
                                                                                    ) {
                                                                                        sub_heading =
                                                                                            test.language == "en"
                                                                                                ? "Select between " +
                                                                                                items.questionData.min +
                                                                                                " and " +
                                                                                                items.questionData.max +
                                                                                                " of the following"
                                                                                                : " اختر بين " + items.questionData.max + " و " +
                                                                                                items.questionData.min + " مما يلي"
                                                                                            ;
                                                                                    } else if (items.questionData.min) {
                                                                                        sub_heading =
                                                                                            test.language == "en"
                                                                                                ? "Select " +
                                                                                                items.questionData.min +
                                                                                                " or more of the following"
                                                                                                : " أو أكثر مما يلي " +
                                                                                                items.questionData.min + " اختر";
                                                                                    } else if (items.questionData.max) {
                                                                                        sub_heading =
                                                                                            test.language == "en"
                                                                                                ? "Select up to " +
                                                                                                items.questionData.max +
                                                                                                " of the following"
                                                                                                : " اختر ما يصل إلى " +
                                                                                                items.questionData.max + " مما يلي "
                                                                                    }
                                                                                }
                                                                                return sub_heading;
                                                                            })()}
                                                                        </Text>
                                                                    </div>

                                                                    <TakeTestQuestionImage type={'question'} url={items.questionData && items.questionData.image_url} />
                                                                    <div className="test-form-hold mt-32">

                                                                        <div className={`${test.language == "en" ? "singlechoice-option-wrap" : "singlechoice-option-wrap singlechoice-option-arabic-wrap"}`}>
                                                                            {items.data.fivesecondstestoptions &&
                                                                                items.data.fivesecondstestoptions.map(
                                                                                    (optionData) => {
                                                                                        return (
                                                                                            <div key={optionData.id}>
                                                                                                <TesterQuestionOptions
                                                                                                    id={`custom-checks-${optionData.id}`}
                                                                                                    type="multiplechoice"
                                                                                                    value={optionData.id}
                                                                                                    optionImage={optionData.image && optionData.image.image_path}
                                                                                                    label={optionData.option_value}
                                                                                                    isChecked={
                                                                                                        formValues.option_id.includes(
                                                                                                            optionData.id
                                                                                                        )
                                                                                                            ? true
                                                                                                            : false
                                                                                                    }
                                                                                                    onChange={(e) => {
                                                                                                        isActiveQuestionId.current = items.data.id;

                                                                                                        let none_of_the_above_selected = false;

                                                                                                        let other_selected = false;

                                                                                                        if (e.currentTarget.checked) {

                                                                                                            if ((test.language == "en" && optionData.option_value == "None of the above") || (optionData.option_value == "لا شيء مما بالأعلى" && test.language == "ar")) {
                                                                                                                formValues.option_id = [];
                                                                                                                formValues.option_id.push(
                                                                                                                    parseInt(e.target.value)
                                                                                                                );
                                                                                                                formValues.other_option_value = "";
                                                                                                                setFormValues({
                                                                                                                    ...formValues,
                                                                                                                    question_id:
                                                                                                                        items.data.id,
                                                                                                                    option_id:
                                                                                                                        formValues.option_id,
                                                                                                                    is_none_above: parseInt(e.target.value),
                                                                                                                    other_option_value: "",
                                                                                                                });
                                                                                                                otherOptionSelected.current = false;

                                                                                                                setIsNoneOfTheAboveSelected(true);

                                                                                                                none_of_the_above_selected = true;

                                                                                                            } else {

                                                                                                                setIsNoneOfTheAboveSelected(false);

                                                                                                                if (formValues.option_id.includes(formValues.is_none_above)) {
                                                                                                                    formValues.option_id = formValues.option_id.filter(item => item !== formValues.is_none_above)
                                                                                                                }

                                                                                                                formValues.option_id.push(
                                                                                                                    parseInt(e.target.value)
                                                                                                                );
                                                                                                                if ((test.language == "en" && optionData.option_value == "Other") || (optionData.option_value == "آخر" && test.language == "ar")) {

                                                                                                                    formValues.other_option_value = "";
                                                                                                                    setFormValues({
                                                                                                                        ...formValues,
                                                                                                                        question_id:
                                                                                                                            items.data.id,
                                                                                                                        option_id:
                                                                                                                            formValues.option_id,
                                                                                                                        other_option_value: "",
                                                                                                                    });
                                                                                                                    otherOptionSelected.current = true;
                                                                                                                } else {
                                                                                                                    setFormValues({
                                                                                                                        ...formValues,
                                                                                                                        question_id:
                                                                                                                            items.data.id,
                                                                                                                        option_id:
                                                                                                                            formValues.option_id,
                                                                                                                    });
                                                                                                                    otherOptionSelected.current = false;
                                                                                                                }
                                                                                                            }
                                                                                                        } else {

                                                                                                            if ((test.language == "en" && optionData.option_value == "None of the above") || (optionData.option_value == "لا شيء مما بالأعلى" && test.language == "ar")) {

                                                                                                                setIsNoneOfTheAboveSelected(false);
                                                                                                            }
                                                                                                            if ((test.language == "en" && optionData.option_value == "Other") || (optionData.option_value == "آخر" && test.language == "ar")) {
                                                                                                                otherOptionSelected.current = false;
                                                                                                                setFormErrors(
                                                                                                                    { error: "", error_class: "" }
                                                                                                                );
                                                                                                            }
                                                                                                            formValues.option_id =
                                                                                                                removeItemFromArray(
                                                                                                                    formValues.option_id,
                                                                                                                    optionData.id
                                                                                                                );
                                                                                                            setFormValues({
                                                                                                                ...formValues,
                                                                                                                question_id:
                                                                                                                    items.data.id,
                                                                                                                option_id:
                                                                                                                    formValues.option_id,
                                                                                                            });
                                                                                                        }
                                                                                                        updateNextBtnStateThruOptions(items, formValues, none_of_the_above_selected);
                                                                                                    }} />

                                                                                                {formValues.option_id.includes(optionData.id) && ((optionData.option_value == "Other" && test.language == "en") || (optionData.option_value == "آخر" && test.language == "ar")) &&
                                                                                                    <div className="other-input-field">
                                                                                                        <Input
                                                                                                            label={test.language == "en" ? "Specify other" : "تحديد آخر"}
                                                                                                            maxLength="100"
                                                                                                            language={test.language}
                                                                                                            value={formValues.other_option_value}
                                                                                                            id="othervalue"
                                                                                                            rtl={test.language == "en" ? false : true}
                                                                                                            readOnly={false}
                                                                                                            onChange={(e) => {
                                                                                                                formValues.other_option_value = e.target.value;
                                                                                                                setFormValues({
                                                                                                                    ...formValues,
                                                                                                                    other_option_value: e.target.value,
                                                                                                                });
                                                                                                                setFormErrors(
                                                                                                                    { error: "", error_class: "" }
                                                                                                                );
                                                                                                                updateNextBtnStateThruOther(items, formValues);
                                                                                                            }}
                                                                                                            onClear={() => {
                                                                                                                setFormValues({
                                                                                                                    ...formValues,
                                                                                                                    other_option_value: "",
                                                                                                                });
                                                                                                            }}
                                                                                                            error={formErrors.error}
                                                                                                            required={true}
                                                                                                        />
                                                                                                    </div>
                                                                                                }
                                                                                            </div>
                                                                                        );
                                                                                    }
                                                                                )}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            )}

                                                        {/* Akshay: Added Rating Scale */}
                                                        {(items.questionData.questionType === 'ratingscale') &&
                                                            <div className="single-choice-take-test medium-box-wrapper rating-scale-take-test">
                                                                <div className="taking-test-progressbar">
                                                                    <PageProgressBar language={test.language} current_step={isActiveQuestion} total_steps={QuestionArrayList.length} variant={"take-test"} label={test.language == "en" ? "Question" : "سؤال"} />
                                                                </div>

                                                                <div className="take-test-heading-wrap">
                                                                    <Text type="h2">
                                                                        {items.questionData.question}
                                                                        &nbsp;
                                                                        {items.questionData.is_optional == true &&
                                                                            <span className="optional-span">
                                                                                ({test.language == "en" ? "Optional" : "اختياري"})
                                                                            </span>
                                                                        }
                                                                    </Text>
                                                                </div>

                                                                <TakeTestQuestionImage type={'question'} url={items.questionData && items.questionData.image_url} />

                                                                <div className="test-form-hold mt-32 rating-options">
                                                                    <div className={`${test.language == "en" ? "ratingscale-option-wrap" : "ratingscale-option-wrap ratingscale-option-arabic-wrap"}`}>

                                                                        <RatingScaleTester
                                                                            type={items.questionData.scale_type == "Emotions" ? "emoticons" : (items.questionData.scale_type == "Numeric" ? "numbers" : "stars")}
                                                                            scale={items.questionData.scale_length == "3 points" ? 3 : (items.questionData.scale_length == "5 points" ? 5 : (items.questionData.scale_length == "7 points" ? 7 : 10))}
                                                                            value={formValues.answer}
                                                                            labels={items.questionData.labels}
                                                                            onChange={(value) => {
                                                                                setFormValues({ ...formValues, question_id: items.data.id, answer: value })
                                                                                nextButtonActive.current = true;
                                                                            }}
                                                                        />

                                                                    </div>
                                                                </div>
                                                            </div>
                                                        }

                                                        <div className="likert-scale-taking-test-view">

                                                            {items.questionData.questionType == "likertscale" && (
                                                                <>

                                                                    <div className="taking-test-progressbar">
                                                                        {/* {logicAdded==false && */}
                                                                        <PageProgressBar language={test.language} current_step={isActiveQuestion} total_steps={QuestionArrayList.length} variant={"take-test"} label={test.language == "en" ? "Question" : "سؤال"} />
                                                                        {/* } */}
                                                                    </div>

                                                                    <div className="take-test-heading-wrap">
                                                                        <Text type="h2">
                                                                            {items.questionData.question}&nbsp;
                                                                            {items.questionData.is_optional == true &&
                                                                                <span className="optional-span">
                                                                                    ({test.language == "en" ? "Optional" : "اختياري"})
                                                                                </span>
                                                                            }
                                                                        </Text>
                                                                    </div>

                                                                    <TakeTestQuestionImage type={'question'} url={items.questionData && items.questionData.image_url} />

                                                                    <div className="survey-rating-options-wrap likret-scale-top-row none-for-mobile ">
                                                                        <div className="bd-question-radio-data w-100">
                                                                            <div className="ans-list-repeat saved-ans-repeat w-100 mt-32">
                                                                                <div className="likert-option-left">
                                                                                    <div className="ans-icon">&nbsp;</div>
                                                                                    <div className="ans-data">&nbsp;</div>
                                                                                </div>
                                                                                <div className="likert-option-right">
                                                                                    {items.data.fivesecondstestoptions.map((option, index) => (
                                                                                        <label htmlFor="radio4" key={index}>
                                                                                            <p>{option.option_value}</p>
                                                                                        </label>
                                                                                    ))}
                                                                                </div>
                                                                            </div>


                                                                        </div>
                                                                    </div>
                                                                </>
                                                            )}

                                                            <div className={`likert-innerview ${likertActive == true ? "likert-active-view" : ""}`}>
                                                                {items.questionData.questionType == "likertscale" && items.data.fivesecondstestsubquestions.map(
                                                                    (subQuestion, subquestionindex) => (
                                                                        <div className="ans-list-wrap likert-scale-option-add" key={subquestionindex} id={`subquestion-${subquestionindex}`}>
                                                                            <div className="ans-list-repeat saved-ans-repeat">
                                                                                <div className="likert-option-left">
                                                                                    <div className="ans-data">{subQuestion.subquestion}</div>
                                                                                </div>
                                                                                <div className="likert-option-right">
                                                                                    <div className="survey-rating-options-wrap w-100">
                                                                                        <div className="bd-question-radio-data w-100">
                                                                                            <div className="checkboxes radio_btn_class  d-flex rating-answer-options-radio w-100">
                                                                                                {items.data.fivesecondstestoptions.map((option, index) => (
                                                                                                    <>
                                                                                                        <div className="likert-view-option-repeat" key={subquestionindex + '-' + index}>
                                                                                                            <span
                                                                                                                // href={`#subquestion-${subquestionindex + 1}`}
                                                                                                                onClick={(e) => {
                                                                                                                    // e.preventDefault();
                                                                                                                    movetonextlikertsubquestion(subquestionindex);
                                                                                                                }
                                                                                                                }
                                                                                                            >
                                                                                                                <RadioButton
                                                                                                                    id={`option-select-${subquestionindex + '-' + index}`}
                                                                                                                    isSelectedlabelBold={true}
                                                                                                                    name={`option-select-${subquestionindex}`}
                                                                                                                    value={option.id}
                                                                                                                    checked={
                                                                                                                        formValues.option_id.forEach((subquestion) => {
                                                                                                                            if ((subquestion.subquestionid === subQuestion.id) && (subquestion.optionid === option.id)) {
                                                                                                                                return true;
                                                                                                                            }
                                                                                                                        })

                                                                                                                    }
                                                                                                                    onChange={(e) => {
                                                                                                                        isActiveQuestionId.current = items.data.id;
                                                                                                                        likertAttemptedSubQuestion.current++;

                                                                                                                        options.forEach((subquestion, index) => {
                                                                                                                            if (subquestion.subquestionid === subQuestion.id) {
                                                                                                                                options.splice(index, 1);
                                                                                                                                likertAttemptedSubQuestion.current--;
                                                                                                                            }
                                                                                                                        })
                                                                                                                        options.push({ subquestionid: subQuestion.id, optionid: option.id });

                                                                                                                        var op = options.sort((a, b) => {
                                                                                                                            if (a.subquestionid < b.subquestionid) {
                                                                                                                                return -1;
                                                                                                                            }
                                                                                                                        });

                                                                                                                        setFormValues({
                                                                                                                            ...formValues,
                                                                                                                            question_id: items.data.id,
                                                                                                                            option_id: op,
                                                                                                                            totalSubQuestions: items.data.fivesecondstestsubquestions.length,
                                                                                                                        });
                                                                                                                        if (likertAttemptedSubQuestion.current >= items.data.fivesecondstestsubquestions.length) {
                                                                                                                            nextButtonActive.current = true;
                                                                                                                        } else {
                                                                                                                            nextButtonActive.current = false;
                                                                                                                        }

                                                                                                                    }}
                                                                                                                    label={option.option_value}
                                                                                                                />
                                                                                                            </span>
                                                                                                            {/* <div>{option.option_value}</div> */}
                                                                                                        </div>

                                                                                                    </>
                                                                                                ))}
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    )
                                                                )}
                                                            </div>
                                                        </div>


                                                        <div className="ranking-scale-taking-test-view">

                                                            {items.questionData.questionType ===
                                                                "rankingscale" && (
                                                                    <>
                                                                        <div className="taking-test-progressbar">
                                                                            {/* {logicAdded==false && */}
                                                                            <PageProgressBar language={test.language} current_step={isActiveQuestion} total_steps={QuestionArrayList.length} variant={"take-test"} label={"Question"} />
                                                                            {/* } */}
                                                                        </div>

                                                                        <div className="take-test-heading-wrap">
                                                                            <Text type="h2">
                                                                                {items.questionData.question}&nbsp;
                                                                                {items.questionData.is_optional == true &&
                                                                                    <span className="optional-span">
                                                                                        ({test.language == "en" ? "Optional" : "اختياري"})
                                                                                    </span>
                                                                                }
                                                                            </Text>
                                                                        </div>

                                                                        <div className="select-option-text-guide mt-24">
                                                                            <Text type="body-text-1" fontWeight="medium-font">{test.language == "en" ? "Drag the items below to reorder them" : "ترتيب العناصر حسب الأهمية"}</Text>
                                                                        </div>

                                                                        <TakeTestQuestionImage type={'question'} url={items.questionData && items.questionData.image_url} />

                                                                        <div className="test-form-hold">

                                                                            <div className="ranking-data-wrap">
                                                                                {/* <div className="ranking-count">
                                                                                    {items.data.fivesecondstestoptions.map(
                                                                                        (optionData, index) => (
                                                                                            <p >{('0' + (index + 1)).slice(-2)}</p>))
                                                                                    }
                                                                                </div> */}
                                                                                <div className="rank-dragable-list-wrap" style={{ position: 'relative' }}>
                                                                                    <DragDropContext
                                                                                        onDragEnd={(param) => {
                                                                                            setPlaceholderProps({});

                                                                                            nextButtonActive.current = true;

                                                                                            const srcI = param.source.index;
                                                                                            const desI = param.destination.index;

                                                                                            items.data.fivesecondstestoptions.splice(
                                                                                                desI,
                                                                                                0,
                                                                                                items.data.fivesecondstestoptions.splice(
                                                                                                    srcI,
                                                                                                    1
                                                                                                )[0]
                                                                                            );
                                                                                            let options = [];
                                                                                            items.data.fivesecondstestoptions.forEach((option) => {
                                                                                                options.push(option.id);

                                                                                            });
                                                                                            isActiveQuestionId.current = items.data.id;
                                                                                            setFormValues({
                                                                                                ...formValues,
                                                                                                question_id: items.data.id,
                                                                                                option_id: options,
                                                                                            });
                                                                                        }}
                                                                                        onDragStart={handleDragStart}
                                                                                        onDragUpdate={handleDragUpdate}
                                                                                    >
                                                                                        <Droppable droppableId="droppable-1">
                                                                                            {(provided, snapshot) => (
                                                                                                <div className="w-100"
                                                                                                    ref={
                                                                                                        provided.innerRef
                                                                                                    }
                                                                                                    {...provided.droppableProps}
                                                                                                >
                                                                                                    {items.data.fivesecondstestoptions.map(
                                                                                                        (optionData, index) => (
                                                                                                            // <div>
                                                                                                            // <div className="ranking-count">{('0' + (index+1)).slice(-2)}</div>
                                                                                                            <Draggable
                                                                                                                key={index}
                                                                                                                draggableId={
                                                                                                                    "draggable-" +
                                                                                                                    index
                                                                                                                }
                                                                                                                index={index}
                                                                                                            >
                                                                                                                {(
                                                                                                                    provided,
                                                                                                                    snapshot
                                                                                                                ) => (
                                                                                                                    <div
                                                                                                                        className={`ranking-list-repeat saved-ans-repeat ${snapshot.isDragging ? 'draggable-element-start' : ''}`}
                                                                                                                        ref={
                                                                                                                            provided.innerRef
                                                                                                                        }
                                                                                                                        {...provided.draggableProps}
                                                                                                                        {...provided.dragHandleProps}
                                                                                                                    >
                                                                                                                        <div className="ranking-count">
                                                                                                                            <p >{('0' + (index + 1)).slice(-2)}</p>
                                                                                                                        </div>
                                                                                                                        <div
                                                                                                                            className={`option-holder ${snapshot.isDragging ? 'draggable-inner-element-start' : ''}`}
                                                                                                                            {...provided.dragHandleProps}
                                                                                                                        >
                                                                                                                            <Icon value="move object" />
                                                                                                                            <Text display={"inline"} type={"body-text-2"}>{optionData.option_value}</Text>
                                                                                                                        </div>
                                                                                                                        {/* <div className="ans-data">
                                                                                                                {optionData.option_value}
                                                                                                            </div> */}
                                                                                                                    </div>
                                                                                                                )}
                                                                                                            </Draggable>
                                                                                                            // </div>
                                                                                                        )
                                                                                                    )}
                                                                                                    {provided.placeholder}
                                                                                                    {!isEmpty(placeholderProps) && (
                                                                                                        <div
                                                                                                            className="placeholder"
                                                                                                            style={{
                                                                                                                top: placeholderProps.clientY,
                                                                                                                left: placeholderProps.clientX,
                                                                                                                height: placeholderProps.clientHeight,
                                                                                                                width: placeholderProps.clientWidth,
                                                                                                                position: "absolute",
                                                                                                                borderColor: "#000000",
                                                                                                                background: placeholderProps.background,
                                                                                                                borderRadius: '10px',
                                                                                                                border: placeholderProps.border,
                                                                                                                margin: '0px 0px 10px'
                                                                                                            }}
                                                                                                        />
                                                                                                    )}
                                                                                                </div>
                                                                                            )}
                                                                                        </Droppable>
                                                                                    </DragDropContext>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </>
                                                                )}
                                                        </div>
                                                    </div>
                                                )}
                                            </div>
                                        );
                                    })}

                            </div>

                            <div className={`taking-test-button-wrap d-flex justify-content-center mt-64 ${isTyping.current == true ? 'keyboard-active-button' : ''} ${isOptional.current == true ? 'optional-button' : ''}`} id="buttons">
                                <Button type="primary" size="large" label={test.language == "en" ? "Next" : "التالي"} iconRight={<Icon colorClass={"gray-50-svg"} value={test.language == "ar" ? 'back-arrow' : 'forward-arrow'} />} state={nextButtonActive.current == true ? 'active' : 'disabled'} />
                                {isOptional.current == true &&
                                    <Button type="ghost" size="medium" label={test.language == "en" ? "Skip" : "تخطي"} iconRight={<Icon colorClass={"gray-900-svg"} value={test.language == "ar" ? 'backward' : 'forward'} />} onClick={(e) => {
                                        if (skippedCount.current < 2) {

                                            isSkipped.current = 1;
                                            setFormValues({
                                                ...formValues,
                                                question_id: isActiveQuestionId.current,
                                            });
                                            handleSubmitQuestion(e);
                                            skippedCount.current = skippedCount.current + 1;
                                        }
                                        // else {
                                        //     showToast("You can skip maximum of 2 questions.", "error");
                                        // }
                                    }}
                                    />
                                }

                            </div>
                        </form>
                    </div>
                </div>
            }
        </>
    );
}
