import PropTypes from "prop-types";
import React, { useState, useEffect, useRef } from "react";
import { Text } from "./../Text/Text";
import { Icon } from "./../Icon/Icon";
import { Tag } from "./../Tag/Tag";
import AddLogicTestResultModal from "../../../dialog/add_logics_test_results_modal";
import { LayoutResearcher } from "../Layouts/layout_researcher";
import { TestResultQuestionImage } from "../QuestionImage/TestResultQuestionImage";
export const QuestionsDropdown = ({
    questionArray,
    changeActiveQuestionProp,
    dropdownOpen,
    resultCount,
    test,
    isActiveDropdown,
    questionType,
    active,
    isLogic,
}) => {
    const wrapperRef = useRef(null);

    const [isListOpen, setListOpen] = useState(false);
    const [isActive, setActive] = useState(active ? active : 1);
    const [metaData, setMetaData] = useState({
        questionType: "",
        totalResultCount: 0,
        userType: "",
        aggregate_total_voters: 0,
        sort_time: "",
        sort_date: "",
        sort_vote: "",
        load_more: "",
        test_question_id: "",
        page: 1,
    });

    const [addLogicModal, setAddLogicModal] = useState({
        open: false,
    });

    const changeActiveQuestion = (index, data) => {
        setActive(index);
        // console.log(data);
        // console.log(index);
        const dataAnswer = data.filter(
            (item, indexItem) => indexItem + 1 === index
        )[0];
        console.log(dataAnswer);
        if (dataAnswer) {
            setMetaData({
                questionType: dataAnswer.question_type,
                totalResultCount: dataAnswer.result_count,
                userType:
                    dataAnswer.question_type === "free" ? "by-user" : "chart",
                aggregate_total_voters: dataAnswer.aggregate_total_voters,
                sort_time: "",
                sort_date: "",
                sort_vote: "",
                load_more: dataAnswer.result_count > 10 ? true : false,
                test_question_id: dataAnswer.id,
                page: 1,
                // 'isLogicEnable':(dataAnswer.question_type !== 'singlechoice' && isLogic && dataAnswer.questions.is_logic)?true:false
                isLogicEnable:
                    isLogic && dataAnswer.questions.is_logic ? true : false,
            });
        }
    };
    const showPreQuestion = (type) => {
        if (type === "increase") {
            // decrease active question length
            if (isActive !== 1) {
                changeActiveQuestion(isActive - 1, questionArray);
                changeActiveQuestionProp(isActive - 1);
            }
        } else {
            // increase active question length
            if (questionArray.length !== isActive) {
                changeActiveQuestion(isActive + 1, questionArray);
                changeActiveQuestionProp(isActive + 1);
            }
        }
    };

    function useOutsideAlerter(ref, questionFormValues) {
        useEffect(() => {
            /**
             * Alert if clicked on outside of element
             */
            //   console.log(ref.current);
            function handleClickOutside(event) {
                if (ref.current && !ref.current.contains(event.target)) {
                    setListOpen(false);
                    dropdownOpen(false);
                }
            }
            // Bind the event listener
            document.addEventListener("mousedown", handleClickOutside);
            return () => {
                // Unbind the event listener on clean up
                document.removeEventListener("mousedown", handleClickOutside);
            };
        }, [ref]);
    }

    useOutsideAlerter(wrapperRef);

    useEffect(() => {
        changeActiveQuestion(active ? active : 1, questionArray);
    }, [questionArray, active]);

    // console.log(metaData)

    return (
        <div
            className={`question-dropdown-container result-question-dropdown ${
                metaData.isLogicEnable ? "logic-enabled" : ""
            }`}
            ref={wrapperRef}
        >
            {test.methodology === "Survey" && isLogic && (
                <>
                    {questionArray &&
                        questionArray.map((items, index) => {
                            return (
                                <>
                                    {isActive === index + 1 &&
                                        items.questions.is_logic === 1 && (
                                            <div
                                                className={"add-logic-enabled"}
                                                onClick={() => {
                                                    console.log("jgjh");
                                                    setAddLogicModal({
                                                        open: true,
                                                        test_id: test.id,
                                                        question_id: items.id,
                                                        questionIndex: index,
                                                    });
                                                }}
                                            >
                                                <Text
                                                    type={"caption"}
                                                    cssClasses={"data-right"}
                                                >
                                                    Logic applied
                                                </Text>
                                            </div>
                                        )}
                                </>
                            );
                        })}
                </>
            )}

            <div
                className="dropdown-header"
                onClick={(event) => {
                    event.preventDefault();
                    event.stopPropagation();
                    // console.log(event.target);
                    // console.log(event.target.classList.contains('dropdown-toggle-disabled'));
                    if (
                        !event.target.classList.contains(
                            "dropdown-toggle-disabled"
                        )
                    ) {
                        if (questionArray.length > 1) {
                            setListOpen(!isListOpen);
                            dropdownOpen(!isListOpen);
                        }
                    }
                }}
            >
                <div className="dropdown-header-left">
                    <div className="dropdown-header-top">
                        <p className="text-small-info text-uppercase">
                            <span className="active-question">{isActive}</span>
                            <span className="text-lowercase">of</span>{" "}
                            {questionArray.length} -{" "}
                            {metaData.questionType === "singlechoice" ? (
                                "Single choice"
                            ) : metaData.questionType === "multiplechoice" ? (
                                "Multiple choice"
                            ) : metaData.questionType === "free" ? (
                                "free text"
                            ) : metaData.questionType === "rankingscale" ? (
                                "Ranking Scale"
                            ) : metaData.questionType === "ratingscale" ? (
                                "Rating Scale"
                            ) : metaData.questionType === "likertscale" ? (
                                "Likert Scale"
                            ) : (
                                <>{metaData.questionType}</>
                            )}
                            <span className="questions-dropdown-result-count">
                                ({resultCount}{" "}
                                {resultCount > 1 ? " answers" : " answer"})
                            </span>
                        </p>
                    </div>
                    <div className="dropdown-header-bottom">
                        <div className="dropdown-header-selected-question">
                            <Text
                                type="body-text-1"
                                fontWeight="medium-font"
                                cssClasses={`${
                                    test.language == "ar" ? "arabic-font" : ""
                                } left-data`}
                            >
                                {questionArray &&
                                    questionArray.map((items, index) => {
                                        return (
                                            <>
                                                {isActive === index + 1 && (
                                                    <div className="test-header-with-ans-img">
                                                        {
                                                            items.questions
                                                                .question
                                                        }

                                                        {items.questions
                                                            .image_url && (
                                                            <TestResultQuestionImage
                                                                type={"button"}
                                                                url={
                                                                    items
                                                                        .questions
                                                                        .image_url
                                                                }
                                                            />
                                                        )}
                                                    </div>
                                                )}
                                            </>
                                        );
                                    })}
                            </Text>
                        </div>
                        <div className="dropdown-header-arrow-buttons">
                            {test.methodology !== "First click" &&
                                test.methodology !== "Tree Test" && (
                                    <>
                                        {test.methodology ===
                                            "Prototype test" &&
                                        !isActiveDropdown &&
                                        !questionType ? (
                                            <></>
                                        ) : (
                                            <div className="bd-que-slide-arrow">
                                                <a
                                                    onClick={(e) => {
                                                        e.preventDefault();
                                                        e.stopPropagation();
                                                        showPreQuestion(
                                                            "increase"
                                                        );
                                                        if (isListOpen) {
                                                            setListOpen(false);
                                                            dropdownOpen(false);
                                                        }
                                                    }}
                                                    className={`${
                                                        isActive === 1
                                                            ? "disabled-link"
                                                            : ""
                                                    } dropdown-toggle-disabled`}
                                                >
                                                    <Icon
                                                        value="back-arrow"
                                                        size="medium"
                                                        colorClass={
                                                            "gray-50-svg dropdown-toggle-disabled"
                                                        }
                                                    />
                                                    {/* <Icon value="backward-arrow-white" /> */}
                                                </a>
                                                <a
                                                    onClick={(e) => {
                                                        e.preventDefault();
                                                        e.stopPropagation();
                                                        showPreQuestion(
                                                            "decrease"
                                                        );
                                                        if (isListOpen) {
                                                            setListOpen(false);
                                                            dropdownOpen(false);
                                                        }
                                                    }}
                                                    className={`${
                                                        questionArray.length ===
                                                        isActive
                                                            ? "disabled-link"
                                                            : ""
                                                    } dropdown-toggle-disabled`}
                                                >
                                                    <Icon
                                                        value="forward-arrow"
                                                        size="medium"
                                                        colorClass={
                                                            "gray-50-svg dropdown-toggle-disabled"
                                                        }
                                                    />
                                                </a>
                                            </div>
                                        )}
                                    </>
                                )}
                            {(test.methodology == "First click" ||
                                test.methodology == "Tree Test") &&
                                questionType == "conclusionOrIntroduction" && (
                                    <div className="bd-que-slide-arrow">
                                        <a
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                e.preventDefault();
                                                showPreQuestion("increase");
                                                if (isListOpen) {
                                                    setListOpen(false);
                                                    dropdownOpen(false);
                                                }
                                            }}
                                            className={`${
                                                isActive === 1
                                                    ? "disabled-link"
                                                    : ""
                                            } dropdown-toggle-disabled`}
                                        >
                                            <Icon
                                                value="back-arrow"
                                                size="medium"
                                                colorClass={
                                                    "gray-50-svg dropdown-toggle-disabled"
                                                }
                                            />
                                            {/* <Icon value="backward-arrow-white" /> */}
                                        </a>
                                        <a
                                            onClick={() => {
                                                showPreQuestion("decrease");
                                                if (isListOpen) {
                                                    setListOpen(false);
                                                    dropdownOpen(false);
                                                }
                                            }}
                                            className={`${
                                                questionArray.length ===
                                                isActive
                                                    ? "disabled-link"
                                                    : ""
                                            } dropdown-toggle-disabled`}
                                        >
                                            <Icon
                                                value="forward-arrow"
                                                size="medium"
                                                colorClass={
                                                    "gray-50-svg dropdown-toggle-disabled"
                                                }
                                            />
                                            {/* <Icon value="forward-arrow-white" /> */}
                                        </a>
                                    </div>
                                )}
                        </div>
                    </div>
                </div>
                <div
                    className={`dropdown-header-right ${
                        questionArray.length > 1 ? "" : "disabled-arrow"
                    }`}
                >
                    {test.methodology !== "First click" &&
                        test.methodology !== "Tree Test" && (
                            <>
                                {test.methodology === "Prototype test" &&
                                !isActiveDropdown &&
                                !questionType ? (
                                    <></>
                                ) : (
                                    <div className="dropdown-header-chevron">
                                        {isListOpen ? (
                                            <Icon
                                                value="chevron-up"
                                                size="medium"
                                            />
                                        ) : (
                                            <Icon
                                                value="chevron"
                                                size="medium"
                                            />
                                        )}
                                    </div>
                                )}
                            </>
                        )}
                </div>
            </div>

            <div className="dropdown-header-arrow-buttons none-for-desktop mobile-nav-arrows">
                {test.methodology !== "First click" && (
                    <>
                        {test.methodology === "Prototype test" &&
                        !isActiveDropdown &&
                        !questionType ? (
                            <></>
                        ) : (
                            <div className="bd-que-slide-arrow">
                                <a
                                    onClick={(e) => {
                                        e.preventDefault();
                                        e.stopPropagation();
                                        showPreQuestion("increase");
                                        if (isListOpen) {
                                            setListOpen(false);
                                            dropdownOpen(false);
                                        }
                                    }}
                                    className={`${
                                        isActive === 1 ? "disabled-link" : ""
                                    } dropdown-toggle-disabled`}
                                >
                                    <Icon
                                        value="back-arrow"
                                        size="medium"
                                        colorClass={
                                            "gray-50-svg dropdown-toggle-disabled"
                                        }
                                    />
                                    {/* <Icon value="backward-arrow-white" /> */}
                                </a>
                                <a
                                    onClick={(e) => {
                                        e.preventDefault();
                                        e.stopPropagation();
                                        showPreQuestion("decrease");
                                        if (isListOpen) {
                                            setListOpen(false);
                                            dropdownOpen(false);
                                        }
                                    }}
                                    className={`${
                                        questionArray.length === isActive
                                            ? "disabled-link"
                                            : ""
                                    } dropdown-toggle-disabled`}
                                >
                                    <Icon
                                        value="forward-arrow"
                                        size="medium"
                                        colorClass={
                                            "gray-50-svg dropdown-toggle-disabled"
                                        }
                                    />
                                </a>
                            </div>
                        )}
                    </>
                )}
                {test.methodology == "First click" &&
                    questionType == "conclusionOrIntroduction" && (
                        <div className="bd-que-slide-arrow">
                            <a
                                onClick={(e) => {
                                    e.stopPropagation();
                                    e.preventDefault();
                                    showPreQuestion("increase");
                                    if (isListOpen) {
                                        setListOpen(false);
                                        dropdownOpen(false);
                                    }
                                }}
                                className={`${
                                    isActive === 1 ? "disabled-link" : ""
                                } dropdown-toggle-disabled`}
                            >
                                <Icon
                                    value="back-arrow"
                                    size="medium"
                                    colorClass={
                                        "gray-50-svg dropdown-toggle-disabled"
                                    }
                                />
                                {/* <Icon value="backward-arrow-white" /> */}
                            </a>
                            <a
                                onClick={(e) => {
                                    e.stopPropagation();
                                    e.preventDefault();
                                    showPreQuestion("decrease");
                                    if (isListOpen) {
                                        setListOpen(false);
                                        dropdownOpen(false);
                                    }
                                }}
                                className={`${
                                    questionArray.length === isActive
                                        ? "disabled-link"
                                        : ""
                                } dropdown-toggle-disabled`}
                            >
                                <Icon
                                    value="forward-arrow"
                                    size="medium"
                                    colorClass={
                                        "gray-50-svg dropdown-toggle-disabled"
                                    }
                                />
                                {/* <Icon value="forward-arrow-white" /> */}
                            </a>
                        </div>
                    )}
            </div>

            {isListOpen && (
                <div className="dropdown-list-hold-wrap">
                    <div className="dropdown-list-container">
                        {questionArray &&
                            questionArray.map((item, index) => (
                                <span
                                    className="dropdown-list-item"
                                    key={item.id}
                                    onClick={() => {
                                        setListOpen(false);
                                        dropdownOpen(false);
                                        changeActiveQuestion(
                                            index + 1,
                                            questionArray
                                        );
                                        changeActiveQuestionProp(index + 1);
                                    }}
                                >
                                    {isActive == index + 1 ? (
                                        <div className="dropdown-active-question dropdown-question-item">
                                            <Text
                                                type="body-text-1"
                                                fontWeight="medium-font"
                                                cssClasses={
                                                    test.language == "ar"
                                                        ? "arabic-font"
                                                        : ""
                                                }
                                            >
                                                {item.questions.question}
                                            </Text>{" "}
                                            {item.questions.questionType && (
                                                <Tag
                                                    title={
                                                        item.questions
                                                            .questionType ===
                                                        "singlechoice" ? (
                                                            "Single-choice"
                                                        ) : item.questions
                                                              .questionType ===
                                                          "multiplechoice" ? (
                                                            "Multi-choice"
                                                        ) : item.questions
                                                              .questionType ===
                                                          "free" ? (
                                                            "Free text"
                                                        ) : item.questions
                                                              .questionType ===
                                                          "rankingscale" ? (
                                                            "Ranking scale"
                                                        ) : item.questions
                                                              .questionType ===
                                                          "ratingscale" ? (
                                                            "Rating scale"
                                                        ) : item.questions
                                                              .questionType ===
                                                          "likertscale" ? (
                                                            "Likert scale"
                                                        ) : (
                                                            <>
                                                                {
                                                                    item
                                                                        .questions
                                                                        .questionType
                                                                }
                                                            </>
                                                        )
                                                    }
                                                    type="purple-tag"
                                                />
                                            )}
                                        </div>
                                    ) : (
                                        <div className="dropdown-question-item">
                                            <Text
                                                type="body-text-1"
                                                fontWeight="medium-font"
                                                cssClasses={
                                                    test.language == "ar"
                                                        ? "arabic-font"
                                                        : ""
                                                }
                                            >
                                                {item.questions.question}
                                            </Text>{" "}
                                            {item.questions.questionType && (
                                                <Tag
                                                    title={
                                                        item.questions
                                                            .questionType ===
                                                        "singlechoice" ? (
                                                            "Single-choice"
                                                        ) : item.questions
                                                              .questionType ===
                                                          "multiplechoice" ? (
                                                            "Multi-choice"
                                                        ) : item.questions
                                                              .questionType ===
                                                          "free" ? (
                                                            "Free text"
                                                        ) : item.questions
                                                              .questionType ===
                                                          "rankingscale" ? (
                                                            "Ranking scale"
                                                        ) : item.questions
                                                              .questionType ===
                                                          "ratingscale" ? (
                                                            "Rating scale"
                                                        ) : item.questions
                                                              .questionType ===
                                                          "likertscale" ? (
                                                            "Likert scale"
                                                        ) : (
                                                            <>
                                                                {
                                                                    item
                                                                        .questions
                                                                        .questionType
                                                                }
                                                            </>
                                                        )
                                                    }
                                                    type="pink-tag"
                                                />
                                            )}
                                        </div>
                                    )}
                                </span>
                            ))}
                    </div>
                </div>
            )}

            <AddLogicTestResultModal
                test_id={addLogicModal.test_id}
                question_id={addLogicModal.question_id}
                question_index={addLogicModal.questionIndex}
                openModal={addLogicModal.open}
                close={() => {
                    setAddLogicModal({ ...addLogicModal, open: false });
                }}
            />
        </div>
    );
};
QuestionsDropdown.propTypes = {
    questionArray: PropTypes.array,
    changeActiveQuestionProp: PropTypes.func,
    dropdownOpen: PropTypes.func,
};
QuestionsDropdown.defaultProps = {
    questionArray: [],
    changeActiveQuestionProp: () => {},
    dropdownOpen: () => {},
};
