import { Button } from "../Button/Button";
import { Icon } from "../Icon/Icon";

export default function FigmaAuth({onClick, isLoading, state}){

    
    return <Button 
                label="Sync"
                type={isLoading?"primary" : "secondary"}
                size="medium"
                iconLeft={
                <Icon value="sync" size={"small"} />}
                onClick={()=>{
                    onClick();
                }}
                microLoading={isLoading}
                state={state}
            />;
}
var figma_login_window;
let browser = window
let timer = null

function watcher() {
    if (figma_login_window === null) {

        //console.log(1)
      clearInterval(timer)
      timer = null
    } else if (figma_login_window !== null && !figma_login_window.closed) {
       // console.log(2)
        figma_login_window.focus()
    } else if (figma_login_window !== null && figma_login_window.closed) {
       // console.log(3)
      clearInterval(timer)
      browser.focus()
      timer = null
      figma_login_window = null
    }
  }


export const openFigmaAuthWindow = (callback)=>{

    const w= 600;
    const h =600;
    const dualScreenLeft = window.screenLeft !==  undefined ? window.screenLeft : window.screenX;
    const dualScreenTop = window.screenTop !==  undefined   ? window.screenTop  : window.screenY;

    const width = window.innerWidth ? window.innerWidth : document.documentElement.clientWidth ? document.documentElement.clientWidth : window.screen.width;
    const height = window.innerHeight ? window.innerHeight : document.documentElement.clientHeight ? document.documentElement.clientHeight : window.screen.height;

    const systemZoom = width / window.screen.availWidth;
    const left = (width - w) / 2 / systemZoom + dualScreenLeft
    const top = (height - h) / 2 / systemZoom + dualScreenTop

    figma_login_window = window.open(
        "https://www.figma.com/oauth?client_id=fvYB9GwDlD3qnJemZc04vn&redirect_uri="+process.env.REACT_APP_URL+"figma/login&scope=files:read&state=login&response_type=code", 
        "figma_login_window", 
        "width=600,height=600,top="+top+",left="+left);

    if (timer === null) {
        timer = setInterval(watcher, 500)
    }
    const bc = new BroadcastChannel("figma_channel");

    bc.onmessage = (event) => {

        let params = new URLSearchParams(event.data);
        
        callback(params.get("state"), params.get("code"));
        figma_login_window.close();

        bc.close();

    };


    /*window.addEventListener(
        "message",
        (event) => {
           console.log("as");
        },
    false
    );
    window.onSuccess = (state, code)=>{
        alert(2)
        callback(state, code)
        figma_login_window.close();
    }*/
    return;
}
